@use "@material/list/mdc-list";
@use "@material/menu-surface/mdc-menu-surface";
@use "@material/menu/mdc-menu";


.kebab-menu {
    button {
        background: none;
        border: none;
        svg {
            width: 25px;
            height: 25px;
        }
    }
}