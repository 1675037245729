@use "@material/list";
@use './../base/color';

@include list.core-styles;




.top-chart {
  padding: 0.5rem;
  background-color: color.getColor('white', base);
  border-radius: 4px;
  margin: 1rem 0rem;
  p.title { 
    font-weight: 400;
  }
  ul.container-business{
    list-style-type: none; /* Remove bullets */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margins */
  }
  li.business-list {
    display: grid;
      grid-template-areas: "name amount percentage";
      grid-template-columns: 1fr 0.75fr 0.30fr;
      padding: 0.125rem 0rem;
      font-size: 14px;
  
      span.name {
        grid-area: name;
      }
      span.amount {
        grid-area: amount;
        text-align: right;
      }
      span.percentage {
        grid-area: percentage;
        text-align: center
      }
  }
}

div.removal-list {
  background-color: color.getColor('white', base);
  width: 100%;
  display: grid;
  grid-template-columns: 0.5fr 3fr 1fr;
  grid-template-areas: 'icon name delete';
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  align-items: center;
  height: 46px;
  padding: 0.5rem;
  box-sizing: border-box;
  margin-bottom: 0.5rem;
  .icon-element {
    width: 100%;
    grid-area: icon;
    svg {
        width: 25px;
        height: 25px;
    }
  }
  .text {
    width: 100%;
    grid-area: name;
    padding-left: 0.5rem;
    font-size: 1.125rem;
    box-sizing: border-box;
  }
  button.delete {
    background-color: transparent;
    border: none;
    grid-area: delete;
    svg {
      width: 25px;
      height: 25px;
      fill: color.getColor('grey', base);
    }
  }
}

