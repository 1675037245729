@use './../base/color';

.tab-bar {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  bottom: 0rem;
  margin: 0rem;
  list-style-type: none;
  -webkit-padding-start: 0;  
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: bottom 0.3s;
  -webkit-transition: bottom 0.5s;
  svg {
    width: 20px;
    height: 20px;
  }
  height: 44px;
}

.tab {
  width: 33%;
  padding: 10px 0;
  background: color.getColor('white', base);;
  color: #607D8B;
  overflow: hidden;
  text-align: center;

  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  cursor: pointer;
  position: relative;
  
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  
  svg {
    fill: color.getColor('black', base);
    -webkit-transition: fill 0.5s ease;
    transition: fill 0.5s ease;
  }
}
.tab.selected{
  svg {
    fill: color.getColor('blue', base);
  }
}

.indicator {
  width: 33%;
  height: 3px;
  background: color.getColor('blue', base);
  position: absolute;
  margin-top: 44px;
  margin-left:0;
  bottom: 0rem;
  -webkit-transition: margin 0.5s ease;
  
  transition: margin 0.5s ease;
}
@media only screen and (min-width: 768px) {
  .tab-bar {
    display: none;
  }
}