@use './../base/color';

div.logo-loading-container {
    width: 50px;
}


div.logo-margin {
    margin: 1rem 0rem;
    svg {
        fill: color.getColor('blue', base);
    }
}
div.header-logo {
    height: var(--top-bar-height, 50px);
    display: grid;
    grid-template-columns: 90px 50px auto;
    align-items: center;
    span {
        font-size: 14px;
        font-weight: 300;
    }
    svg {
        width: 30px;
        height: 30px;
    }
    .header-actions {
        display: grid;
        align-items: center;
        justify-items: end;
        button {
            background: none;
            border: none;
            svg {
                width: 25px;
                height: 25px;
                fill: color.getColor('white', base);
            }
        }
        button:focus{
            outline:none;
            
        }
    }
}

div.header-logo.blue-background {
    background-color: color.getColor('blue', base);
    color: color.getColor('white', base);
}
