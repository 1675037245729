@use './../base/color';
@use "@material/switch";

@include switch.core-styles;

.blue-switch {
    @include switch.toggled-on-color(color.getColor('blue', base))
}

div.with-explanation {
    p {
        font-size: 14px;
        padding-top: 0.5rem;
        color: color.getColor('grey', base)
    }
}