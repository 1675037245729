@use './color';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700&family=Nunito+Sans:wght@300;400;600&display=swap');
h3.hero {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}

h2.hero {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}
h1.hero {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    text-align: center;
    margin: 0;
    padding: 1rem 0;
    font-size: 2rem;
}
.underline {
    text-decoration: underline;
}
h3.module-header {
    margin: 0rem;
    font-weight: 500;
}

p.subheader {
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 300;
}

p.body {
    line-height: 1.5rem;
    
}

p.hero {
    line-height: 1.75rem;
    font-size: 1.25rem;
    font-weight: 300;
}
p.hero.bold {
    font-weight: 500;
}

q {
    font-style: italic;
    font-weight: 300;
}

span.error {
    color: color.getColor('red', base);
    font-size: 14px;
}