@use './../base/color';

div.list-product {
    background-color: color.getColor('white', base);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    margin-top: 1rem;
    padding: 0rem 0.5rem;
}

div.product {
    display: grid;
    grid-template-areas:
                        "quantity business total"
                        "progress progress progress";
    grid-template-columns: 0.75fr 5fr 3fr;
    padding: 0.5rem 0rem;
    font-weight: 400;
    font-size: small;
    span.quantity {
        grid-area: quantity;
        background-color: color.getColor('blue', light);
        color: color.getColor('white', base);
        font-weight: 400;
        font-size: 11px;
        border-radius: 4px;
        text-align: center;
    }
    span.product {
        grid-area: business;
        padding-left: 0.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    span.amount{
        grid-area: total;
        text-align: right;
    }
    div.progress{
        grid-area: progress;
        background-color: color.getColor('blue', base);;
        height: 2px;
        margin-top: 0.25rem;
    }
}
div.manual-entry-data {
    min-height: var(--height-full, 100vh);
    grid-template-rows: 1.61fr 1fr;
    display: grid;
    div.main-data {
        display: grid;
        grid-auto-rows: 1fr 1.61fr;
        div.manual-entry-header {
            display: grid;
            grid-template-rows: var(--top-bar-height, 50px) var(--top-bar-height, 50px);
            align-items: center;
            background-color: color.getColor('blue', base);
            color: color.getColor('white', base);;
            align-self: baseline;
            .header-action{
                display: grid;
                grid-template-columns: 1.61fr 1fr;
                align-items: center;
            }
            .header-action .header-section-name {
                font-weight: 400;
                font-size: 18px;
            }
            .top-action{
                text-align: right;
            }
        }
        div.entry-data {
            display: grid;
            align-items: center;
            grid-template-rows: 1.61fr 1fr;
            div.business-card {
                margin: 16px;
                box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
                background-color: color.getColor('white', base);
                padding: 2rem 0.5rem;
                box-sizing: border-box;
                border-radius: 4px;
                .data-detail{
                    display: grid;
                    grid-template-columns: 1.61fr 1fr;
                    align-items: start;
                    .businessname {
                        .business-name {
                            font-size: 16px;
                        }
                        p {
                            font-weight: 300;
                            font-size: 14px;
                        }
                    }
                }
                .total-amount {
                    font-size: 20px;
                    display: block;
                    text-align: right;
                    color: color.getColor('blue', base);
                }
            }
        }
    }
    div.action-section {
        display: grid;
        align-items: center;
        .actions {
            display: grid;
            justify-content: center;
            grid-auto-rows: min-content;
        }
    }
    

}
div.business-header {
    display: grid;
    grid-template-areas:
                        "businessname businessname changename"
                        "amountpurchase avgpurchase totalgasto";
    grid-template-columns: 1fr 1fr 1fr;
    color:  color.getColor('black', base);
    padding-bottom: 0.25rem;
    grid-gap: 0.25rem;
    .businessname {
        grid-area: businessname;
        padding: 0.5rem 0rem;
        h3 {
            margin: 0rem;
        }
        p {
            margin: 0rem;
            font-size: 14px;
        }
        .month-highlight {
            font-weight: 400;
        }
    }
    .changename {
        grid-area: changename;   
    }
    .amount-purchases {
        grid-area: amountpurchase;
    }
    .average-purchase {
        grid-area: avgpurchase;
    }
    .total-gasto {
        grid-area: totalgasto;
    }

    .data-hint {
        color: color.getColor('white', base);
        margin: 0rem auto;
    }
}

div.business-to-move {
    background-color: color.getColor('white', base);
    border-radius: 4px;
    padding: 0.5rem;
    margin: 0.25rem 0rem;
    p {
        margin: 0;
        font-weight: bold;
    }
    .mdc-select {
        width: 100%;
    }

}