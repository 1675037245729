@use "@material/drawer";
@use "@material/list";

@include drawer.core-styles;
@include drawer.dismissible-core-styles;
@include drawer.modal-core-styles;
@include list.core-styles;

.mdc-drawer-app-content {
    flex: auto;
    overflow: auto;
    position: relative;
}

.mdc-drawer .mdc-list-item {
    font-family:  "Catamaran", sans-serif;  
}