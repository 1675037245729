@use './../base/color';

.header-list {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-template-areas: 'text action';
    align-items: center;
    p {
        grid-area: text;
        margin: 0rem;
        padding: 0rem;
        font-weight: 500;
        font-size: 1.25rem;
    }
    button {
        grid-area: action;
    }
}
.header-list.small {
    p {
        font-size: 1.125rem;
    }
}

.header-section {
    display: grid;
    grid-template-columns: 25px 3fr;
    grid-template-areas: 'icon title';
    align-items: center;
    .icon-section {
        grid-area: icon;
        svg {
            width: 25px;
            height: 25px;
        }
    }
    p {
        text-transform: uppercase;
        grid-area: title;
        font-weight: 600;
        padding-left: 0.5rem;
        margin: 0rem;
    }
}

.header-hero-section {
    background-color: color.getColor('blue', base);
    color: color.getColor('white', base);;
    
    padding: 1rem 16px;
    h4 {
        font-size: 20px;
    }
}