@use './../base/color';

.calendar-widget {
    margin: 0rem auto;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    background-color: color.getColor('blue', base);
    color: color.getColor('white', base);
    padding: 0.5rem 1rem;
    box-shadow: 0 4px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    span {
        display: block;
        text-align: center;
        padding: 0.25rem 0rem;
    }
    span.date-text {
        font-size: 14px;
    }
    span.month-total-text {
        font-weight: 600;
        font-size: 1.125rem;
    }
    button {
        background-color: color.getColor('blue', base);
        color: color.getColor('white', base);
        border: none;
        div.square {
            width: 20px;
            svg {
                fill: color.getColor('white', base);
            }
        }
    }
    
}