@use './../base/color';

:root {
    --top-bar-height:  50px;
    --height-full: calc(100vh - 50px);
}



div.wrapper-content{
    display: grid;
}
div.main-home {
    display: grid;
    grid-template-columns: auto;
    column-gap: 10px;
    row-gap: 10px;
    padding: 12px;
}

.form-container {
    display: grid;
    padding: 0rem 0rem;
    grid-auto-rows: 70px;
    grid-auto-columns: 1fr 1fr;
    grid-gap: 5px;
    > label, div.two-column {
        grid-column-end:span 2;
    }
}
.form-container.bigger {
    grid-auto-rows: 75px;
}

.max-content-width {
    width: 100%;
    max-width: 960px;
    margin: 0rem auto;
}

.landing-header {
    display: grid;
    grid-template-columns: 110px auto;
    align-items: baseline;
    padding-left: 16px;
    max-width: 960px;
    width: 100%;
    margin: 0rem auto;
    font-weight: 300;
    svg {
        height: 16px;
        width: 42px;
    }
}

