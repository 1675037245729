@use './../base/color';

div.inner-notification {
    display: grid;
    grid-template-columns: 4fr 1fr;
    max-width: 200px;
    margin: 0.5rem auto;
    background-color: color.getColor('yellow', base);;
    border-radius: 30px;
    padding: 0.5rem 1rem;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    align-items: center;
    font-size: 14px;
    .actions-notification {
        text-align: right;
    }
    button {
        background: none;
        border: none;
        width: 25px;
        height: 25px;
        svg {
            width: 20px;
            height: 20px;
            fill: color.getColor('black', base);
        }
    }
}