@use './../base/color';
@import '~pikaday/css/pikaday.css';


.input-calendar-style {
    position: relative;
    input {
        height: 56px;
        font-size: 1rem;
        padding-left: 2.5rem;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid color.getColor('black', base);
        background-color: whitesmoke;
        width: 100%;
        display: block;
        box-sizing: border-box;
    }
    svg{ 
        position: absolute;
        width: 25px;
        height: 25px;
        top: 13px;
        left: 10px;
        fill: color.getColor('black', base);
    }
}