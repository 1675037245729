@use './../base/color';

.card-element {
    display: grid;
    grid-template-columns: 25px 1fr 20px;
    align-items: center;
    color: color.getColor('black', base);
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    margin-bottom: 1rem;
    padding: 1rem 16px;
    border-radius: 4px;
    span {
        padding-left: 10px;
    }
    svg.icon-card {
        width: 25px;
        height: 25px;
        fill: color.getColor('black', base);
    }
    svg.navigation {
        width: 20px;
        height: 20px;
        fill: color.getColor('blue', base);
    }
}
.element-card-slim {
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 1rem;
    .expense-value {
        display: grid;
        grid-template-columns: 2fr 1fr;
        font-size: 16px;
        padding: 4px 0px;
    }
    .category-value{
        color: color.getColor('violet', base);
        font-size: 14px;
    }
}