@use './color';
* {
    margin: 0rem;
    padding: 0rem;
    box-sizing: border-box;
}

[hidden] { display: none !important; }

body {
    background-color: color.getColor('blackapp', base);
    color:color.getColor('black', base);;
    margin: 0;
    display: flex;
    height: 100vh;
    font-family: 'Nunito Sans', sans-serif;
}


section.accent {
    background-image: linear-gradient(132deg, color.getColor('blue', base) 0%, color.getColor('blue', base) 50%, color.getColor('blue', light) 100%);
    background-color: color.getColor('blue', base);
    color: color.getColor('white', base);
    padding: 1rem 0.5rem;
    box-sizing: border-box;
}

.main-content {
    overflow: auto;
    height: 100%;
    width: 100%;
}

.yellow-header { 
    color: color.getColor('yellow', base);
}
.vertical-padding {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.vertical-extra-padding {
    padding-top: 3rem;
    padding-bottom: 3rem;
}
.side-margin {
    padding-right: 16px;
    padding-left: 16px;
}

.full-height {
    min-height: 100vh;
}

.section-margin-top {
    margin-top: 3rem;
}

.centered-section {
    text-align: center;
    margin: 0.5rem 0rem;
}

.margin-center {
    margin: 0rem auto;
}

.square {
    position: relative;
    width: 50%;
    margin: 0rem auto;
    svg {
        top: 0rem;
        left: 0rem;
        position: absolute;
        width: 100%;
        height: 100%;
    }
}
  
.square:after {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.margin-top {
    margin-top: 0.5rem;
}

.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
