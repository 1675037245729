@use './../base/color';
@use "@material/button";
@include button.core-styles;


.accent-button {
    @include button.ink-color(color.getColor('white', base));
}
.accent-button.main-button {
    @include button.container-fill-color(color.getColor('white', base));
    @include button.ink-color(color.getColor('blue', base));
}
.main-button {
    @include button.container-fill-color(color.getColor('blue', base));
    @include button.ink-color(color.getColor('white', base));
    
}
.main-button-oulined{
    @include button.outline-color(color.getColor('blue', base));
    @include button.ink-color(color.getColor('blue', base));
}
.accent-button.main-button-oulined{
    @include button.outline-color(color.getColor('white', base));
    @include button.ink-color(color.getColor('white', base));
}

.small-main-button-oulined{
    @include button.outline-color(color.getColor('blue', base));
    @include button.ink-color(color.getColor('blue', base));
    @include button.height(30px);
}
.small-main-button-raised{
    @include button.container-fill-color(color.getColor('blue', base));
    @include button.ink-color(color.getColor('white', base));
    @include button.height(30px);
}

button.delete {
    background-color: transparent;
    border: none;
    svg {
        width: 25px;
        height: 25px;
        fill: color.getColor('black', base);
    }
}

.mdc-button.small {
    @include button.height(30px);
}

.text-actions {
    button {
        display: block;
        margin: 0rem auto;
    }
}

.side-actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
}

.actions.min-width-button {
    button.mdc-button--raised, button.mdc-button--outlined{
        min-width: 180px
    }
}

.accent-text-button,
.accent-outlined-button {
    @include button.outline-color(color.getColor('white', base));
    @include button.ink-color(color.getColor('white', base));
}

.enlarge-button {
    width: 170px;
    display: block;
    margin: 0.5rem auto;
}