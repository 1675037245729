@use './../base/color';


.data-hint {
    border-radius: 4px;
    padding: 0.25rem;
    display: block;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    background-color: color.getColor('blue', base);
    .name {
        display: block;
        font-size: 10px;
        font-weight: 700;
    }
    .value {
        display: block;
        font-weight: 600;
        font-size: 14px;
        text-align: center;
    }
}

.data-hint.orange {
    background-color: color.getColor('orange', base);
}

.data-hint.green {
    background-color: color.getColor('green', base);
}

.data-hint.red {
    background-color: color.getColor('red', base);
}