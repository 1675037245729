@use './../base/color';
@use "nouislider/distribute/nouislider.css";

.noUi-connect {
    background: color.getColor('blue', light);
}

.noUi-target {
    width: 90%;
    margin-top: 3rem;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
}