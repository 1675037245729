@use "@material/list/mdc-list";
@use "@material/menu-surface/mdc-menu-surface";
@use "@material/menu/mdc-menu";
@use "@material/select/mdc-select";
@use "@material/select/mixins" as select;

.demo-width-class {
    min-width: auto;
    width: 100%;
    @include select.filled-height(30px);
    @include select.filled-with-leading-icon-height(30px);
}

.select-width-margin {
    width: 100%;
    margin: 1rem 0rem;
}

div.small-select {
    @include select.core-styles();
}

div.myselect {
    
    @include select.outline-color(white);
    
    // @include select.container-fill-color(red);
    // @include select.bottom-line-color(red);
    // @include select.ink-color(white)
}
.mdc-list-item__text.with-icon  {
    display: grid;
    grid-template-columns: 1fr 20px;
    align-items: center;
    width: 100%;
    svg {
        width: 20px;
        height: 20px;
    }
}