@use './../base/color';

.landingview {
    img {
        width: 100%;
    }
    h2.hero {
        margin-top: 100px;
        margin-bottom: 50px;
        font-size: 2rem;
    }
    .hero-section {
        position: relative;
        .fixed-to-bottom {
            position: absolute;
            bottom: 8rem;
            left: 0rem;
            width: 100%;   
        }
    }
    .benefits {
        padding-bottom: 4.5rem;
        display: grid;
        align-items: center;
        .icon-benefit {
            width: 50%;
            margin: 0rem auto;
            grid-area: icon;
            padding: 10px;
            svg {
                fill: color.getColor('blue', base) ;
            }
        }
        h2 {
            text-align: center;
        }
        p {
            grid-area: text;
            margin: 0.5rem 0rem;
            text-align: center;
            font-size: 1.25rem;
            font-weight: 300;
        }
    }
    ul {
        line-height: 1.75rem;
        font-size: 1.25rem;
        font-weight: 300;
        padding: 1rem 0rem;
        padding-left: 2rem;
        li {
            padding: 1rem 0rem;
        }
    }

    .comparison-table {
        border-radius: 4px;
        background-color: color.getColor('blackapp', light) ;;
        box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
        padding: 1rem 0.5rem;
        max-width: 500px;
        margin: 0rem auto;
        .header-table {
            font-size: 16px;
            font-weight: 600;
        }
    }
    

    .comparison-table .row-3 {
        display: grid;
        grid-template-columns: 1fr 0.805fr 0.805fr;
        text-align: center;
        align-items: center;
        padding: 0.5rem 0rem;
        div {
            display: flex;
            justify-content: center;
        }
    }

    .comparison-table svg {
        width: 20px;
        height: 20px;
    }
    
    .price-table {
        text-align: center;
        background-color: color.getColor('blue', base) ;
        background-image: linear-gradient(135deg, color.getColor('blue', base) 0%, color.getColor('blue', light) 100%);
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        border-radius: 4px;
        width: 250px;
        height: 300px;
        display: block;
        position: relative;
        margin: 0px auto;
        margin-bottom: 4rem;
        
        div.wrapper-content {
            display: grid;
            grid-template-rows: 1.61fr 1fr;
            height: 300px;
            width: 100%;
            top: 0rem;
            left: 0rem;
            color: color.getColor('white', base);
            font-family: 'Montserrat', sans-serif;

            div.price {
                align-self: center;
                font-size: 3rem;
            }

            div.hero-2 {
                font-size: 16px;
                margin: 0.5rem 0rem;
            }

            div.footer{
                font-size: 18px;
                font-weight: bold;
            }
        }
        button {
            position: absolute;
            margin: 0rem;
            width: 150px;
            left: 50px;
            bottom: -1rem;
            border-radius: 20px;
        }

        div.wrapper-button {
            display: block;
            position: absolute;

        }
    }
      
    .mainlandingsection {
        display: grid;
        align-items: center;
        grid-template-rows:50px auto;
    }
    
    .landing-hero {
        display: grid;
        justify-self: center;
        max-width: 960px;
        .actions {
            margin-top: 3rem;
           
        }
        h1.hero{
            text-align: left;
        }
        .hero-side-header {
            display: grid;
            align-items: center;    
        }

    }

    .blog-list {
        .blog-post {
            display: grid;
            grid-template-columns: 1fr 1.61fr;
            gap: 10px;
            max-width: 320px;

            img {
                border-radius: 4px;
            }

            .title {
                display: grid;
                align-items: center;
            }
        }
    }
}


@media (min-width:320px)  {
    /* smartphones, iPhone, portrait 480x320 phones */
    .phone-animation {
        width: 100%;
    }
    .hero-side-header {
        height: 100vh;
    }
    .landingview .benefits {
        grid-template-areas: "icon" "text";
        grid-template-rows: 1.61fr 1fr ;
    }

    .landingview .benefits.vertical {
        grid-template-rows: 1fr 1.61fr ;
    }
    
}
@media (min-width:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ 
    .wrapper-container-three {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
    .landing-hero {
        grid-template-columns: 1.61fr 1fr;
    }
    .hero-side-header {
        height: initial;
    }
    .enlarge-button:not(.centered) {
        margin: 0.5rem 0rem;
    }
    .landingview .benefits {
        width: 80%;
        margin: 0rem auto;
    }
    .landingview .benefits h2 {
        text-align: center;
    }
    .landingview .benefits p {
        font-size: 16px;
    }

    .benefits.left {
        grid-template-rows: none;
        grid-template-columns: 1fr 1.61fr;
        grid-template-areas: "icon text";
    }
    .tip {
        width: 80%;
        margin: 0rem auto;
        q {
            font-size: 1.25rem;
        }
    }

}

