@use './../base/color';


.add-alarm-to-budget {
    padding: 0.5rem;
    background-color: color.getColor('white', base);
    border-radius: 4px;
    border: 1px solid color.getColor('blue', base);
    .slider {
        width: 80%;
    }
    
}