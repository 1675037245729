@use './../base/color';

div.category-setup {
    background-color: color.getColor('white', base);
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-areas: 'name delete';
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    align-items: center;
    height: 46px;
    padding: 0.5rem;
    box-sizing: border-box;
    margin-bottom: 0.5rem;
    .name {
        width: 100%;
        grid-area: name;
        padding-left: 0.5rem;
        font-size: 1.125rem;
        box-sizing: border-box;
        label {
            width: 100%;
        }

    }
    button.delete {
        background-color: transparent;
        border: none;
        grid-area: delete;
        svg {
            width: 25px;
            height: 25px;
            fill: color.getColor('grey', base);
        }
    }
    button.confirm {
        svg {
            width: 25px;
            height: 25px;
            fill: color.getColor('green', base);
            -webkit-filter: drop-shadow( 0px 1px 2px rgba(0, 0, 0, .12));
            filter: drop-shadow( 0px 1px 2px rgba(0, 0, 0, .12));
        }
    }
}

div.category-setup.add {
    background-color: color.getColor('white', base);
    .action-button {
        grid-column-start: name;
        grid-column-end: delete;
    }
}
div.dategrouping[data-drawer-showing="true"] {
    .header {
        svg {
            -webkit-transform: rotate(90deg);  /* to support Safari and Android browser */
            -ms-transform: rotate(90deg);      /* to support IE 9 */
            transform: rotate(90deg);
        }
    }
}

div.category{
    padding: 0.5rem;
    border-radius: 4px;
    background-color: color.getColor('white', base);
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    margin-bottom: 0.5rem;
    div.header {
        display: grid;
        grid-template-columns: 3fr 5fr;
        border-bottom: 1px solid;
        span.total {
            text-align: right;
        }
    }
    div.header.with-icon {
        display: grid;
        grid-template-columns: 20px 3fr 5fr;
        border-bottom: none;
        padding-top: 10px;
        font-size: 14px;
        span.total {
            text-align: right;
        }
        svg {
            width: 20px;
            height: 20px;
            transition: .3s all;
            fill: color.getColor('blue', base);
        }
    }
    div.content.with-icon {
        padding-left: 20px;
    }
    div.content {
        transition: max-height 0.2s;
        overflow: hidden;
        .hash-and-users {
            display: grid;
            grid-template-columns: 1fr 1.61fr;
            span {
                font-size: 12px;
                font-weight: bold;
                color: color.getColor('violet', base);
            }
            .usertag {
                padding: 0.125rem;
                border-radius: 4px;
                margin-right: 0.25rem;
                color: color.getColor('white', base);
            }
            .usertag.user-color-0 {
                background: color.getColor('violet', base);;
            }
            .usertag.user-color-1 {
                background: color.getColor('green', base);;
            }
            .usertag.user-color-2 {
                background: color.getColor('orange', base);;
            }
            .usertag.user-color-4 {
                background: color.getColor('yellow', base);;
            }
        }
    }
    div.content[aria-hidden="true"] {
        max-height: 0;
    }
    .content[aria-hidden="false"] {
        max-height: var(--containerHeight, 1000px);
      }
    div.detail-invoice {
        display: grid;
        grid-template-areas:
                            "amountbill business total"
                            "progress progress progress";
        grid-template-columns: 20px 5fr 3fr;
        margin: 0.5rem 0rem;
        font-weight: 400;
        font-size: small;
        span.amount-bill {
            background-color: color.getColor('violet', base);
            color: color.getColor('white', base);
            border-radius: 50%;
            height: 18px;
            width: 18px;
            text-align: center;
            font-weight: 600;
            font-size: 11px;
            grid-area: amountbill;
            line-height: 1.25rem;
        }
        span.business {
            grid-area: business;
            line-height: 1.25rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-left: 0.125rem;
        }
        span.total {
            grid-area: total;
            text-align: right;
            line-height: 1.25rem;
        }
        div.progress {
            grid-area: progress;
            display: flex;
            margin-top: 0.25rem;
            height: 2px;
            div.segment {
                height: 2px;
            }
            div.segment.user-color-0 {
                background: color.getColor('violet', base);;
            }
            div.segment.user-color-1 {
                background: color.getColor('green', base);;
            }
            div.segment.user-color-2 {
                background: color.getColor('orange', base);;
            }
            div.segment.user-color-4 {
                background: color.getColor('yellow', base);;
            }
        }
    }
    div.content-category {
        
    }
}

div.category-header {
    box-sizing: border-box;
    padding: 1rem 16px;
    background-color: color.getColor('blue', base);
    color: color.getColor('white', base);
    display: grid;
    grid-template-areas:"subtitle subtitle subtitle menu"
                        "header header header menu"
                        "budget budget budget menu";
    grid-template-columns: 1fr 1fr 1fr 25px;
    span.subheader {
        grid-area: subtitle;
        font-size: 12px;
        margin: 0.25rem 0rem;
    }
    h3 {
        margin: 0rem;
        grid-area: header;
        align-self: end;
    }
    .kebab-menu {
        grid-area: menu;
        align-self: center;
    }
    .budget {
        grid-area: budget;
        padding-top: 0.25rem;
        font-size: 14px;
        font-weight: bold;
    }
    svg {
        
        fill: color.getColor('white', base);
    }
}

div.category-invitation {
    display: grid;
    grid-template-columns: 1fr 40px;
    padding: 0.5rem;
    background-color: color.getColor('white', base);;
    border-radius: 4px;
    align-items: center;
    button.delete {
        svg {
            fill: color.getColor('blue', base);
        }
    }
}

div.pending-category-invitation {
    padding: 0.5rem;
    background-color: color.getColor('white', base);;
    border-radius: 4px;
    margin-bottom: 0.5rem;
    button {
        margin: none;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
}

div.category-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    div.category-item {
        display: grid;
        background-color: color.getColor('white', base);;
        padding: 0.75rem 0.5rem;
        border-radius: 4px;
        box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    }
  }