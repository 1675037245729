@use './../base/color';
div.sectionview {
    min-height: 100vh;
}
.box-section {
    background-color: color.getColor('white', base);
    color: #3e3e3e;
    padding: 0.5rem;
    border-radius: 4px;
    margin: 2rem 0rem;

    .header-section {        
        svg {
            fill: color.getColor('blue', base);
        }
        p {
            color: color.getColor('blue', base);
        }
    }
    .action-highlight{
        padding: 1rem 0rem;
        font-weight: bold;
    }
}