div.viewscontainer {
    width: 100%;
    min-height: 100vh;
    overflow-x: hidden;
    position: relative;
    // background: #9000B3; /* For browsers that do not support gradients */
    // background: -webkit-linear-gradient(left top, #9000B3, #1416B4); /* For Safari 5.1 to 6.0 */
    // background: -o-linear-gradient(bottom right, #9000B3, #1416B4); /* For Opera 11.1 to 12.0 */
    // background: -moz-linear-gradient(bottom right, #9000B3, #1416B4); /* For Firefox 3.6 to 15 */
    // background: linear-gradient(to bottom right, #9000B3, #1416B4); /* Standard syntax */
    div.sectionview {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        will-change: transform;
        transition: -webkit-transform 0.3s cubic-bezier(0.465, 0.183, 0.153, 0.946);
        transition: transform 0.3s cubic-bezier(0.465, 0.183, 0.153, 0.946);
    }
    div.no-transition {
        -webkit-transition: none !important;
        -moz-transition: none !important;
        -o-transition: none !important;
        transition: none !important;
    }
    div.rightcommingsection {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
    div.leftcommingsection {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}
// helper classes
div.rightchangeview {
    div.currentsection {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
    div.rightcommingsection {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }  
}
div.leftchangeview {
    div.currentsection {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
    div.leftcommingsection {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}