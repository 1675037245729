@use './../base/color';

.grid-button {
    --auto-grid-min-size: 4rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
    gap: 4px;
    
    .button {
        position: relative;
        box-sizing: border-box;
        border-radius: 4px;
        background-color: color.getColor('white', base);
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        svg {
            width: 100%;
            height: 100%;
        }
    }

    .button::before {
        content: '';
        display: block;
        padding-top: 100%;
    }
    
    .button .content {
        position: absolute;
        top: 0; left: 0;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        padding: 0.75rem;
    }

    .button.selected {
        border: 3px solid color.getColor('blue', base);
        -webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
        -moz-box-shadow: inset 0px 0px 5px #c1c1c1;
             box-shadow: inset 0px 0px 5px #c1c1c1;
    }
    
}