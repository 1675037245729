@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700&family=Nunito+Sans:wght@300;400;600&display=swap");
@import '~pikaday/css/pikaday.css';
:root {
  --top-bar-height: 50px;
  --height-full: calc(100vh - 50px);
}

div.wrapper-content {
  display: grid;
}

div.main-home {
  display: grid;
  grid-template-columns: auto;
  column-gap: 10px;
  row-gap: 10px;
  padding: 12px;
}

.form-container {
  display: grid;
  padding: 0rem 0rem;
  grid-auto-rows: 70px;
  grid-auto-columns: 1fr 1fr;
  grid-gap: 5px;
}
.form-container > label, .form-container div.two-column {
  grid-column-end: span 2;
}

.form-container.bigger {
  grid-auto-rows: 75px;
}

.max-content-width {
  width: 100%;
  max-width: 960px;
  margin: 0rem auto;
}

.landing-header {
  display: grid;
  grid-template-columns: 110px auto;
  align-items: baseline;
  padding-left: 16px;
  max-width: 960px;
  width: 100%;
  margin: 0rem auto;
  font-weight: 300;
}
.landing-header svg {
  height: 16px;
  width: 42px;
}

.landingview img {
  width: 100%;
}
.landingview h2.hero {
  margin-top: 100px;
  margin-bottom: 50px;
  font-size: 2rem;
}
.landingview .hero-section {
  position: relative;
}
.landingview .hero-section .fixed-to-bottom {
  position: absolute;
  bottom: 8rem;
  left: 0rem;
  width: 100%;
}
.landingview .benefits {
  padding-bottom: 4.5rem;
  display: grid;
  align-items: center;
}
.landingview .benefits .icon-benefit {
  width: 50%;
  margin: 0rem auto;
  grid-area: icon;
  padding: 10px;
}
.landingview .benefits .icon-benefit svg {
  fill: #0077FF;
}
.landingview .benefits h2 {
  text-align: center;
}
.landingview .benefits p {
  grid-area: text;
  margin: 0.5rem 0rem;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 300;
}
.landingview ul {
  line-height: 1.75rem;
  font-size: 1.25rem;
  font-weight: 300;
  padding: 1rem 0rem;
  padding-left: 2rem;
}
.landingview ul li {
  padding: 1rem 0rem;
}
.landingview .comparison-table {
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 1rem 0.5rem;
  max-width: 500px;
  margin: 0rem auto;
}
.landingview .comparison-table .header-table {
  font-size: 16px;
  font-weight: 600;
}
.landingview .comparison-table .row-3 {
  display: grid;
  grid-template-columns: 1fr 0.805fr 0.805fr;
  text-align: center;
  align-items: center;
  padding: 0.5rem 0rem;
}
.landingview .comparison-table .row-3 div {
  display: flex;
  justify-content: center;
}
.landingview .comparison-table svg {
  width: 20px;
  height: 20px;
}
.landingview .price-table {
  text-align: center;
  background-color: #0077FF;
  background-image: linear-gradient(135deg, #0077FF 0%, #00C3FF 100%);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  width: 250px;
  height: 300px;
  display: block;
  position: relative;
  margin: 0px auto;
  margin-bottom: 4rem;
}
.landingview .price-table div.wrapper-content {
  display: grid;
  grid-template-rows: 1.61fr 1fr;
  height: 300px;
  width: 100%;
  top: 0rem;
  left: 0rem;
  color: #F7F7F7;
  font-family: "Montserrat", sans-serif;
}
.landingview .price-table div.wrapper-content div.price {
  align-self: center;
  font-size: 3rem;
}
.landingview .price-table div.wrapper-content div.hero-2 {
  font-size: 16px;
  margin: 0.5rem 0rem;
}
.landingview .price-table div.wrapper-content div.footer {
  font-size: 18px;
  font-weight: bold;
}
.landingview .price-table button {
  position: absolute;
  margin: 0rem;
  width: 150px;
  left: 50px;
  bottom: -1rem;
  border-radius: 20px;
}
.landingview .price-table div.wrapper-button {
  display: block;
  position: absolute;
}
.landingview .mainlandingsection {
  display: grid;
  align-items: center;
  grid-template-rows: 50px auto;
}
.landingview .landing-hero {
  display: grid;
  justify-self: center;
  max-width: 960px;
}
.landingview .landing-hero .actions {
  margin-top: 3rem;
}
.landingview .landing-hero h1.hero {
  text-align: left;
}
.landingview .landing-hero .hero-side-header {
  display: grid;
  align-items: center;
}
.landingview .blog-list .blog-post {
  display: grid;
  grid-template-columns: 1fr 1.61fr;
  gap: 10px;
  max-width: 320px;
}
.landingview .blog-list .blog-post img {
  border-radius: 4px;
}
.landingview .blog-list .blog-post .title {
  display: grid;
  align-items: center;
}

@media (min-width: 320px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .phone-animation {
    width: 100%;
  }

  .hero-side-header {
    height: 100vh;
  }

  .landingview .benefits {
    grid-template-areas: "icon" "text";
    grid-template-rows: 1.61fr 1fr;
  }

  .landingview .benefits.vertical {
    grid-template-rows: 1fr 1.61fr;
  }
}
@media (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  .wrapper-container-three {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .landing-hero {
    grid-template-columns: 1.61fr 1fr;
  }

  .hero-side-header {
    height: initial;
  }

  .enlarge-button:not(.centered) {
    margin: 0.5rem 0rem;
  }

  .landingview .benefits {
    width: 80%;
    margin: 0rem auto;
  }

  .landingview .benefits h2 {
    text-align: center;
  }

  .landingview .benefits p {
    font-size: 16px;
  }

  .benefits.left {
    grid-template-rows: none;
    grid-template-columns: 1fr 1.61fr;
    grid-template-areas: "icon text";
  }

  .tip {
    width: 80%;
    margin: 0rem auto;
  }
  .tip q {
    font-size: 1.25rem;
  }
}
* {
  margin: 0rem;
  padding: 0rem;
  box-sizing: border-box;
}

[hidden] {
  display: none !important;
}

body {
  background-color: #FBFBFF;
  color: #3e3e3e;
  margin: 0;
  display: flex;
  height: 100vh;
  font-family: "Nunito Sans", sans-serif;
}

section.accent {
  background-image: linear-gradient(132deg, #0077FF 0%, #0077FF 50%, #00C3FF 100%);
  background-color: #0077FF;
  color: #F7F7F7;
  padding: 1rem 0.5rem;
  box-sizing: border-box;
}

.main-content {
  overflow: auto;
  height: 100%;
  width: 100%;
}

.yellow-header {
  color: #FEA735;
}

.vertical-padding {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.vertical-extra-padding {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.side-margin {
  padding-right: 16px;
  padding-left: 16px;
}

.full-height {
  min-height: 100vh;
}

.section-margin-top {
  margin-top: 3rem;
}

.centered-section {
  text-align: center;
  margin: 0.5rem 0rem;
}

.margin-center {
  margin: 0rem auto;
}

.square {
  position: relative;
  width: 50%;
  margin: 0rem auto;
}
.square svg {
  top: 0rem;
  left: 0rem;
  position: absolute;
  width: 100%;
  height: 100%;
}

.square:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.margin-top {
  margin-top: 0.5rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

h3.hero {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

h2.hero {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

h1.hero {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  text-align: center;
  margin: 0;
  padding: 1rem 0;
  font-size: 2rem;
}

.underline {
  text-decoration: underline;
}

h3.module-header {
  margin: 0rem;
  font-weight: 500;
}

p.subheader {
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 300;
}

p.body {
  line-height: 1.5rem;
}

p.hero {
  line-height: 1.75rem;
  font-size: 1.25rem;
  font-weight: 300;
}

p.hero.bold {
  font-weight: 500;
}

q {
  font-style: italic;
  font-weight: 300;
}

span.error {
  color: #D1463F;
  font-size: 14px;
}

.mdc-touch-target-wrapper {
  display: inline;
}

.mdc-elevation-overlay {
  position: absolute;
  border-radius: inherit;
  opacity: 0;
  pointer-events: none;
  transition: opacity 280ms cubic-bezier(0.4, 0, 0.2, 1);
  background-color: #fff;
}

.mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-button-font-size, 0.875rem);
  line-height: 2.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-button-line-height, 2.25rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: 0.0892857143em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-button-letter-spacing, 0.0892857143em);
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: uppercase;
  /* @alternate */
  text-transform: var(--mdc-typography-button-text-transform, uppercase);
  padding: 0 8px 0 8px;
  /* @alternate */
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-width: 64px;
  border: none;
  outline: none;
  /* @alternate */
  line-height: inherit;
  user-select: none;
  -webkit-appearance: none;
  overflow: visible;
  vertical-align: middle;
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-small, 4px);
  height: 36px;
}
.mdc-button .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */
  left: 0;
}
.mdc-button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.mdc-button:active {
  outline: none;
}
.mdc-button:hover {
  cursor: pointer;
}
.mdc-button:disabled {
  cursor: default;
  pointer-events: none;
}
.mdc-button .mdc-button__ripple {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-small, 4px);
}
.mdc-button:not(:disabled) {
  background-color: transparent;
}
.mdc-button:disabled {
  background-color: transparent;
}
.mdc-button .mdc-button__icon {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 8px;
  display: inline-block;
  width: 18px;
  height: 18px;
  font-size: 18px;
  vertical-align: top;
}
[dir=rtl] .mdc-button .mdc-button__icon, .mdc-button .mdc-button__icon[dir=rtl] {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 0;
}
.mdc-button .mdc-button__touch {
  position: absolute;
  top: 50%;
  right: 0;
  height: 48px;
  left: 0;
  transform: translateY(-50%);
}
.mdc-button:not(:disabled) {
  color: #0077FF;
  /* @alternate */
  color: var(--mdc-theme-primary, #0077FF);
}
.mdc-button:disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mdc-button__label + .mdc-button__icon {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 0;
}
[dir=rtl] .mdc-button__label + .mdc-button__icon, .mdc-button__label + .mdc-button__icon[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 8px;
}

svg.mdc-button__icon {
  fill: currentColor;
}

.mdc-button--raised .mdc-button__icon,
.mdc-button--unelevated .mdc-button__icon,
.mdc-button--outlined .mdc-button__icon {
  /* @noflip */
  margin-left: -4px;
  /* @noflip */
  margin-right: 8px;
}
[dir=rtl] .mdc-button--raised .mdc-button__icon, .mdc-button--raised .mdc-button__icon[dir=rtl],
[dir=rtl] .mdc-button--unelevated .mdc-button__icon,
.mdc-button--unelevated .mdc-button__icon[dir=rtl],
[dir=rtl] .mdc-button--outlined .mdc-button__icon,
.mdc-button--outlined .mdc-button__icon[dir=rtl] {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: -4px;
}
.mdc-button--raised .mdc-button__label + .mdc-button__icon,
.mdc-button--unelevated .mdc-button__label + .mdc-button__icon,
.mdc-button--outlined .mdc-button__label + .mdc-button__icon {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: -4px;
}
[dir=rtl] .mdc-button--raised .mdc-button__label + .mdc-button__icon, .mdc-button--raised .mdc-button__label + .mdc-button__icon[dir=rtl],
[dir=rtl] .mdc-button--unelevated .mdc-button__label + .mdc-button__icon,
.mdc-button--unelevated .mdc-button__label + .mdc-button__icon[dir=rtl],
[dir=rtl] .mdc-button--outlined .mdc-button__label + .mdc-button__icon,
.mdc-button--outlined .mdc-button__label + .mdc-button__icon[dir=rtl] {
  /* @noflip */
  margin-left: -4px;
  /* @noflip */
  margin-right: 8px;
}

.mdc-button--raised,
.mdc-button--unelevated {
  padding: 0 16px 0 16px;
}
.mdc-button--raised:not(:disabled),
.mdc-button--unelevated:not(:disabled) {
  background-color: #0077FF;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #0077FF);
}
.mdc-button--raised:not(:disabled),
.mdc-button--unelevated:not(:disabled) {
  color: #442b2d;
  /* @alternate */
  color: var(--mdc-theme-on-primary, #442b2d);
}
.mdc-button--raised:disabled,
.mdc-button--unelevated:disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mdc-button--raised:disabled,
.mdc-button--unelevated:disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mdc-button--raised {
  /* @alternate */
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}
.mdc-button--raised:hover, .mdc-button--raised:focus {
  /* @alternate */
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mdc-button--raised:active {
  /* @alternate */
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mdc-button--raised:disabled {
  /* @alternate */
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mdc-button--outlined {
  padding: 0 15px 0 15px;
  border-width: 1px;
  border-style: solid;
}
.mdc-button--outlined .mdc-button__ripple {
  top: -1px;
  left: -1px;
  border: 1px solid transparent;
}
.mdc-button--outlined .mdc-button__touch {
  left: -1px;
  width: calc(100% + 2 * 1px);
}
.mdc-button--outlined:not(:disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.mdc-button--outlined:disabled {
  border-color: rgba(0, 0, 0, 0.12);
}

.mdc-button--touch {
  margin-top: 6px;
  margin-bottom: 6px;
}

@keyframes mdc-ripple-fg-radius-in {
  from {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1);
  }
  to {
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
  }
}
@keyframes mdc-ripple-fg-opacity-in {
  from {
    animation-timing-function: linear;
    opacity: 0;
  }
  to {
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
}
@keyframes mdc-ripple-fg-opacity-out {
  from {
    animation-timing-function: linear;
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
  to {
    opacity: 0;
  }
}
.mdc-button {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-button .mdc-button__ripple::before,
.mdc-button .mdc-button__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-button .mdc-button__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-button .mdc-button__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-button.mdc-ripple-upgraded .mdc-button__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-button.mdc-ripple-upgraded .mdc-button__ripple::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-button.mdc-ripple-upgraded--unbounded .mdc-button__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-button.mdc-ripple-upgraded--foreground-activation .mdc-button__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-button.mdc-ripple-upgraded--foreground-deactivation .mdc-button__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-button .mdc-button__ripple::before,
.mdc-button .mdc-button__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-button.mdc-ripple-upgraded .mdc-button__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-button .mdc-button__ripple::before, .mdc-button .mdc-button__ripple::after {
  background-color: #0077FF;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #0077FF));
}
.mdc-button:hover .mdc-button__ripple::before, .mdc-button.mdc-ripple-surface--hover .mdc-button__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-button.mdc-ripple-upgraded--background-focused .mdc-button__ripple::before, .mdc-button:not(.mdc-ripple-upgraded):focus .mdc-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-button:not(.mdc-ripple-upgraded) .mdc-button__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-button:not(.mdc-ripple-upgraded):active .mdc-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-button.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-button .mdc-button__ripple {
  position: absolute;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.mdc-button:not(.mdc-button--outlined) .mdc-button__ripple {
  top: 0;
  left: 0;
}

.mdc-button--raised .mdc-button__ripple::before, .mdc-button--raised .mdc-button__ripple::after,
.mdc-button--unelevated .mdc-button__ripple::before,
.mdc-button--unelevated .mdc-button__ripple::after {
  background-color: #442b2d;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-primary, #442b2d));
}
.mdc-button--raised:hover .mdc-button__ripple::before, .mdc-button--raised.mdc-ripple-surface--hover .mdc-button__ripple::before,
.mdc-button--unelevated:hover .mdc-button__ripple::before,
.mdc-button--unelevated.mdc-ripple-surface--hover .mdc-button__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-button--raised.mdc-ripple-upgraded--background-focused .mdc-button__ripple::before, .mdc-button--raised:not(.mdc-ripple-upgraded):focus .mdc-button__ripple::before,
.mdc-button--unelevated.mdc-ripple-upgraded--background-focused .mdc-button__ripple::before,
.mdc-button--unelevated:not(.mdc-ripple-upgraded):focus .mdc-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-button--raised:not(.mdc-ripple-upgraded) .mdc-button__ripple::after,
.mdc-button--unelevated:not(.mdc-ripple-upgraded) .mdc-button__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-button--raised:not(.mdc-ripple-upgraded):active .mdc-button__ripple::after,
.mdc-button--unelevated:not(.mdc-ripple-upgraded):active .mdc-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-button--raised.mdc-ripple-upgraded,
.mdc-button--unelevated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-top-app-bar {
  background-color: #0077FF;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #0077FF);
  color: white;
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  z-index: 4;
}
.mdc-top-app-bar .mdc-top-app-bar__action-item,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon {
  color: #442b2d;
  /* @alternate */
  color: var(--mdc-theme-on-primary, #442b2d);
}
.mdc-top-app-bar .mdc-top-app-bar__action-item::before, .mdc-top-app-bar .mdc-top-app-bar__action-item::after,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon::after {
  background-color: #442b2d;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-primary, #442b2d));
}
.mdc-top-app-bar .mdc-top-app-bar__action-item:hover::before, .mdc-top-app-bar .mdc-top-app-bar__action-item.mdc-ripple-surface--hover::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon:hover::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon.mdc-ripple-surface--hover::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-top-app-bar .mdc-top-app-bar__action-item.mdc-ripple-upgraded--background-focused::before, .mdc-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded):focus::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded--background-focused::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded)::after,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded):active::after,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-top-app-bar .mdc-top-app-bar__action-item.mdc-ripple-upgraded,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-top-app-bar__row {
  display: flex;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 64px;
}
.mdc-top-app-bar__section {
  display: inline-flex;
  flex: 1 1 auto;
  align-items: center;
  min-width: 0;
  padding: 8px 12px;
  z-index: 1;
}
.mdc-top-app-bar__section--align-start {
  justify-content: flex-start;
  order: -1;
}
.mdc-top-app-bar__section--align-end {
  justify-content: flex-end;
  order: 1;
}
.mdc-top-app-bar__title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1.25rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-headline6-font-weight, 500);
  letter-spacing: 0.0125em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, 0.0125em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
  /* @noflip */
  padding-left: 20px;
  /* @noflip */
  padding-right: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  z-index: 1;
}
[dir=rtl] .mdc-top-app-bar__title, .mdc-top-app-bar__title[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 20px;
}

.mdc-top-app-bar--short-collapsed {
  /* @noflip */
  border-top-left-radius: 0;
  /* @noflip */
  border-top-right-radius: 0;
  /* @noflip */
  border-bottom-right-radius: 24px;
  /* @noflip */
  border-bottom-left-radius: 0;
}
[dir=rtl] .mdc-top-app-bar--short-collapsed, .mdc-top-app-bar--short-collapsed[dir=rtl] {
  /* @noflip */
  border-top-left-radius: 0;
  /* @noflip */
  border-top-right-radius: 0;
  /* @noflip */
  border-bottom-right-radius: 0;
  /* @noflip */
  border-bottom-left-radius: 24px;
}

.mdc-top-app-bar--short {
  top: 0;
  /* @noflip */
  right: auto;
  /* @noflip */
  left: 0;
  width: 100%;
  transition: width 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
[dir=rtl] .mdc-top-app-bar--short, .mdc-top-app-bar--short[dir=rtl] {
  /* @noflip */
  right: 0;
  /* @noflip */
  left: auto;
}
.mdc-top-app-bar--short .mdc-top-app-bar__row {
  height: 56px;
}
.mdc-top-app-bar--short .mdc-top-app-bar__section {
  padding: 4px;
}
.mdc-top-app-bar--short .mdc-top-app-bar__title {
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 1;
}

.mdc-top-app-bar--short-collapsed {
  /* @alternate */
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  width: 56px;
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1);
}
.mdc-top-app-bar--short-collapsed .mdc-top-app-bar__title {
  display: none;
}
.mdc-top-app-bar--short-collapsed .mdc-top-app-bar__action-item {
  transition: padding 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item {
  width: 112px;
}
.mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item .mdc-top-app-bar__section--align-end {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 12px;
}
[dir=rtl] .mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item .mdc-top-app-bar__section--align-end, .mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item .mdc-top-app-bar__section--align-end[dir=rtl] {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 0;
}

.mdc-top-app-bar--dense .mdc-top-app-bar__row {
  height: 48px;
}
.mdc-top-app-bar--dense .mdc-top-app-bar__section {
  padding: 0 4px;
}
.mdc-top-app-bar--dense .mdc-top-app-bar__title {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 0;
}
[dir=rtl] .mdc-top-app-bar--dense .mdc-top-app-bar__title, .mdc-top-app-bar--dense .mdc-top-app-bar__title[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 12px;
}

.mdc-top-app-bar--prominent .mdc-top-app-bar__row {
  height: 128px;
}
.mdc-top-app-bar--prominent .mdc-top-app-bar__title {
  align-self: flex-end;
  padding-bottom: 2px;
}
.mdc-top-app-bar--prominent .mdc-top-app-bar__action-item,
.mdc-top-app-bar--prominent .mdc-top-app-bar__navigation-icon {
  align-self: flex-start;
}

.mdc-top-app-bar--fixed {
  transition: box-shadow 200ms linear;
}

.mdc-top-app-bar--fixed-scrolled {
  /* @alternate */
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 200ms linear;
}

.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__row {
  height: 96px;
}
.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__section {
  padding: 0 12px;
}
.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__title {
  /* @noflip */
  padding-left: 20px;
  /* @noflip */
  padding-right: 0;
  padding-bottom: 9px;
}
[dir=rtl] .mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__title, .mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__title[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 20px;
}

.mdc-top-app-bar--fixed-adjust {
  padding-top: 64px;
}

.mdc-top-app-bar--dense-fixed-adjust {
  padding-top: 48px;
}

.mdc-top-app-bar--short-fixed-adjust {
  padding-top: 56px;
}

.mdc-top-app-bar--prominent-fixed-adjust {
  padding-top: 128px;
}

.mdc-top-app-bar--dense-prominent-fixed-adjust {
  padding-top: 96px;
}

@media (max-width: 599px) {
  .mdc-top-app-bar__row {
    height: 56px;
  }

  .mdc-top-app-bar__section {
    padding: 4px;
  }

  .mdc-top-app-bar--short {
    transition: width 200ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .mdc-top-app-bar--short-collapsed {
    transition: width 250ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__section--align-end {
    /* @noflip */
    padding-left: 0;
    /* @noflip */
    padding-right: 12px;
  }
  [dir=rtl] .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__section--align-end, .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__section--align-end[dir=rtl] {
    /* @noflip */
    padding-left: 12px;
    /* @noflip */
    padding-right: 0;
  }

  .mdc-top-app-bar--prominent .mdc-top-app-bar__title {
    padding-bottom: 6px;
  }

  .mdc-top-app-bar--fixed-adjust {
    padding-top: 56px;
  }
}
.mdc-icon-button {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  border: none;
  outline: none;
  background-color: transparent;
  fill: currentColor;
  color: inherit;
  font-size: 24px;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  width: 48px;
  height: 48px;
  padding: 12px;
}
.mdc-icon-button svg,
.mdc-icon-button img {
  width: 24px;
  height: 24px;
}
.mdc-icon-button:disabled {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38));
}
.mdc-icon-button:disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-icon-button__icon {
  display: inline-block;
}
.mdc-icon-button__icon.mdc-icon-button__icon--on {
  display: none;
}

.mdc-icon-button--on .mdc-icon-button__icon {
  display: none;
}
.mdc-icon-button--on .mdc-icon-button__icon.mdc-icon-button__icon--on {
  display: inline-block;
}

.mdc-icon-button {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-icon-button::before, .mdc-icon-button::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-icon-button::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-icon-button::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-icon-button.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-icon-button.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-icon-button.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-icon-button.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-icon-button.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-icon-button::before, .mdc-icon-button::after {
  top: calc(50% - 50%);
  /* @noflip */
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}
.mdc-icon-button.mdc-ripple-upgraded::before, .mdc-icon-button.mdc-ripple-upgraded::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-icon-button.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-icon-button::before, .mdc-icon-button::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
.mdc-icon-button:hover::before, .mdc-icon-button.mdc-ripple-surface--hover::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-icon-button.mdc-ripple-upgraded--background-focused::before, .mdc-icon-button:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-icon-button:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-icon-button:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-icon-button.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-top-app-bar {
  z-index: 7;
}
.mdc-top-app-bar .header-text {
  color: #F7F7F7;
  font-weight: 300;
}

.mdc-drawer {
  border-color: rgba(0, 0, 0, 0.12);
  background-color: #fff;
  /* @noflip */
  border-top-left-radius: 0;
  /* @noflip */
  border-top-right-radius: 0;
  /* @alternate */
  /* @noflip */
  border-top-right-radius: var(--mdc-shape-large, 0);
  /* @noflip */
  border-bottom-right-radius: 0;
  /* @alternate */
  /* @noflip */
  border-bottom-right-radius: var(--mdc-shape-large, 0);
  /* @noflip */
  border-bottom-left-radius: 0;
  z-index: 6;
  width: 256px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  box-sizing: border-box;
  height: 100%;
  /* @noflip */
  border-right-width: 1px;
  /* @noflip */
  border-right-style: solid;
  overflow: hidden;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.mdc-drawer .mdc-drawer__title {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-drawer .mdc-list-group__subheader {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-drawer .mdc-drawer__subtitle {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-drawer .mdc-list-item__graphic {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-drawer .mdc-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-drawer .mdc-list-item--activated .mdc-list-item__graphic {
  color: #0077ff;
}
.mdc-drawer .mdc-list-item--activated {
  color: rgba(0, 119, 255, 0.87);
}
[dir=rtl] .mdc-drawer, .mdc-drawer[dir=rtl] {
  /* @noflip */
  border-top-left-radius: 0;
  /* @alternate */
  /* @noflip */
  border-top-left-radius: var(--mdc-shape-large, 0);
  /* @noflip */
  border-top-right-radius: 0;
  /* @noflip */
  border-bottom-right-radius: 0;
  /* @noflip */
  border-bottom-left-radius: 0;
  /* @alternate */
  /* @noflip */
  border-bottom-left-radius: var(--mdc-shape-large, 0);
}
.mdc-drawer .mdc-list-item {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-small, 4px);
}
.mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mdc-drawer-app-content {
  /* @noflip */
  margin-left: 256px;
  /* @noflip */
  margin-right: 0;
}
[dir=rtl] .mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mdc-drawer-app-content, .mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mdc-drawer-app-content[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 256px;
}
[dir=rtl] .mdc-drawer, .mdc-drawer[dir=rtl] {
  /* @noflip */
  border-right-width: 0;
  /* @noflip */
  border-left-width: 1px;
  /* @noflip */
  border-right-style: none;
  /* @noflip */
  border-left-style: solid;
}
.mdc-drawer .mdc-list-item {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle2-font-size, 0.875rem);
  line-height: 1.375rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle2-line-height, 1.375rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
  letter-spacing: 0.0071428571em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, 0.0071428571em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle2-text-transform, inherit);
  height: calc(48px - 2 * 4px);
  margin: 8px 8px;
  padding: 0 8px;
}
.mdc-drawer .mdc-list-item:nth-child(1) {
  margin-top: 2px;
}
.mdc-drawer .mdc-list-item:nth-last-child(1) {
  margin-bottom: 0;
}
.mdc-drawer .mdc-list-group__subheader {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin: 0;
  padding: 0 16px;
}
.mdc-drawer .mdc-list-group__subheader::before {
  display: inline-block;
  width: 0;
  height: 24px;
  content: "";
  vertical-align: 0;
}
.mdc-drawer .mdc-list-divider {
  margin: 3px 0 4px;
}
.mdc-drawer .mdc-list-item__text,
.mdc-drawer .mdc-list-item__graphic {
  pointer-events: none;
}

.mdc-drawer--animate {
  transform: translateX(-100%);
}
[dir=rtl] .mdc-drawer--animate, .mdc-drawer--animate[dir=rtl] {
  transform: translateX(100%);
}

.mdc-drawer--opening {
  transform: translateX(0);
  transition-duration: 250ms;
}
[dir=rtl] .mdc-drawer--opening, .mdc-drawer--opening[dir=rtl] {
  transform: translateX(0);
}

.mdc-drawer--closing {
  transform: translateX(-100%);
  transition-duration: 200ms;
}
[dir=rtl] .mdc-drawer--closing, .mdc-drawer--closing[dir=rtl] {
  transform: translateX(100%);
}

.mdc-drawer__header {
  flex-shrink: 0;
  box-sizing: border-box;
  min-height: 64px;
  padding: 0 16px 4px;
}

.mdc-drawer__title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1.25rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-headline6-font-weight, 500);
  letter-spacing: 0.0125em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, 0.0125em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-drawer__title::before {
  display: inline-block;
  width: 0;
  height: 36px;
  content: "";
  vertical-align: 0;
}
.mdc-drawer__title::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-drawer__subtitle {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: 0;
}
.mdc-drawer__subtitle::before {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: 0;
}

.mdc-drawer__content {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.mdc-drawer--dismissible {
  /* @noflip */
  left: 0;
  /* @noflip */
  right: initial;
  display: none;
  position: absolute;
}
[dir=rtl] .mdc-drawer--dismissible, .mdc-drawer--dismissible[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 0;
}
.mdc-drawer--dismissible.mdc-drawer--open {
  display: flex;
}

.mdc-drawer-app-content {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
  position: relative;
}
[dir=rtl] .mdc-drawer-app-content, .mdc-drawer-app-content[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
}

.mdc-drawer--modal {
  /* @alternate */
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  /* @noflip */
  left: 0;
  /* @noflip */
  right: initial;
  display: none;
  position: fixed;
}
.mdc-drawer--modal + .mdc-drawer-scrim {
  background-color: rgba(0, 0, 0, 0.32);
}
[dir=rtl] .mdc-drawer--modal, .mdc-drawer--modal[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 0;
}
.mdc-drawer--modal.mdc-drawer--open {
  display: flex;
}

.mdc-drawer-scrim {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.mdc-drawer--open + .mdc-drawer-scrim {
  display: block;
}
.mdc-drawer--animate + .mdc-drawer-scrim {
  opacity: 0;
}
.mdc-drawer--opening + .mdc-drawer-scrim {
  transition-duration: 250ms;
  opacity: 1;
}
.mdc-drawer--closing + .mdc-drawer-scrim {
  transition-duration: 200ms;
  opacity: 0;
}

.mdc-list {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  /* @alternate */
  line-height: 1.5rem;
  margin: 0;
  padding: 8px 0;
  list-style-type: none;
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mdc-list:focus {
  outline: none;
}

.mdc-list-item {
  height: 48px;
}

.mdc-list-item__secondary-text {
  color: rgba(0, 0, 0, 0.54);
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__graphic {
  background-color: transparent;
}

.mdc-list-item__graphic {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__meta {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-group__subheader {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-item--disabled .mdc-list-item__text {
  opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__text,
.mdc-list-item--disabled .mdc-list-item__primary-text,
.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--selected,
.mdc-list-item--activated {
  color: #0077FF;
  /* @alternate */
  color: var(--mdc-theme-primary, #0077FF);
}
.mdc-list-item--selected .mdc-list-item__graphic,
.mdc-list-item--activated .mdc-list-item__graphic {
  color: #0077FF;
  /* @alternate */
  color: var(--mdc-theme-primary, #0077FF);
}

.mdc-list--dense {
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 0.812rem;
}

.mdc-list-item {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  padding: 0;
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
  height: 48px;
}
.mdc-list-item:focus {
  outline: none;
}
.mdc-list-item:not(.mdc-list-item--selected):focus::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  content: "";
}
.mdc-list-item.mdc-list-item--selected::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 3px double transparent;
  border-radius: inherit;
  content: "";
}
[dir=rtl] .mdc-list-item, .mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
}
.mdc-list--icon-list .mdc-list-item {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
  height: 56px;
}
[dir=rtl] .mdc-list--icon-list .mdc-list-item, .mdc-list--icon-list .mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
}
.mdc-list--avatar-list .mdc-list-item {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
  height: 56px;
}
[dir=rtl] .mdc-list--avatar-list .mdc-list-item, .mdc-list--avatar-list .mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
}
.mdc-list--thumbnail-list .mdc-list-item {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
  height: 56px;
}
[dir=rtl] .mdc-list--thumbnail-list .mdc-list-item, .mdc-list--thumbnail-list .mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
}
.mdc-list--image-list .mdc-list-item {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
  height: 72px;
}
[dir=rtl] .mdc-list--image-list .mdc-list-item, .mdc-list--image-list .mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
}
.mdc-list--video-list .mdc-list-item {
  /* @noflip */
  padding-left: 0px;
  /* @noflip */
  padding-right: 16px;
  height: 72px;
}
[dir=rtl] .mdc-list--video-list .mdc-list-item, .mdc-list--video-list .mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 0px;
}

.mdc-list--dense .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
  width: 20px;
  height: 20px;
}
[dir=rtl] .mdc-list--dense .mdc-list-item__graphic, .mdc-list--dense .mdc-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
}

.mdc-list-item__graphic {
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  fill: currentColor;
  object-fit: cover;
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 32px;
  width: 24px;
  height: 24px;
}
[dir=rtl] .mdc-list-item__graphic, .mdc-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 32px;
  /* @noflip */
  margin-right: 0;
}
.mdc-list--icon-list .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 32px;
  width: 24px;
  height: 24px;
}
[dir=rtl] .mdc-list--icon-list .mdc-list-item__graphic, .mdc-list--icon-list .mdc-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 32px;
  /* @noflip */
  margin-right: 0;
}
.mdc-list--avatar-list .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
[dir=rtl] .mdc-list--avatar-list .mdc-list-item__graphic, .mdc-list--avatar-list .mdc-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
}
.mdc-list--thumbnail-list .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
  width: 40px;
  height: 40px;
}
[dir=rtl] .mdc-list--thumbnail-list .mdc-list-item__graphic, .mdc-list--thumbnail-list .mdc-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
}
.mdc-list--image-list .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
  width: 56px;
  height: 56px;
}
[dir=rtl] .mdc-list--image-list .mdc-list-item__graphic, .mdc-list--image-list .mdc-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
}
.mdc-list--video-list .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
  width: 100px;
  height: 56px;
}
[dir=rtl] .mdc-list--video-list .mdc-list-item__graphic, .mdc-list--video-list .mdc-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
}

.mdc-list .mdc-list-item__graphic {
  display: inline-flex;
}

.mdc-list-item__meta {
  /* @noflip */
  margin-left: auto;
  /* @noflip */
  margin-right: 0;
}
.mdc-list-item__meta:not(.material-icons) {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
}
.mdc-list-item[dir=rtl] .mdc-list-item__meta, [dir=rtl] .mdc-list-item .mdc-list-item__meta {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: auto;
}

.mdc-list-item__text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mdc-list-item__text[for] {
  pointer-events: none;
}

.mdc-list-item__primary-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list--video-list .mdc-list-item__primary-text, .mdc-list--image-list .mdc-list-item__primary-text, .mdc-list--thumbnail-list .mdc-list-item__primary-text, .mdc-list--avatar-list .mdc-list-item__primary-text, .mdc-list--icon-list .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list--video-list .mdc-list-item__primary-text::before, .mdc-list--image-list .mdc-list-item__primary-text::before, .mdc-list--thumbnail-list .mdc-list-item__primary-text::before, .mdc-list--avatar-list .mdc-list-item__primary-text::before, .mdc-list--icon-list .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list--video-list .mdc-list-item__primary-text::after, .mdc-list--image-list .mdc-list-item__primary-text::after, .mdc-list--thumbnail-list .mdc-list-item__primary-text::after, .mdc-list--avatar-list .mdc-list-item__primary-text::after, .mdc-list--icon-list .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list--dense .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list--dense .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 24px;
  content: "";
  vertical-align: 0;
}
.mdc-list--dense .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item__secondary-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-list-item__secondary-text::before {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: 0;
}
.mdc-list--dense .mdc-list-item__secondary-text {
  font-size: inherit;
}

.mdc-list--dense .mdc-list-item {
  height: 40px;
}

.mdc-list--two-line .mdc-list-item__text {
  align-self: flex-start;
}

.mdc-list--two-line .mdc-list-item {
  height: 64px;
}
.mdc-list--two-line.mdc-list--video-list .mdc-list-item, .mdc-list--two-line.mdc-list--image-list .mdc-list-item, .mdc-list--two-line.mdc-list--thumbnail-list .mdc-list-item, .mdc-list--two-line.mdc-list--avatar-list .mdc-list-item, .mdc-list--two-line.mdc-list--icon-list .mdc-list-item {
  height: 72px;
}
.mdc-list--two-line.mdc-list--icon-list .mdc-list-item__graphic {
  align-self: flex-start;
  margin-top: 16px;
}

.mdc-list--two-line.mdc-list--dense .mdc-list-item,
.mdc-list--avatar-list.mdc-list--dense .mdc-list-item {
  height: 60px;
}

.mdc-list--avatar-list.mdc-list--dense .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
  width: 36px;
  height: 36px;
}
[dir=rtl] .mdc-list--avatar-list.mdc-list--dense .mdc-list-item__graphic, .mdc-list--avatar-list.mdc-list--dense .mdc-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
}

:not(.mdc-list-item--disabled).mdc-list-item {
  cursor: pointer;
}

a.mdc-list-item {
  color: inherit;
  text-decoration: none;
}

.mdc-list-divider {
  height: 0;
  margin: 0;
  border: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.mdc-list-divider {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mdc-list-divider--padded {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-list-divider--padded, .mdc-list-divider--padded[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}

.mdc-list-divider--inset {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 72px);
}
[dir=rtl] .mdc-list-divider--inset, .mdc-list-divider--inset[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}

.mdc-list-divider--inset.mdc-list-divider--padded {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 88px);
}
[dir=rtl] .mdc-list-divider--inset.mdc-list-divider--padded, .mdc-list-divider--inset.mdc-list-divider--padded[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}

.mdc-list .mdc-list-divider--inset-leading {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-list .mdc-list-divider--inset-leading, .mdc-list .mdc-list-divider--inset-leading[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}
.mdc-list .mdc-list-divider--inset-trailing {
  width: calc(100% - 16px);
}
.mdc-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing, .mdc-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}
.mdc-list .mdc-list-divider--inset-leading.mdc-list-divider--padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-list .mdc-list-divider--inset-leading.mdc-list-divider--padding, .mdc-list .mdc-list-divider--inset-leading.mdc-list-divider--padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}
.mdc-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding, .mdc-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}

.mdc-list--icon-list .mdc-list-divider--inset-leading {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 72px);
}
[dir=rtl] .mdc-list--icon-list .mdc-list-divider--inset-leading, .mdc-list--icon-list .mdc-list-divider--inset-leading[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}
.mdc-list--icon-list .mdc-list-divider--inset-trailing {
  width: calc(100% - 16px);
}
.mdc-list--icon-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 88px);
}
[dir=rtl] .mdc-list--icon-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing, .mdc-list--icon-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}
.mdc-list--icon-list .mdc-list-divider--inset-leading.mdc-list-divider--padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-list--icon-list .mdc-list-divider--inset-leading.mdc-list-divider--padding, .mdc-list--icon-list .mdc-list-divider--inset-leading.mdc-list-divider--padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}
.mdc-list--icon-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-list--icon-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding, .mdc-list--icon-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}

.mdc-list--avatar-list .mdc-list-divider--inset-leading {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 72px);
}
[dir=rtl] .mdc-list--avatar-list .mdc-list-divider--inset-leading, .mdc-list--avatar-list .mdc-list-divider--inset-leading[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}
.mdc-list--avatar-list .mdc-list-divider--inset-trailing {
  width: calc(100% - 16px);
}
.mdc-list--avatar-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 88px);
}
[dir=rtl] .mdc-list--avatar-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing, .mdc-list--avatar-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}
.mdc-list--avatar-list .mdc-list-divider--inset-leading.mdc-list-divider--padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-list--avatar-list .mdc-list-divider--inset-leading.mdc-list-divider--padding, .mdc-list--avatar-list .mdc-list-divider--inset-leading.mdc-list-divider--padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}
.mdc-list--avatar-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-list--avatar-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding, .mdc-list--avatar-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}

.mdc-list--thumbnail-list .mdc-list-divider--inset-leading {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 72px);
}
[dir=rtl] .mdc-list--thumbnail-list .mdc-list-divider--inset-leading, .mdc-list--thumbnail-list .mdc-list-divider--inset-leading[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}
.mdc-list--thumbnail-list .mdc-list-divider--inset-trailing {
  width: calc(100% - 16px);
}
.mdc-list--thumbnail-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 88px);
}
[dir=rtl] .mdc-list--thumbnail-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing, .mdc-list--thumbnail-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}
.mdc-list--thumbnail-list .mdc-list-divider--inset-leading.mdc-list-divider--padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-list--thumbnail-list .mdc-list-divider--inset-leading.mdc-list-divider--padding, .mdc-list--thumbnail-list .mdc-list-divider--inset-leading.mdc-list-divider--padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}
.mdc-list--thumbnail-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-list--thumbnail-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding, .mdc-list--thumbnail-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}

.mdc-list--image-list .mdc-list-divider--inset-leading {
  /* @noflip */
  margin-left: 88px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 88px);
}
[dir=rtl] .mdc-list--image-list .mdc-list-divider--inset-leading, .mdc-list--image-list .mdc-list-divider--inset-leading[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 88px;
}
.mdc-list--image-list .mdc-list-divider--inset-trailing {
  width: calc(100% - 16px);
}
.mdc-list--image-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing {
  /* @noflip */
  margin-left: 88px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 104px);
}
[dir=rtl] .mdc-list--image-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing, .mdc-list--image-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 88px;
}
.mdc-list--image-list .mdc-list-divider--inset-leading.mdc-list-divider--padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-list--image-list .mdc-list-divider--inset-leading.mdc-list-divider--padding, .mdc-list--image-list .mdc-list-divider--inset-leading.mdc-list-divider--padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}
.mdc-list--image-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-list--image-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding, .mdc-list--image-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}

.mdc-list--video-list .mdc-list-divider--inset-leading {
  /* @noflip */
  margin-left: 116px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 116px);
}
[dir=rtl] .mdc-list--video-list .mdc-list-divider--inset-leading, .mdc-list--video-list .mdc-list-divider--inset-leading[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 116px;
}
.mdc-list--video-list .mdc-list-divider--inset-trailing {
  width: calc(100% - 16px);
}
.mdc-list--video-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing {
  /* @noflip */
  margin-left: 116px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 132px);
}
[dir=rtl] .mdc-list--video-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing, .mdc-list--video-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 116px;
}
.mdc-list--video-list .mdc-list-divider--inset-leading.mdc-list-divider--padding {
  /* @noflip */
  margin-left: 0px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 0px);
}
[dir=rtl] .mdc-list--video-list .mdc-list-divider--inset-leading.mdc-list-divider--padding, .mdc-list--video-list .mdc-list-divider--inset-leading.mdc-list-divider--padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0px;
}
.mdc-list--video-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding {
  /* @noflip */
  margin-left: 0px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-list--video-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding, .mdc-list--video-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0px;
}

.mdc-list-group .mdc-list {
  padding: 0;
}

.mdc-list-group__subheader {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  margin: calc((3rem - 1.5rem) / 2) 16px;
}

:not(.mdc-list-item--disabled).mdc-list-item {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::before,
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded .mdc-list-item__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--unbounded .mdc-list-item__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--foreground-activation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--foreground-deactivation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::before,
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
:not(.mdc-list-item--disabled).mdc-list-item:hover .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
:not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}
:not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
:not(.mdc-list-item--disabled).mdc-list-item--activated .mdc-list-item__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-activated-opacity, 0.12);
}
:not(.mdc-list-item--disabled).mdc-list-item--activated .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--activated .mdc-list-item__ripple::after {
  background-color: #0077FF;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #0077FF));
}
:not(.mdc-list-item--disabled).mdc-list-item--activated:hover .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.16;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.16);
}
:not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
:not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}
:not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}
:not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.24);
}
:not(.mdc-list-item--disabled).mdc-list-item--selected .mdc-list-item__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-selected-opacity, 0.08);
}
:not(.mdc-list-item--disabled).mdc-list-item--selected .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--selected .mdc-list-item__ripple::after {
  background-color: #0077FF;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #0077FF));
}
:not(.mdc-list-item--disabled).mdc-list-item--selected:hover .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.12);
}
:not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.2);
}
:not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}
:not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.2);
}
:not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.2);
}
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-list-item--disabled {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-list-item--disabled .mdc-list-item__ripple::before,
.mdc-list-item--disabled .mdc-list-item__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-list-item--disabled .mdc-list-item__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-list-item--disabled .mdc-list-item__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-list-item--disabled.mdc-ripple-upgraded .mdc-list-item__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-list-item--disabled.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-list-item--disabled.mdc-ripple-upgraded--unbounded .mdc-list-item__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-list-item--disabled.mdc-ripple-upgraded--foreground-activation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-list-item--disabled.mdc-ripple-upgraded--foreground-deactivation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-list-item--disabled .mdc-list-item__ripple::before,
.mdc-list-item--disabled .mdc-list-item__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-list-item--disabled.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-list-item--disabled .mdc-list-item__ripple::before,
.mdc-list-item--disabled .mdc-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
.mdc-list-item--disabled.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, .mdc-list-item--disabled:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-list-item--disabled .mdc-list-item__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-drawer-app-content {
  flex: auto;
  overflow: auto;
  position: relative;
}

.mdc-drawer .mdc-list-item {
  font-family: "Catamaran", sans-serif;
}

.mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-button-font-size, 0.875rem);
  line-height: 2.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-button-line-height, 2.25rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: 0.0892857143em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-button-letter-spacing, 0.0892857143em);
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: uppercase;
  /* @alternate */
  text-transform: var(--mdc-typography-button-text-transform, uppercase);
  padding: 0 8px 0 8px;
  /* @alternate */
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-width: 64px;
  border: none;
  outline: none;
  /* @alternate */
  line-height: inherit;
  user-select: none;
  -webkit-appearance: none;
  overflow: visible;
  vertical-align: middle;
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-small, 4px);
  height: 36px;
}
.mdc-button .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */
  left: 0;
}
.mdc-button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.mdc-button:active {
  outline: none;
}
.mdc-button:hover {
  cursor: pointer;
}
.mdc-button:disabled {
  cursor: default;
  pointer-events: none;
}
.mdc-button .mdc-button__ripple {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-small, 4px);
}
.mdc-button:not(:disabled) {
  background-color: transparent;
}
.mdc-button:disabled {
  background-color: transparent;
}
.mdc-button .mdc-button__icon {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 8px;
  display: inline-block;
  width: 18px;
  height: 18px;
  font-size: 18px;
  vertical-align: top;
}
[dir=rtl] .mdc-button .mdc-button__icon, .mdc-button .mdc-button__icon[dir=rtl] {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 0;
}
.mdc-button .mdc-button__touch {
  position: absolute;
  top: 50%;
  right: 0;
  height: 48px;
  left: 0;
  transform: translateY(-50%);
}
.mdc-button:not(:disabled) {
  color: #0077FF;
  /* @alternate */
  color: var(--mdc-theme-primary, #0077FF);
}
.mdc-button:disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mdc-button__label + .mdc-button__icon {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 0;
}
[dir=rtl] .mdc-button__label + .mdc-button__icon, .mdc-button__label + .mdc-button__icon[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 8px;
}

svg.mdc-button__icon {
  fill: currentColor;
}

.mdc-button--raised .mdc-button__icon,
.mdc-button--unelevated .mdc-button__icon,
.mdc-button--outlined .mdc-button__icon {
  /* @noflip */
  margin-left: -4px;
  /* @noflip */
  margin-right: 8px;
}
[dir=rtl] .mdc-button--raised .mdc-button__icon, .mdc-button--raised .mdc-button__icon[dir=rtl],
[dir=rtl] .mdc-button--unelevated .mdc-button__icon,
.mdc-button--unelevated .mdc-button__icon[dir=rtl],
[dir=rtl] .mdc-button--outlined .mdc-button__icon,
.mdc-button--outlined .mdc-button__icon[dir=rtl] {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: -4px;
}
.mdc-button--raised .mdc-button__label + .mdc-button__icon,
.mdc-button--unelevated .mdc-button__label + .mdc-button__icon,
.mdc-button--outlined .mdc-button__label + .mdc-button__icon {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: -4px;
}
[dir=rtl] .mdc-button--raised .mdc-button__label + .mdc-button__icon, .mdc-button--raised .mdc-button__label + .mdc-button__icon[dir=rtl],
[dir=rtl] .mdc-button--unelevated .mdc-button__label + .mdc-button__icon,
.mdc-button--unelevated .mdc-button__label + .mdc-button__icon[dir=rtl],
[dir=rtl] .mdc-button--outlined .mdc-button__label + .mdc-button__icon,
.mdc-button--outlined .mdc-button__label + .mdc-button__icon[dir=rtl] {
  /* @noflip */
  margin-left: -4px;
  /* @noflip */
  margin-right: 8px;
}

.mdc-button--raised,
.mdc-button--unelevated {
  padding: 0 16px 0 16px;
}
.mdc-button--raised:not(:disabled),
.mdc-button--unelevated:not(:disabled) {
  background-color: #0077FF;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #0077FF);
}
.mdc-button--raised:not(:disabled),
.mdc-button--unelevated:not(:disabled) {
  color: #442b2d;
  /* @alternate */
  color: var(--mdc-theme-on-primary, #442b2d);
}
.mdc-button--raised:disabled,
.mdc-button--unelevated:disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mdc-button--raised:disabled,
.mdc-button--unelevated:disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mdc-button--raised {
  /* @alternate */
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}
.mdc-button--raised:hover, .mdc-button--raised:focus {
  /* @alternate */
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mdc-button--raised:active {
  /* @alternate */
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mdc-button--raised:disabled {
  /* @alternate */
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mdc-button--outlined {
  padding: 0 15px 0 15px;
  border-width: 1px;
  border-style: solid;
}
.mdc-button--outlined .mdc-button__ripple {
  top: -1px;
  left: -1px;
  border: 1px solid transparent;
}
.mdc-button--outlined .mdc-button__touch {
  left: -1px;
  width: calc(100% + 2 * 1px);
}
.mdc-button--outlined:not(:disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.mdc-button--outlined:disabled {
  border-color: rgba(0, 0, 0, 0.12);
}

.mdc-button--touch {
  margin-top: 6px;
  margin-bottom: 6px;
}

.mdc-button {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-button .mdc-button__ripple::before,
.mdc-button .mdc-button__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-button .mdc-button__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-button .mdc-button__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-button.mdc-ripple-upgraded .mdc-button__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-button.mdc-ripple-upgraded .mdc-button__ripple::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-button.mdc-ripple-upgraded--unbounded .mdc-button__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-button.mdc-ripple-upgraded--foreground-activation .mdc-button__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-button.mdc-ripple-upgraded--foreground-deactivation .mdc-button__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-button .mdc-button__ripple::before,
.mdc-button .mdc-button__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-button.mdc-ripple-upgraded .mdc-button__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-button .mdc-button__ripple::before, .mdc-button .mdc-button__ripple::after {
  background-color: #0077FF;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #0077FF));
}
.mdc-button:hover .mdc-button__ripple::before, .mdc-button.mdc-ripple-surface--hover .mdc-button__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-button.mdc-ripple-upgraded--background-focused .mdc-button__ripple::before, .mdc-button:not(.mdc-ripple-upgraded):focus .mdc-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-button:not(.mdc-ripple-upgraded) .mdc-button__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-button:not(.mdc-ripple-upgraded):active .mdc-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-button.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-button .mdc-button__ripple {
  position: absolute;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.mdc-button:not(.mdc-button--outlined) .mdc-button__ripple {
  top: 0;
  left: 0;
}

.mdc-button--raised .mdc-button__ripple::before, .mdc-button--raised .mdc-button__ripple::after,
.mdc-button--unelevated .mdc-button__ripple::before,
.mdc-button--unelevated .mdc-button__ripple::after {
  background-color: #442b2d;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-primary, #442b2d));
}
.mdc-button--raised:hover .mdc-button__ripple::before, .mdc-button--raised.mdc-ripple-surface--hover .mdc-button__ripple::before,
.mdc-button--unelevated:hover .mdc-button__ripple::before,
.mdc-button--unelevated.mdc-ripple-surface--hover .mdc-button__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-button--raised.mdc-ripple-upgraded--background-focused .mdc-button__ripple::before, .mdc-button--raised:not(.mdc-ripple-upgraded):focus .mdc-button__ripple::before,
.mdc-button--unelevated.mdc-ripple-upgraded--background-focused .mdc-button__ripple::before,
.mdc-button--unelevated:not(.mdc-ripple-upgraded):focus .mdc-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-button--raised:not(.mdc-ripple-upgraded) .mdc-button__ripple::after,
.mdc-button--unelevated:not(.mdc-ripple-upgraded) .mdc-button__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-button--raised:not(.mdc-ripple-upgraded):active .mdc-button__ripple::after,
.mdc-button--unelevated:not(.mdc-ripple-upgraded):active .mdc-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-button--raised.mdc-ripple-upgraded,
.mdc-button--unelevated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.accent-button:not(:disabled) {
  color: #F7F7F7;
}

.accent-button.main-button:not(:disabled) {
  background-color: #F7F7F7;
}
.accent-button.main-button:not(:disabled) {
  color: #0077FF;
}

.main-button:not(:disabled) {
  background-color: #0077FF;
}
.main-button:not(:disabled) {
  color: #F7F7F7;
}

.main-button-oulined:not(:disabled) {
  border-color: #0077FF;
}
.main-button-oulined:not(:disabled) {
  color: #0077FF;
}

.accent-button.main-button-oulined:not(:disabled) {
  border-color: #F7F7F7;
}
.accent-button.main-button-oulined:not(:disabled) {
  color: #F7F7F7;
}

.small-main-button-oulined {
  height: 30px;
}
.small-main-button-oulined:not(:disabled) {
  border-color: #0077FF;
}
.small-main-button-oulined:not(:disabled) {
  color: #0077FF;
}

.small-main-button-raised {
  height: 30px;
}
.small-main-button-raised:not(:disabled) {
  background-color: #0077FF;
}
.small-main-button-raised:not(:disabled) {
  color: #F7F7F7;
}

button.delete {
  background-color: transparent;
  border: none;
}
button.delete svg {
  width: 25px;
  height: 25px;
  fill: #3e3e3e;
}

.mdc-button.small {
  height: 30px;
}

.text-actions button {
  display: block;
  margin: 0rem auto;
}

.side-actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
}

.actions.min-width-button button.mdc-button--raised, .actions.min-width-button button.mdc-button--outlined {
  min-width: 180px;
}

.accent-text-button:not(:disabled),
.accent-outlined-button:not(:disabled) {
  border-color: #F7F7F7;
}
.accent-text-button:not(:disabled),
.accent-outlined-button:not(:disabled) {
  color: #F7F7F7;
}

.enlarge-button {
  width: 170px;
  display: block;
  margin: 0.5rem auto;
}

div.category-setup {
  background-color: #F7F7F7;
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-areas: "name delete";
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  align-items: center;
  height: 46px;
  padding: 0.5rem;
  box-sizing: border-box;
  margin-bottom: 0.5rem;
}
div.category-setup .name {
  width: 100%;
  grid-area: name;
  padding-left: 0.5rem;
  font-size: 1.125rem;
  box-sizing: border-box;
}
div.category-setup .name label {
  width: 100%;
}
div.category-setup button.delete {
  background-color: transparent;
  border: none;
  grid-area: delete;
}
div.category-setup button.delete svg {
  width: 25px;
  height: 25px;
  fill: #7c7b7b;
}
div.category-setup button.confirm svg {
  width: 25px;
  height: 25px;
  fill: #2E9599;
  -webkit-filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.12));
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.12));
}

div.category-setup.add {
  background-color: #F7F7F7;
}
div.category-setup.add .action-button {
  grid-column-start: name;
  grid-column-end: delete;
}

div.dategrouping[data-drawer-showing=true] .header svg {
  -webkit-transform: rotate(90deg);
  /* to support Safari and Android browser */
  -ms-transform: rotate(90deg);
  /* to support IE 9 */
  transform: rotate(90deg);
}

div.category {
  padding: 0.5rem;
  border-radius: 4px;
  background-color: #F7F7F7;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin-bottom: 0.5rem;
}
div.category div.header {
  display: grid;
  grid-template-columns: 3fr 5fr;
  border-bottom: 1px solid;
}
div.category div.header span.total {
  text-align: right;
}
div.category div.header.with-icon {
  display: grid;
  grid-template-columns: 20px 3fr 5fr;
  border-bottom: none;
  padding-top: 10px;
  font-size: 14px;
}
div.category div.header.with-icon span.total {
  text-align: right;
}
div.category div.header.with-icon svg {
  width: 20px;
  height: 20px;
  transition: 0.3s all;
  fill: #0077FF;
}
div.category div.content.with-icon {
  padding-left: 20px;
}
div.category div.content {
  transition: max-height 0.2s;
  overflow: hidden;
}
div.category div.content .hash-and-users {
  display: grid;
  grid-template-columns: 1fr 1.61fr;
}
div.category div.content .hash-and-users span {
  font-size: 12px;
  font-weight: bold;
  color: #787FF6;
}
div.category div.content .hash-and-users .usertag {
  padding: 0.125rem;
  border-radius: 4px;
  margin-right: 0.25rem;
  color: #F7F7F7;
}
div.category div.content .hash-and-users .usertag.user-color-0 {
  background: #787FF6;
}
div.category div.content .hash-and-users .usertag.user-color-1 {
  background: #2E9599;
}
div.category div.content .hash-and-users .usertag.user-color-2 {
  background: #FE7235;
}
div.category div.content .hash-and-users .usertag.user-color-4 {
  background: #FEA735;
}
div.category div.content[aria-hidden=true] {
  max-height: 0;
}
div.category .content[aria-hidden=false] {
  max-height: var(--containerHeight, 1000px);
}
div.category div.detail-invoice {
  display: grid;
  grid-template-areas: "amountbill business total" "progress progress progress";
  grid-template-columns: 20px 5fr 3fr;
  margin: 0.5rem 0rem;
  font-weight: 400;
  font-size: small;
}
div.category div.detail-invoice span.amount-bill {
  background-color: #787FF6;
  color: #F7F7F7;
  border-radius: 50%;
  height: 18px;
  width: 18px;
  text-align: center;
  font-weight: 600;
  font-size: 11px;
  grid-area: amountbill;
  line-height: 1.25rem;
}
div.category div.detail-invoice span.business {
  grid-area: business;
  line-height: 1.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 0.125rem;
}
div.category div.detail-invoice span.total {
  grid-area: total;
  text-align: right;
  line-height: 1.25rem;
}
div.category div.detail-invoice div.progress {
  grid-area: progress;
  display: flex;
  margin-top: 0.25rem;
  height: 2px;
}
div.category div.detail-invoice div.progress div.segment {
  height: 2px;
}
div.category div.detail-invoice div.progress div.segment.user-color-0 {
  background: #787FF6;
}
div.category div.detail-invoice div.progress div.segment.user-color-1 {
  background: #2E9599;
}
div.category div.detail-invoice div.progress div.segment.user-color-2 {
  background: #FE7235;
}
div.category div.detail-invoice div.progress div.segment.user-color-4 {
  background: #FEA735;
}
div.category-header {
  box-sizing: border-box;
  padding: 1rem 16px;
  background-color: #0077FF;
  color: #F7F7F7;
  display: grid;
  grid-template-areas: "subtitle subtitle subtitle menu" "header header header menu" "budget budget budget menu";
  grid-template-columns: 1fr 1fr 1fr 25px;
}
div.category-header span.subheader {
  grid-area: subtitle;
  font-size: 12px;
  margin: 0.25rem 0rem;
}
div.category-header h3 {
  margin: 0rem;
  grid-area: header;
  align-self: end;
}
div.category-header .kebab-menu {
  grid-area: menu;
  align-self: center;
}
div.category-header .budget {
  grid-area: budget;
  padding-top: 0.25rem;
  font-size: 14px;
  font-weight: bold;
}
div.category-header svg {
  fill: #F7F7F7;
}

div.category-invitation {
  display: grid;
  grid-template-columns: 1fr 40px;
  padding: 0.5rem;
  background-color: #F7F7F7;
  border-radius: 4px;
  align-items: center;
}
div.category-invitation button.delete svg {
  fill: #0077FF;
}

div.pending-category-invitation {
  padding: 0.5rem;
  background-color: #F7F7F7;
  border-radius: 4px;
  margin-bottom: 0.5rem;
}
div.pending-category-invitation button {
  margin: none;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

div.category-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}
div.category-grid div.category-item {
  display: grid;
  background-color: #F7F7F7;
  padding: 0.75rem 0.5rem;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

div.list-product {
  background-color: #F7F7F7;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin-top: 1rem;
  padding: 0rem 0.5rem;
}

div.product {
  display: grid;
  grid-template-areas: "quantity business total" "progress progress progress";
  grid-template-columns: 0.75fr 5fr 3fr;
  padding: 0.5rem 0rem;
  font-weight: 400;
  font-size: small;
}
div.product span.quantity {
  grid-area: quantity;
  background-color: #00C3FF;
  color: #F7F7F7;
  font-weight: 400;
  font-size: 11px;
  border-radius: 4px;
  text-align: center;
}
div.product span.product {
  grid-area: business;
  padding-left: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
div.product span.amount {
  grid-area: total;
  text-align: right;
}
div.product div.progress {
  grid-area: progress;
  background-color: #0077FF;
  height: 2px;
  margin-top: 0.25rem;
}

div.manual-entry-data {
  min-height: var(--height-full, 100vh);
  grid-template-rows: 1.61fr 1fr;
  display: grid;
}
div.manual-entry-data div.main-data {
  display: grid;
  grid-auto-rows: 1fr 1.61fr;
}
div.manual-entry-data div.main-data div.manual-entry-header {
  display: grid;
  grid-template-rows: var(--top-bar-height, 50px) var(--top-bar-height, 50px);
  align-items: center;
  background-color: #0077FF;
  color: #F7F7F7;
  align-self: baseline;
}
div.manual-entry-data div.main-data div.manual-entry-header .header-action {
  display: grid;
  grid-template-columns: 1.61fr 1fr;
  align-items: center;
}
div.manual-entry-data div.main-data div.manual-entry-header .header-action .header-section-name {
  font-weight: 400;
  font-size: 18px;
}
div.manual-entry-data div.main-data div.manual-entry-header .top-action {
  text-align: right;
}
div.manual-entry-data div.main-data div.entry-data {
  display: grid;
  align-items: center;
  grid-template-rows: 1.61fr 1fr;
}
div.manual-entry-data div.main-data div.entry-data div.business-card {
  margin: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background-color: #F7F7F7;
  padding: 2rem 0.5rem;
  box-sizing: border-box;
  border-radius: 4px;
}
div.manual-entry-data div.main-data div.entry-data div.business-card .data-detail {
  display: grid;
  grid-template-columns: 1.61fr 1fr;
  align-items: start;
}
div.manual-entry-data div.main-data div.entry-data div.business-card .data-detail .businessname .business-name {
  font-size: 16px;
}
div.manual-entry-data div.main-data div.entry-data div.business-card .data-detail .businessname p {
  font-weight: 300;
  font-size: 14px;
}
div.manual-entry-data div.main-data div.entry-data div.business-card .total-amount {
  font-size: 20px;
  display: block;
  text-align: right;
  color: #0077FF;
}
div.manual-entry-data div.action-section {
  display: grid;
  align-items: center;
}
div.manual-entry-data div.action-section .actions {
  display: grid;
  justify-content: center;
  grid-auto-rows: min-content;
}

div.business-header {
  display: grid;
  grid-template-areas: "businessname businessname changename" "amountpurchase avgpurchase totalgasto";
  grid-template-columns: 1fr 1fr 1fr;
  color: #3e3e3e;
  padding-bottom: 0.25rem;
  grid-gap: 0.25rem;
}
div.business-header .businessname {
  grid-area: businessname;
  padding: 0.5rem 0rem;
}
div.business-header .businessname h3 {
  margin: 0rem;
}
div.business-header .businessname p {
  margin: 0rem;
  font-size: 14px;
}
div.business-header .businessname .month-highlight {
  font-weight: 400;
}
div.business-header .changename {
  grid-area: changename;
}
div.business-header .amount-purchases {
  grid-area: amountpurchase;
}
div.business-header .average-purchase {
  grid-area: avgpurchase;
}
div.business-header .total-gasto {
  grid-area: totalgasto;
}
div.business-header .data-hint {
  color: #F7F7F7;
  margin: 0rem auto;
}

div.business-to-move {
  background-color: #F7F7F7;
  border-radius: 4px;
  padding: 0.5rem;
  margin: 0.25rem 0rem;
}
div.business-to-move p {
  margin: 0;
  font-weight: bold;
}
div.business-to-move .mdc-select {
  width: 100%;
}

.mdc-list {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  /* @alternate */
  line-height: 1.5rem;
  margin: 0;
  padding: 8px 0;
  list-style-type: none;
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mdc-list:focus {
  outline: none;
}

.mdc-list-item {
  height: 48px;
}

.mdc-list-item__secondary-text {
  color: rgba(0, 0, 0, 0.54);
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__graphic {
  background-color: transparent;
}

.mdc-list-item__graphic {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__meta {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-group__subheader {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-item--disabled .mdc-list-item__text {
  opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__text,
.mdc-list-item--disabled .mdc-list-item__primary-text,
.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--selected,
.mdc-list-item--activated {
  color: #0077FF;
  /* @alternate */
  color: var(--mdc-theme-primary, #0077FF);
}
.mdc-list-item--selected .mdc-list-item__graphic,
.mdc-list-item--activated .mdc-list-item__graphic {
  color: #0077FF;
  /* @alternate */
  color: var(--mdc-theme-primary, #0077FF);
}

.mdc-list--dense {
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 0.812rem;
}

.mdc-list-item {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  padding: 0;
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
  height: 48px;
}
.mdc-list-item:focus {
  outline: none;
}
.mdc-list-item:not(.mdc-list-item--selected):focus::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  content: "";
}
.mdc-list-item.mdc-list-item--selected::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 3px double transparent;
  border-radius: inherit;
  content: "";
}
[dir=rtl] .mdc-list-item, .mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
}
.mdc-list--icon-list .mdc-list-item {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
  height: 56px;
}
[dir=rtl] .mdc-list--icon-list .mdc-list-item, .mdc-list--icon-list .mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
}
.mdc-list--avatar-list .mdc-list-item {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
  height: 56px;
}
[dir=rtl] .mdc-list--avatar-list .mdc-list-item, .mdc-list--avatar-list .mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
}
.mdc-list--thumbnail-list .mdc-list-item {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
  height: 56px;
}
[dir=rtl] .mdc-list--thumbnail-list .mdc-list-item, .mdc-list--thumbnail-list .mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
}
.mdc-list--image-list .mdc-list-item {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
  height: 72px;
}
[dir=rtl] .mdc-list--image-list .mdc-list-item, .mdc-list--image-list .mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
}
.mdc-list--video-list .mdc-list-item {
  /* @noflip */
  padding-left: 0px;
  /* @noflip */
  padding-right: 16px;
  height: 72px;
}
[dir=rtl] .mdc-list--video-list .mdc-list-item, .mdc-list--video-list .mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 0px;
}

.mdc-list--dense .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
  width: 20px;
  height: 20px;
}
[dir=rtl] .mdc-list--dense .mdc-list-item__graphic, .mdc-list--dense .mdc-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
}

.mdc-list-item__graphic {
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  fill: currentColor;
  object-fit: cover;
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 32px;
  width: 24px;
  height: 24px;
}
[dir=rtl] .mdc-list-item__graphic, .mdc-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 32px;
  /* @noflip */
  margin-right: 0;
}
.mdc-list--icon-list .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 32px;
  width: 24px;
  height: 24px;
}
[dir=rtl] .mdc-list--icon-list .mdc-list-item__graphic, .mdc-list--icon-list .mdc-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 32px;
  /* @noflip */
  margin-right: 0;
}
.mdc-list--avatar-list .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
[dir=rtl] .mdc-list--avatar-list .mdc-list-item__graphic, .mdc-list--avatar-list .mdc-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
}
.mdc-list--thumbnail-list .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
  width: 40px;
  height: 40px;
}
[dir=rtl] .mdc-list--thumbnail-list .mdc-list-item__graphic, .mdc-list--thumbnail-list .mdc-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
}
.mdc-list--image-list .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
  width: 56px;
  height: 56px;
}
[dir=rtl] .mdc-list--image-list .mdc-list-item__graphic, .mdc-list--image-list .mdc-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
}
.mdc-list--video-list .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
  width: 100px;
  height: 56px;
}
[dir=rtl] .mdc-list--video-list .mdc-list-item__graphic, .mdc-list--video-list .mdc-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
}

.mdc-list .mdc-list-item__graphic {
  display: inline-flex;
}

.mdc-list-item__meta {
  /* @noflip */
  margin-left: auto;
  /* @noflip */
  margin-right: 0;
}
.mdc-list-item__meta:not(.material-icons) {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
}
.mdc-list-item[dir=rtl] .mdc-list-item__meta, [dir=rtl] .mdc-list-item .mdc-list-item__meta {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: auto;
}

.mdc-list-item__text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mdc-list-item__text[for] {
  pointer-events: none;
}

.mdc-list-item__primary-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list--video-list .mdc-list-item__primary-text, .mdc-list--image-list .mdc-list-item__primary-text, .mdc-list--thumbnail-list .mdc-list-item__primary-text, .mdc-list--avatar-list .mdc-list-item__primary-text, .mdc-list--icon-list .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list--video-list .mdc-list-item__primary-text::before, .mdc-list--image-list .mdc-list-item__primary-text::before, .mdc-list--thumbnail-list .mdc-list-item__primary-text::before, .mdc-list--avatar-list .mdc-list-item__primary-text::before, .mdc-list--icon-list .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list--video-list .mdc-list-item__primary-text::after, .mdc-list--image-list .mdc-list-item__primary-text::after, .mdc-list--thumbnail-list .mdc-list-item__primary-text::after, .mdc-list--avatar-list .mdc-list-item__primary-text::after, .mdc-list--icon-list .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list--dense .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list--dense .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 24px;
  content: "";
  vertical-align: 0;
}
.mdc-list--dense .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item__secondary-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-list-item__secondary-text::before {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: 0;
}
.mdc-list--dense .mdc-list-item__secondary-text {
  font-size: inherit;
}

.mdc-list--dense .mdc-list-item {
  height: 40px;
}

.mdc-list--two-line .mdc-list-item__text {
  align-self: flex-start;
}

.mdc-list--two-line .mdc-list-item {
  height: 64px;
}
.mdc-list--two-line.mdc-list--video-list .mdc-list-item, .mdc-list--two-line.mdc-list--image-list .mdc-list-item, .mdc-list--two-line.mdc-list--thumbnail-list .mdc-list-item, .mdc-list--two-line.mdc-list--avatar-list .mdc-list-item, .mdc-list--two-line.mdc-list--icon-list .mdc-list-item {
  height: 72px;
}
.mdc-list--two-line.mdc-list--icon-list .mdc-list-item__graphic {
  align-self: flex-start;
  margin-top: 16px;
}

.mdc-list--two-line.mdc-list--dense .mdc-list-item,
.mdc-list--avatar-list.mdc-list--dense .mdc-list-item {
  height: 60px;
}

.mdc-list--avatar-list.mdc-list--dense .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
  width: 36px;
  height: 36px;
}
[dir=rtl] .mdc-list--avatar-list.mdc-list--dense .mdc-list-item__graphic, .mdc-list--avatar-list.mdc-list--dense .mdc-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
}

:not(.mdc-list-item--disabled).mdc-list-item {
  cursor: pointer;
}

a.mdc-list-item {
  color: inherit;
  text-decoration: none;
}

.mdc-list-divider {
  height: 0;
  margin: 0;
  border: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.mdc-list-divider {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mdc-list-divider--padded {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-list-divider--padded, .mdc-list-divider--padded[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}

.mdc-list-divider--inset {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 72px);
}
[dir=rtl] .mdc-list-divider--inset, .mdc-list-divider--inset[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}

.mdc-list-divider--inset.mdc-list-divider--padded {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 88px);
}
[dir=rtl] .mdc-list-divider--inset.mdc-list-divider--padded, .mdc-list-divider--inset.mdc-list-divider--padded[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}

.mdc-list .mdc-list-divider--inset-leading {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-list .mdc-list-divider--inset-leading, .mdc-list .mdc-list-divider--inset-leading[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}
.mdc-list .mdc-list-divider--inset-trailing {
  width: calc(100% - 16px);
}
.mdc-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing, .mdc-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}
.mdc-list .mdc-list-divider--inset-leading.mdc-list-divider--padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-list .mdc-list-divider--inset-leading.mdc-list-divider--padding, .mdc-list .mdc-list-divider--inset-leading.mdc-list-divider--padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}
.mdc-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding, .mdc-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}

.mdc-list--icon-list .mdc-list-divider--inset-leading {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 72px);
}
[dir=rtl] .mdc-list--icon-list .mdc-list-divider--inset-leading, .mdc-list--icon-list .mdc-list-divider--inset-leading[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}
.mdc-list--icon-list .mdc-list-divider--inset-trailing {
  width: calc(100% - 16px);
}
.mdc-list--icon-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 88px);
}
[dir=rtl] .mdc-list--icon-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing, .mdc-list--icon-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}
.mdc-list--icon-list .mdc-list-divider--inset-leading.mdc-list-divider--padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-list--icon-list .mdc-list-divider--inset-leading.mdc-list-divider--padding, .mdc-list--icon-list .mdc-list-divider--inset-leading.mdc-list-divider--padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}
.mdc-list--icon-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-list--icon-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding, .mdc-list--icon-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}

.mdc-list--avatar-list .mdc-list-divider--inset-leading {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 72px);
}
[dir=rtl] .mdc-list--avatar-list .mdc-list-divider--inset-leading, .mdc-list--avatar-list .mdc-list-divider--inset-leading[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}
.mdc-list--avatar-list .mdc-list-divider--inset-trailing {
  width: calc(100% - 16px);
}
.mdc-list--avatar-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 88px);
}
[dir=rtl] .mdc-list--avatar-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing, .mdc-list--avatar-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}
.mdc-list--avatar-list .mdc-list-divider--inset-leading.mdc-list-divider--padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-list--avatar-list .mdc-list-divider--inset-leading.mdc-list-divider--padding, .mdc-list--avatar-list .mdc-list-divider--inset-leading.mdc-list-divider--padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}
.mdc-list--avatar-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-list--avatar-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding, .mdc-list--avatar-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}

.mdc-list--thumbnail-list .mdc-list-divider--inset-leading {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 72px);
}
[dir=rtl] .mdc-list--thumbnail-list .mdc-list-divider--inset-leading, .mdc-list--thumbnail-list .mdc-list-divider--inset-leading[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}
.mdc-list--thumbnail-list .mdc-list-divider--inset-trailing {
  width: calc(100% - 16px);
}
.mdc-list--thumbnail-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 88px);
}
[dir=rtl] .mdc-list--thumbnail-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing, .mdc-list--thumbnail-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}
.mdc-list--thumbnail-list .mdc-list-divider--inset-leading.mdc-list-divider--padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-list--thumbnail-list .mdc-list-divider--inset-leading.mdc-list-divider--padding, .mdc-list--thumbnail-list .mdc-list-divider--inset-leading.mdc-list-divider--padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}
.mdc-list--thumbnail-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-list--thumbnail-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding, .mdc-list--thumbnail-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}

.mdc-list--image-list .mdc-list-divider--inset-leading {
  /* @noflip */
  margin-left: 88px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 88px);
}
[dir=rtl] .mdc-list--image-list .mdc-list-divider--inset-leading, .mdc-list--image-list .mdc-list-divider--inset-leading[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 88px;
}
.mdc-list--image-list .mdc-list-divider--inset-trailing {
  width: calc(100% - 16px);
}
.mdc-list--image-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing {
  /* @noflip */
  margin-left: 88px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 104px);
}
[dir=rtl] .mdc-list--image-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing, .mdc-list--image-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 88px;
}
.mdc-list--image-list .mdc-list-divider--inset-leading.mdc-list-divider--padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-list--image-list .mdc-list-divider--inset-leading.mdc-list-divider--padding, .mdc-list--image-list .mdc-list-divider--inset-leading.mdc-list-divider--padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}
.mdc-list--image-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-list--image-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding, .mdc-list--image-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}

.mdc-list--video-list .mdc-list-divider--inset-leading {
  /* @noflip */
  margin-left: 116px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 116px);
}
[dir=rtl] .mdc-list--video-list .mdc-list-divider--inset-leading, .mdc-list--video-list .mdc-list-divider--inset-leading[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 116px;
}
.mdc-list--video-list .mdc-list-divider--inset-trailing {
  width: calc(100% - 16px);
}
.mdc-list--video-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing {
  /* @noflip */
  margin-left: 116px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 132px);
}
[dir=rtl] .mdc-list--video-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing, .mdc-list--video-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 116px;
}
.mdc-list--video-list .mdc-list-divider--inset-leading.mdc-list-divider--padding {
  /* @noflip */
  margin-left: 0px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 0px);
}
[dir=rtl] .mdc-list--video-list .mdc-list-divider--inset-leading.mdc-list-divider--padding, .mdc-list--video-list .mdc-list-divider--inset-leading.mdc-list-divider--padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0px;
}
.mdc-list--video-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding {
  /* @noflip */
  margin-left: 0px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-list--video-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding, .mdc-list--video-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0px;
}

.mdc-list-group .mdc-list {
  padding: 0;
}

.mdc-list-group__subheader {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  margin: calc((3rem - 1.5rem) / 2) 16px;
}

:not(.mdc-list-item--disabled).mdc-list-item {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::before,
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded .mdc-list-item__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--unbounded .mdc-list-item__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--foreground-activation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--foreground-deactivation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::before,
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
:not(.mdc-list-item--disabled).mdc-list-item:hover .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
:not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}
:not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
:not(.mdc-list-item--disabled).mdc-list-item--activated .mdc-list-item__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-activated-opacity, 0.12);
}
:not(.mdc-list-item--disabled).mdc-list-item--activated .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--activated .mdc-list-item__ripple::after {
  background-color: #0077FF;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #0077FF));
}
:not(.mdc-list-item--disabled).mdc-list-item--activated:hover .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.16;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.16);
}
:not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
:not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}
:not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}
:not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.24);
}
:not(.mdc-list-item--disabled).mdc-list-item--selected .mdc-list-item__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-selected-opacity, 0.08);
}
:not(.mdc-list-item--disabled).mdc-list-item--selected .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--selected .mdc-list-item__ripple::after {
  background-color: #0077FF;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #0077FF));
}
:not(.mdc-list-item--disabled).mdc-list-item--selected:hover .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.12);
}
:not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.2);
}
:not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}
:not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.2);
}
:not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.2);
}
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-list-item--disabled {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-list-item--disabled .mdc-list-item__ripple::before,
.mdc-list-item--disabled .mdc-list-item__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-list-item--disabled .mdc-list-item__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-list-item--disabled .mdc-list-item__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-list-item--disabled.mdc-ripple-upgraded .mdc-list-item__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-list-item--disabled.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-list-item--disabled.mdc-ripple-upgraded--unbounded .mdc-list-item__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-list-item--disabled.mdc-ripple-upgraded--foreground-activation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-list-item--disabled.mdc-ripple-upgraded--foreground-deactivation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-list-item--disabled .mdc-list-item__ripple::before,
.mdc-list-item--disabled .mdc-list-item__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-list-item--disabled.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-list-item--disabled .mdc-list-item__ripple::before,
.mdc-list-item--disabled .mdc-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
.mdc-list-item--disabled.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, .mdc-list-item--disabled:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-list-item--disabled .mdc-list-item__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-menu-surface {
  display: none;
  position: absolute;
  box-sizing: border-box;
  max-width: calc(100vw - 32px);
  max-height: calc(100vh - 32px);
  margin: 0;
  padding: 0;
  transform: scale(1);
  transform-origin: top left;
  opacity: 0;
  overflow: auto;
  will-change: transform, opacity;
  z-index: 8;
  transition: opacity 0.03s linear, transform 0.12s cubic-bezier(0, 0, 0.2, 1);
  /* @alternate */
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-medium, 4px);
  /* @noflip */
  transform-origin-left: top left;
  /* @noflip */
  transform-origin-right: top right;
}
.mdc-menu-surface:focus {
  outline: none;
}
.mdc-menu-surface--open {
  display: inline-block;
  transform: scale(1);
  opacity: 1;
}
.mdc-menu-surface--animating-open {
  display: inline-block;
  transform: scale(0.8);
  opacity: 0;
}
.mdc-menu-surface--animating-closed {
  display: inline-block;
  opacity: 0;
  transition: opacity 0.075s linear;
}
[dir=rtl] .mdc-menu-surface, .mdc-menu-surface[dir=rtl] {
  /* @noflip */
  transform-origin-left: top right;
  /* @noflip */
  transform-origin-right: top left;
}

.mdc-menu-surface--anchor {
  position: relative;
  overflow: visible;
}

.mdc-menu-surface--fixed {
  position: fixed;
}

.mdc-menu-surface--fullwidth {
  width: 100%;
}

.mdc-menu {
  min-width: 112px;
}
.mdc-menu .mdc-list-item__meta {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-menu .mdc-list-item__graphic {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-menu .mdc-list {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  position: relative;
}
.mdc-menu .mdc-list .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */
  left: 0;
}
.mdc-menu .mdc-list-divider {
  margin: 8px 0;
}
.mdc-menu .mdc-list-item {
  user-select: none;
}
.mdc-menu .mdc-list-item--disabled {
  cursor: auto;
}
.mdc-menu a.mdc-list-item .mdc-list-item__text,
.mdc-menu a.mdc-list-item .mdc-list-item__graphic {
  pointer-events: none;
}

.mdc-menu__selection-group {
  padding: 0;
  fill: currentColor;
}
.mdc-menu__selection-group .mdc-list-item {
  /* @noflip */
  padding-left: 56px;
  /* @noflip */
  padding-right: 16px;
}
[dir=rtl] .mdc-menu__selection-group .mdc-list-item, .mdc-menu__selection-group .mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 56px;
}
.mdc-menu__selection-group .mdc-menu__selection-group-icon {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: initial;
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
[dir=rtl] .mdc-menu__selection-group .mdc-menu__selection-group-icon, .mdc-menu__selection-group .mdc-menu__selection-group-icon[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 16px;
}

.mdc-menu-item--selected .mdc-menu__selection-group-icon {
  display: inline;
}

.mdc-select-helper-text {
  margin: 0;
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
[dir=rtl] .mdc-select-helper-text, .mdc-select-helper-text[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 16px;
}
.mdc-select-helper-text::before {
  display: inline-block;
  width: 0;
  height: 16px;
  content: "";
  vertical-align: 0;
}

.mdc-select-helper-text--validation-msg {
  opacity: 0;
  transition: opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-select--invalid + .mdc-select-helper-text--validation-msg,
.mdc-select-helper-text--validation-msg-persistent {
  opacity: 1;
}

.mdc-select--with-leading-icon .mdc-select__icon {
  display: inline-block;
  box-sizing: border-box;
  border: none;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  flex-shrink: 0;
  align-self: center;
  background-color: transparent;
  fill: currentColor;
}
.mdc-select--with-leading-icon .mdc-select__icon {
  /* @noflip */
  margin-left: 12px;
  /* @noflip */
  margin-right: 12px;
}
[dir=rtl] .mdc-select--with-leading-icon .mdc-select__icon, .mdc-select--with-leading-icon .mdc-select__icon[dir=rtl] {
  /* @noflip */
  margin-left: 12px;
  /* @noflip */
  margin-right: 12px;
}

.mdc-select__icon:not([tabindex]),
.mdc-select__icon[tabindex="-1"] {
  cursor: default;
  pointer-events: none;
}

.mdc-floating-label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  position: absolute;
  /* @noflip */
  left: 0;
  /* @noflip */
  transform-origin: left top;
  line-height: 1.15rem;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: text;
  overflow: hidden;
  /* @alternate */
  will-change: transform;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
[dir=rtl] .mdc-floating-label, .mdc-floating-label[dir=rtl] {
  /* @noflip */
  right: 0;
  /* @noflip */
  left: auto;
  /* @noflip */
  transform-origin: right top;
  /* @noflip */
  text-align: right;
}

.mdc-floating-label--float-above {
  cursor: auto;
}

.mdc-floating-label--required::after {
  /* @noflip */
  margin-left: 1px;
  /* @noflip */
  margin-right: 0px;
  content: "*";
}
[dir=rtl] .mdc-floating-label--required::after, .mdc-floating-label--required[dir=rtl]::after {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 1px;
}

.mdc-floating-label--float-above {
  transform: translateY(-106%) scale(0.75);
}

.mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-standard 250ms 1;
}

@keyframes mdc-floating-label-shake-float-above-standard {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-106%) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-106%) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-106%) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-106%) scale(0.75);
  }
}
.mdc-line-ripple::before, .mdc-line-ripple::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom-style: solid;
  content: "";
}
.mdc-line-ripple::before {
  border-bottom-width: 1px;
  z-index: 1;
}
.mdc-line-ripple::after {
  transform: scaleX(0);
  border-bottom-width: 2px;
  opacity: 0;
  z-index: 2;
}
.mdc-line-ripple::after {
  transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1), opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-line-ripple--active::after {
  transform: scaleX(1);
  opacity: 1;
}

.mdc-line-ripple--deactivating::after {
  opacity: 0;
}

.mdc-notched-outline {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  height: 100%;
  /* @noflip */
  text-align: left;
  pointer-events: none;
}
[dir=rtl] .mdc-notched-outline, .mdc-notched-outline[dir=rtl] {
  /* @noflip */
  text-align: right;
}
.mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
  box-sizing: border-box;
  height: 100%;
  border-top: 1px solid;
  border-bottom: 1px solid;
  pointer-events: none;
}
.mdc-notched-outline__leading {
  /* @noflip */
  border-left: 1px solid;
  /* @noflip */
  border-right: none;
  width: 12px;
}
[dir=rtl] .mdc-notched-outline__leading, .mdc-notched-outline__leading[dir=rtl] {
  /* @noflip */
  border-left: none;
  /* @noflip */
  border-right: 1px solid;
}
.mdc-notched-outline__trailing {
  /* @noflip */
  border-left: none;
  /* @noflip */
  border-right: 1px solid;
  flex-grow: 1;
}
[dir=rtl] .mdc-notched-outline__trailing, .mdc-notched-outline__trailing[dir=rtl] {
  /* @noflip */
  border-left: 1px solid;
  /* @noflip */
  border-right: none;
}
.mdc-notched-outline__notch {
  flex: 0 0 auto;
  width: auto;
  max-width: calc(100% - 12px * 2);
}
.mdc-notched-outline .mdc-floating-label {
  display: inline-block;
  position: relative;
  max-width: 100%;
}
.mdc-notched-outline .mdc-floating-label--float-above {
  text-overflow: clip;
}
.mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75);
}

.mdc-notched-outline--notched .mdc-notched-outline__notch {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 8px;
  border-top: none;
}
[dir=rtl] .mdc-notched-outline--notched .mdc-notched-outline__notch, .mdc-notched-outline--notched .mdc-notched-outline__notch[dir=rtl] {
  /* @noflip */
  padding-left: 8px;
  /* @noflip */
  padding-right: 0;
}

.mdc-notched-outline--no-label .mdc-notched-outline__notch {
  padding: 0;
}

.mdc-select__anchor {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-select__anchor .mdc-select__ripple::before,
.mdc-select__anchor .mdc-select__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-select__anchor .mdc-select__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-select__anchor .mdc-select__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-select__anchor.mdc-ripple-upgraded .mdc-select__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-select__anchor.mdc-ripple-upgraded .mdc-select__ripple::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-select__anchor.mdc-ripple-upgraded--unbounded .mdc-select__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-select__anchor.mdc-ripple-upgraded--foreground-activation .mdc-select__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-select__anchor.mdc-ripple-upgraded--foreground-deactivation .mdc-select__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-select__anchor .mdc-select__ripple::before,
.mdc-select__anchor .mdc-select__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-select__anchor.mdc-ripple-upgraded .mdc-select__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-select__anchor .mdc-select__ripple::before,
.mdc-select__anchor .mdc-select__ripple::after {
  background-color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.mdc-select__anchor:hover .mdc-select__ripple::before, .mdc-select__anchor.mdc-ripple-surface--hover .mdc-select__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-select__anchor.mdc-ripple-upgraded--background-focused .mdc-select__ripple::before, .mdc-select__anchor:not(.mdc-ripple-upgraded):focus .mdc-select__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-select__anchor .mdc-select__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-select__menu .mdc-list .mdc-list-item--selected .mdc-list-item__ripple::before, .mdc-select__menu .mdc-list .mdc-list-item--selected .mdc-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-surface, #000));
}
.mdc-select__menu .mdc-list .mdc-list-item--selected:hover .mdc-list-item__ripple::before, .mdc-select__menu .mdc-list .mdc-list-item--selected.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-select__menu .mdc-list .mdc-list-item--selected.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, .mdc-select__menu .mdc-list .mdc-list-item--selected:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-select__menu .mdc-list .mdc-list-item--selected:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-select__menu .mdc-list .mdc-list-item--selected:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-select__menu .mdc-list .mdc-list-item--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-select {
  min-width: 200px;
  display: inline-flex;
  position: relative;
}
.mdc-select:not(.mdc-select--disabled) .mdc-select__anchor {
  background-color: whitesmoke;
}
.mdc-select:not(.mdc-select--disabled) .mdc-select__selected-text {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-select:not(.mdc-select--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-select:not(.mdc-select--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.mdc-select:not(.mdc-select--disabled) .mdc-select__dropdown-icon {
  fill: rgba(0, 0, 0, 0.54);
}
.mdc-select:not(.mdc-select--disabled).mdc-select--focused .mdc-select__dropdown-icon {
  fill: #0077FF;
  /* @alternate */
  fill: var(--mdc-theme-primary, #0077FF);
}
.mdc-select:not(.mdc-select--disabled) + .mdc-select-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-select:not(.mdc-select--disabled) .mdc-select__icon {
  color: rgba(0, 0, 0, 0.54);
}
.mdc-select:not(.mdc-select--disabled).mdc-select--focused .mdc-line-ripple::after {
  border-bottom-color: #0077FF;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-primary, #0077FF);
}
.mdc-select:not(.mdc-select--disabled).mdc-select--focused .mdc-floating-label {
  color: rgba(0, 119, 255, 0.87);
}
.mdc-select:not(.mdc-select--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.mdc-select.mdc-select--disabled .mdc-select__anchor {
  background-color: #fafafa;
}
.mdc-select.mdc-select--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-select.mdc-select--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.mdc-select.mdc-select--disabled .mdc-select__dropdown-icon {
  fill: rgba(0, 0, 0, 0.38);
}
.mdc-select.mdc-select--disabled .mdc-select__selected-text {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-select.mdc-select--disabled + .mdc-select-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-select.mdc-select--disabled .mdc-select__icon {
  color: rgba(0, 0, 0, 0.38);
}
@media screen and (-ms-high-contrast: active) {
  .mdc-select.mdc-select--disabled .mdc-select__selected-text {
    color: GrayText;
  }
  .mdc-select.mdc-select--disabled .mdc-select__dropdown-icon {
    fill: red;
  }
  .mdc-select.mdc-select--disabled .mdc-floating-label {
    color: GrayText;
  }
  .mdc-select.mdc-select--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
  .mdc-select.mdc-select--disabled .mdc-notched-outline__leading,
.mdc-select.mdc-select--disabled .mdc-notched-outline__notch,
.mdc-select.mdc-select--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
  .mdc-select.mdc-select--disabled .mdc-select__icon {
    color: GrayText;
  }
  .mdc-select.mdc-select--disabled + .mdc-select-helper-text {
    color: GrayText;
  }
}
.mdc-select .mdc-floating-label {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: initial;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
[dir=rtl] .mdc-select .mdc-floating-label, .mdc-select .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 16px;
}
.mdc-select.mdc-select--filled .mdc-floating-label {
  max-width: calc(100% - 64px);
}
.mdc-select.mdc-select--filled .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75 - 64px / 0.75);
}
.mdc-select.mdc-select--outlined .mdc-floating-label {
  /* @noflip */
  left: 4px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .mdc-select.mdc-select--outlined .mdc-floating-label, .mdc-select.mdc-select--outlined .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 4px;
}
.mdc-select .mdc-select__anchor {
  height: 56px;
  display: flex;
  align-items: baseline;
}
.mdc-select .mdc-select__anchor::before {
  display: inline-block;
  width: 0;
  height: 40px;
  content: "";
  vertical-align: 0;
}
.mdc-select.mdc-select--no-label .mdc-select__anchor .mdc-select__selected-text::before {
  content: "​";
}
.mdc-select.mdc-select--no-label .mdc-select__anchor .mdc-select__selected-text {
  height: 100%;
  display: inline-flex;
  align-items: center;
}
.mdc-select.mdc-select--no-label .mdc-select__anchor::before {
  display: none;
}
.mdc-select .mdc-select__anchor {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.mdc-select .mdc-select__anchor {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 0;
}
[dir=rtl] .mdc-select .mdc-select__anchor, .mdc-select .mdc-select__anchor[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 16px;
}
.mdc-select.mdc-select--with-leading-icon .mdc-select__anchor {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 0;
}
[dir=rtl] .mdc-select.mdc-select--with-leading-icon .mdc-select__anchor, .mdc-select.mdc-select--with-leading-icon .mdc-select__anchor[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 0;
}
.mdc-select .mdc-select__icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
}
.mdc-select .mdc-select__dropdown-icon {
  width: 24px;
  height: 24px;
}
.mdc-select .mdc-select__menu .mdc-list-item {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
}
[dir=rtl] .mdc-select .mdc-select__menu .mdc-list-item, .mdc-select .mdc-select__menu .mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
}
.mdc-select .mdc-select__menu .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 12px;
}
[dir=rtl] .mdc-select .mdc-select__menu .mdc-list-item__graphic, .mdc-select .mdc-select__menu .mdc-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 12px;
  /* @noflip */
  margin-right: 0;
}
.mdc-select__dropdown-icon {
  /* @noflip */
  margin-left: 12px;
  /* @noflip */
  margin-right: 12px;
  display: inline-flex;
  position: relative;
  align-self: center;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  pointer-events: none;
}
.mdc-select__dropdown-icon .mdc-select__dropdown-icon-active,
.mdc-select__dropdown-icon .mdc-select__dropdown-icon-inactive {
  position: absolute;
  top: 0;
  left: 0;
}
.mdc-select__dropdown-icon .mdc-select__dropdown-icon-graphic {
  width: 41.6666666667%;
  height: 20.8333333333%;
}
.mdc-select__dropdown-icon .mdc-select__dropdown-icon-inactive {
  opacity: 1;
  transition: opacity 75ms linear 75ms;
}
.mdc-select__dropdown-icon .mdc-select__dropdown-icon-active {
  opacity: 0;
  transition: opacity 75ms linear;
}
[dir=rtl] .mdc-select__dropdown-icon, .mdc-select__dropdown-icon[dir=rtl] {
  /* @noflip */
  margin-left: 12px;
  /* @noflip */
  margin-right: 12px;
}
.mdc-select--activated .mdc-select__dropdown-icon .mdc-select__dropdown-icon-inactive {
  opacity: 0;
  transition: opacity 49.5ms linear;
}
.mdc-select--activated .mdc-select__dropdown-icon .mdc-select__dropdown-icon-active {
  opacity: 1;
  transition: opacity 100.5ms linear 49.5ms;
}

.mdc-select__anchor {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  outline: none;
  cursor: pointer;
}
.mdc-select__anchor .mdc-floating-label--float-above {
  transform: translateY(-106%) scale(0.75);
}
.mdc-select__anchor.mdc-select--focused.mdc-line-ripple::after {
  transform: scale(1, 2);
  opacity: 1;
}

.mdc-select__selected-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  box-sizing: border-box;
  width: auto;
  flex-grow: 1;
  height: 28px;
  border: none;
  outline: none;
  padding: 0;
  white-space: nowrap;
  appearance: none;
  pointer-events: none;
  overflow: hidden;
  background-color: transparent;
  color: inherit;
}
.mdc-select__selected-text::-ms-expand {
  display: none;
}
.mdc-select__selected-text::-ms-value {
  background-color: transparent;
  color: inherit;
}

.mdc-select--outlined {
  border: none;
}
.mdc-select--outlined:not(.mdc-select--disabled) .mdc-select__anchor {
  background-color: transparent;
}
.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}
.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__anchor:hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__anchor:hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__anchor:hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing {
  border-width: 2px;
}
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #0077FF;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #0077FF);
}
.mdc-select--outlined.mdc-select--disabled .mdc-notched-outline__leading,
.mdc-select--outlined.mdc-select--disabled .mdc-notched-outline__notch,
.mdc-select--outlined.mdc-select--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
.mdc-select--outlined.mdc-select--disabled .mdc-select__anchor {
  background-color: transparent;
}
.mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  /* @noflip */
  border-top-left-radius: 4px;
  /* @noflip */
  border-top-right-radius: 0;
  /* @noflip */
  border-bottom-right-radius: 0;
  /* @noflip */
  border-bottom-left-radius: 4px;
}
[dir=rtl] .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__leading, .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__leading[dir=rtl] {
  /* @noflip */
  border-top-left-radius: 0;
  /* @noflip */
  border-top-right-radius: 4px;
  /* @noflip */
  border-bottom-right-radius: 4px;
  /* @noflip */
  border-bottom-left-radius: 0;
}
.mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
  /* @noflip */
  border-top-left-radius: 0;
  /* @noflip */
  border-top-right-radius: 4px;
  /* @noflip */
  border-bottom-right-radius: 4px;
  /* @noflip */
  border-bottom-left-radius: 0;
}
[dir=rtl] .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__trailing, .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__trailing[dir=rtl] {
  /* @noflip */
  border-top-left-radius: 4px;
  /* @noflip */
  border-top-right-radius: 0;
  /* @noflip */
  border-bottom-right-radius: 0;
  /* @noflip */
  border-bottom-left-radius: 4px;
}
.mdc-select--outlined .mdc-select__selected-text {
  border-radius: 4px;
}
.mdc-select--outlined:not(.mdc-select--disabled) .mdc-select__anchor {
  background-color: transparent;
}
.mdc-select--outlined .mdc-select__anchor :not(.mdc-notched-outline--notched) .mdc-notched-outline__notch {
  max-width: calc(100% - 60px);
}
.mdc-select--outlined .mdc-select__anchor {
  overflow: visible;
}
.mdc-select--outlined .mdc-select__anchor .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-select-outlined 250ms 1;
}
.mdc-select--outlined .mdc-select__anchor .mdc-floating-label--float-above {
  transform: translateY(-37.25px) scale(1);
}
.mdc-select--outlined .mdc-select__anchor .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.mdc-select--outlined .mdc-select__anchor.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--outlined .mdc-select__anchor .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-34.75px) scale(0.75);
}
.mdc-select--outlined .mdc-select__anchor.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--outlined .mdc-select__anchor .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.mdc-select--outlined .mdc-select__anchor .mdc-notched-outline--notched .mdc-notched-outline__notch {
  padding-top: 1px;
}
.mdc-select--outlined .mdc-select__anchor .mdc-select__selected-text::before {
  content: "​";
}
.mdc-select--outlined .mdc-select__anchor .mdc-select__selected-text {
  height: 100%;
  display: inline-flex;
  align-items: center;
}
.mdc-select--outlined .mdc-select__anchor::before {
  display: none;
}
.mdc-select--outlined .mdc-select__selected-text {
  display: flex;
  border: none;
  z-index: 1;
  background-color: transparent;
}
.mdc-select--outlined .mdc-select__icon {
  z-index: 2;
}
.mdc-select--outlined .mdc-floating-label {
  line-height: 1.15rem;
}
.mdc-select--outlined.mdc-select--focused .mdc-notched-outline--notched .mdc-notched-outline__notch {
  padding-top: 2px;
}

.mdc-select--invalid:not(.mdc-select--disabled) .mdc-floating-label {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020);
}
.mdc-select--invalid:not(.mdc-select--disabled) .mdc-line-ripple::before {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020);
}
.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-line-ripple::after {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020);
}
.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-floating-label {
  color: #b00020;
}
.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--invalid + .mdc-select-helper-text--validation-msg {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020);
}
.mdc-select--invalid:not(.mdc-select--disabled) .mdc-select__dropdown-icon {
  fill: #b00020;
  /* @alternate */
  fill: var(--mdc-theme-error, #b00020);
}
.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-select__dropdown-icon {
  fill: #b00020;
  /* @alternate */
  fill: var(--mdc-theme-error, #b00020);
}
.mdc-select--invalid:not(.mdc-select--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020);
}
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__anchor:hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__anchor:hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__anchor:hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing {
  border-width: 2px;
}
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}

.mdc-select--disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-select--with-leading-icon .mdc-floating-label {
  /* @noflip */
  left: 48px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .mdc-select--with-leading-icon .mdc-floating-label, .mdc-select--with-leading-icon .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 48px;
}
.mdc-select--with-leading-icon .mdc-select__menu .mdc-list-item {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 12px;
}
[dir=rtl] .mdc-select--with-leading-icon .mdc-select__menu .mdc-list-item, .mdc-select--with-leading-icon .mdc-select__menu .mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 12px;
}
.mdc-select--with-leading-icon.mdc-select--filled .mdc-floating-label {
  max-width: calc(100% - 96px);
}
.mdc-select--with-leading-icon.mdc-select--filled .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75 - 96px / 0.75);
}
.mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label {
  /* @noflip */
  left: 36px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label, .mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 36px;
}
.mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label--float-above {
  transform: translateY(-37.25px) translateX(-32px) scale(1);
}
[dir=rtl] .mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label--float-above, .mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-37.25px) translateX(32px) scale(1);
}
.mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.mdc-select--with-leading-icon.mdc-select--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--with-leading-icon.mdc-select--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-34.75px) translateX(-32px) scale(0.75);
}
[dir=rtl] .mdc-select--with-leading-icon.mdc-select--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above, .mdc-select--with-leading-icon.mdc-select--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir=rtl],
[dir=rtl] .mdc-select--with-leading-icon.mdc-select--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--with-leading-icon.mdc-select--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-34.75px) translateX(32px) scale(0.75);
}
.mdc-select--with-leading-icon.mdc-select--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--with-leading-icon.mdc-select--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-select-outlined-leading-icon-56px 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-select-outlined-leading-icon-56px {
  0% {
    transform: translateX(calc(0 - 32px)) translateY(-34.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 32px)) translateY(-34.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 32px)) translateY(-34.75px) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 32px)) translateY(-34.75px) scale(0.75);
  }
}
[dir=rtl] .mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label--shake, .mdc-select--with-leading-icon.mdc-select--outlined[dir=rtl] .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-select-outlined-leading-icon-56px 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-select-outlined-leading-icon-56px-rtl {
  0% {
    transform: translateX(calc(0 - -32px)) translateY(-34.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - -32px)) translateY(-34.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - -32px)) translateY(-34.75px) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - -32px)) translateY(-34.75px) scale(0.75);
  }
}
.mdc-select--with-leading-icon.mdc-select--outlined .mdc-select__anchor :not(.mdc-notched-outline--notched) .mdc-notched-outline__notch {
  max-width: calc(100% - 96px);
}

.mdc-select--filled .mdc-menu-surface--is-open-below {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.mdc-select__menu .mdc-select__icon {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
}
[dir=rtl] .mdc-select__menu .mdc-select__icon, .mdc-select__menu .mdc-select__icon[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
}
.mdc-select__menu .mdc-list .mdc-list-item--selected,
.mdc-select__menu .mdc-list .mdc-list-item--activated {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-select__menu .mdc-list .mdc-list-item--selected .mdc-list-item__graphic,
.mdc-select__menu .mdc-list .mdc-list-item--activated .mdc-list-item__graphic {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.demo-width-class {
  min-width: auto;
  width: 100%;
}
.demo-width-class .mdc-select__anchor {
  height: 30px;
  display: flex;
  align-items: baseline;
}
.demo-width-class .mdc-select__anchor::before {
  display: inline-block;
  width: 0;
  height: 40px;
  content: "";
  vertical-align: 0;
}
.demo-width-class .mdc-select__anchor .mdc-select__selected-text::before {
  content: "​";
}
.demo-width-class .mdc-select__anchor .mdc-select__selected-text {
  height: 100%;
  display: inline-flex;
  align-items: center;
}
.demo-width-class .mdc-select__anchor::before {
  display: none;
}
.demo-width-class .mdc-select__anchor .mdc-floating-label {
  display: none;
}
.demo-width-class.mdc-select--no-label .mdc-select__anchor .mdc-select__selected-text::before {
  content: "​";
}
.demo-width-class.mdc-select--no-label .mdc-select__anchor .mdc-select__selected-text {
  height: 100%;
  display: inline-flex;
  align-items: center;
}
.demo-width-class.mdc-select--no-label .mdc-select__anchor::before {
  display: none;
}
.demo-width-class .mdc-select__dropdown-icon {
  width: 20px;
  height: 20px;
}
.demo-width-class.mdc-select--filled .mdc-floating-label {
  max-width: calc(100% - 60px);
}
.demo-width-class.mdc-select--filled .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75 - 60px / 0.75);
}
.demo-width-class .mdc-select__anchor {
  height: 30px;
  display: flex;
  align-items: baseline;
}
.demo-width-class .mdc-select__anchor::before {
  display: inline-block;
  width: 0;
  height: 40px;
  content: "";
  vertical-align: 0;
}
.demo-width-class .mdc-select__anchor .mdc-select__selected-text::before {
  content: "​";
}
.demo-width-class .mdc-select__anchor .mdc-select__selected-text {
  height: 100%;
  display: inline-flex;
  align-items: center;
}
.demo-width-class .mdc-select__anchor::before {
  display: none;
}
.demo-width-class .mdc-select__anchor .mdc-floating-label {
  display: none;
}
.demo-width-class.mdc-select--no-label .mdc-select__anchor .mdc-select__selected-text::before {
  content: "​";
}
.demo-width-class.mdc-select--no-label .mdc-select__anchor .mdc-select__selected-text {
  height: 100%;
  display: inline-flex;
  align-items: center;
}
.demo-width-class.mdc-select--no-label .mdc-select__anchor::before {
  display: none;
}
.demo-width-class .mdc-select__dropdown-icon {
  width: 20px;
  height: 20px;
}
.demo-width-class.mdc-select--filled .mdc-floating-label {
  max-width: calc(100% - 60px);
}
.demo-width-class.mdc-select--filled .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75 - 60px / 0.75);
}
.demo-width-class .mdc-select__icon {
  width: 20px;
  height: 20px;
  font-size: 20px;
}
.demo-width-class.mdc-select--filled .mdc-floating-label {
  max-width: calc(100% - 88px);
}
.demo-width-class.mdc-select--filled .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75 - 88px / 0.75);
}
.demo-width-class .mdc-list-item__graphic {
  width: 20px;
  height: 20px;
}
.demo-width-class .mdc-select__anchor .mdc-floating-label {
  /* @noflip */
  left: 44px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .demo-width-class .mdc-select__anchor .mdc-floating-label, .demo-width-class .mdc-select__anchor .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 44px;
}

.select-width-margin {
  width: 100%;
  margin: 1rem 0rem;
}

div.small-select .mdc-select__anchor {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
div.small-select .mdc-select__anchor .mdc-select__ripple::before,
div.small-select .mdc-select__anchor .mdc-select__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
div.small-select .mdc-select__anchor .mdc-select__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
div.small-select .mdc-select__anchor .mdc-select__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
div.small-select .mdc-select__anchor.mdc-ripple-upgraded .mdc-select__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
div.small-select .mdc-select__anchor.mdc-ripple-upgraded .mdc-select__ripple::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
div.small-select .mdc-select__anchor.mdc-ripple-upgraded--unbounded .mdc-select__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
div.small-select .mdc-select__anchor.mdc-ripple-upgraded--foreground-activation .mdc-select__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
div.small-select .mdc-select__anchor.mdc-ripple-upgraded--foreground-deactivation .mdc-select__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
div.small-select .mdc-select__anchor .mdc-select__ripple::before,
div.small-select .mdc-select__anchor .mdc-select__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
div.small-select .mdc-select__anchor.mdc-ripple-upgraded .mdc-select__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
div.small-select .mdc-select__anchor .mdc-select__ripple::before,
div.small-select .mdc-select__anchor .mdc-select__ripple::after {
  background-color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
div.small-select .mdc-select__anchor:hover .mdc-select__ripple::before, div.small-select .mdc-select__anchor.mdc-ripple-surface--hover .mdc-select__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
div.small-select .mdc-select__anchor.mdc-ripple-upgraded--background-focused .mdc-select__ripple::before, div.small-select .mdc-select__anchor:not(.mdc-ripple-upgraded):focus .mdc-select__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
div.small-select .mdc-select__anchor .mdc-select__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
div.small-select .mdc-select__menu .mdc-list .mdc-list-item--selected .mdc-list-item__ripple::before, div.small-select .mdc-select__menu .mdc-list .mdc-list-item--selected .mdc-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-surface, #000));
}
div.small-select .mdc-select__menu .mdc-list .mdc-list-item--selected:hover .mdc-list-item__ripple::before, div.small-select .mdc-select__menu .mdc-list .mdc-list-item--selected.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
div.small-select .mdc-select__menu .mdc-list .mdc-list-item--selected.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, div.small-select .mdc-select__menu .mdc-list .mdc-list-item--selected:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
div.small-select .mdc-select__menu .mdc-list .mdc-list-item--selected:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}
div.small-select .mdc-select__menu .mdc-list .mdc-list-item--selected:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
div.small-select .mdc-select__menu .mdc-list .mdc-list-item--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
div.small-select .mdc-select {
  min-width: 200px;
  display: inline-flex;
  position: relative;
}
div.small-select .mdc-select:not(.mdc-select--disabled) .mdc-select__anchor {
  background-color: whitesmoke;
}
div.small-select .mdc-select:not(.mdc-select--disabled) .mdc-select__selected-text {
  color: rgba(0, 0, 0, 0.87);
}
div.small-select .mdc-select:not(.mdc-select--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
div.small-select .mdc-select:not(.mdc-select--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
div.small-select .mdc-select:not(.mdc-select--disabled) .mdc-select__dropdown-icon {
  fill: rgba(0, 0, 0, 0.54);
}
div.small-select .mdc-select:not(.mdc-select--disabled).mdc-select--focused .mdc-select__dropdown-icon {
  fill: #0077FF;
  /* @alternate */
  fill: var(--mdc-theme-primary, #0077FF);
}
div.small-select .mdc-select:not(.mdc-select--disabled) + .mdc-select-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
div.small-select .mdc-select:not(.mdc-select--disabled) .mdc-select__icon {
  color: rgba(0, 0, 0, 0.54);
}
div.small-select .mdc-select:not(.mdc-select--disabled).mdc-select--focused .mdc-line-ripple::after {
  border-bottom-color: #0077FF;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-primary, #0077FF);
}
div.small-select .mdc-select:not(.mdc-select--disabled).mdc-select--focused .mdc-floating-label {
  color: rgba(0, 119, 255, 0.87);
}
div.small-select .mdc-select:not(.mdc-select--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
div.small-select .mdc-select.mdc-select--disabled .mdc-select__anchor {
  background-color: #fafafa;
}
div.small-select .mdc-select.mdc-select--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}
div.small-select .mdc-select.mdc-select--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
div.small-select .mdc-select.mdc-select--disabled .mdc-select__dropdown-icon {
  fill: rgba(0, 0, 0, 0.38);
}
div.small-select .mdc-select.mdc-select--disabled .mdc-select__selected-text {
  color: rgba(0, 0, 0, 0.38);
}
div.small-select .mdc-select.mdc-select--disabled + .mdc-select-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
div.small-select .mdc-select.mdc-select--disabled .mdc-select__icon {
  color: rgba(0, 0, 0, 0.38);
}
@media screen and (-ms-high-contrast: active) {
  div.small-select .mdc-select.mdc-select--disabled .mdc-select__selected-text {
    color: GrayText;
  }
  div.small-select .mdc-select.mdc-select--disabled .mdc-select__dropdown-icon {
    fill: red;
  }
  div.small-select .mdc-select.mdc-select--disabled .mdc-floating-label {
    color: GrayText;
  }
  div.small-select .mdc-select.mdc-select--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
  div.small-select .mdc-select.mdc-select--disabled .mdc-notched-outline__leading,
div.small-select .mdc-select.mdc-select--disabled .mdc-notched-outline__notch,
div.small-select .mdc-select.mdc-select--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
  div.small-select .mdc-select.mdc-select--disabled .mdc-select__icon {
    color: GrayText;
  }
  div.small-select .mdc-select.mdc-select--disabled + .mdc-select-helper-text {
    color: GrayText;
  }
}
div.small-select .mdc-select .mdc-floating-label {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: initial;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
[dir=rtl] div.small-select .mdc-select .mdc-floating-label, div.small-select .mdc-select .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 16px;
}
div.small-select .mdc-select.mdc-select--filled .mdc-floating-label {
  max-width: calc(100% - 64px);
}
div.small-select .mdc-select.mdc-select--filled .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75 - 64px / 0.75);
}
div.small-select .mdc-select.mdc-select--outlined .mdc-floating-label {
  /* @noflip */
  left: 4px;
  /* @noflip */
  right: initial;
}
[dir=rtl] div.small-select .mdc-select.mdc-select--outlined .mdc-floating-label, div.small-select .mdc-select.mdc-select--outlined .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 4px;
}
div.small-select .mdc-select .mdc-select__anchor {
  height: 56px;
  display: flex;
  align-items: baseline;
}
div.small-select .mdc-select .mdc-select__anchor::before {
  display: inline-block;
  width: 0;
  height: 40px;
  content: "";
  vertical-align: 0;
}
div.small-select .mdc-select.mdc-select--no-label .mdc-select__anchor .mdc-select__selected-text::before {
  content: "​";
}
div.small-select .mdc-select.mdc-select--no-label .mdc-select__anchor .mdc-select__selected-text {
  height: 100%;
  display: inline-flex;
  align-items: center;
}
div.small-select .mdc-select.mdc-select--no-label .mdc-select__anchor::before {
  display: none;
}
div.small-select .mdc-select .mdc-select__anchor {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
div.small-select .mdc-select .mdc-select__anchor {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 0;
}
[dir=rtl] div.small-select .mdc-select .mdc-select__anchor, div.small-select .mdc-select .mdc-select__anchor[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 16px;
}
div.small-select .mdc-select.mdc-select--with-leading-icon .mdc-select__anchor {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 0;
}
[dir=rtl] div.small-select .mdc-select.mdc-select--with-leading-icon .mdc-select__anchor, div.small-select .mdc-select.mdc-select--with-leading-icon .mdc-select__anchor[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 0;
}
div.small-select .mdc-select .mdc-select__icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
}
div.small-select .mdc-select .mdc-select__dropdown-icon {
  width: 24px;
  height: 24px;
}
div.small-select .mdc-select .mdc-select__menu .mdc-list-item {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
}
[dir=rtl] div.small-select .mdc-select .mdc-select__menu .mdc-list-item, div.small-select .mdc-select .mdc-select__menu .mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
}
div.small-select .mdc-select .mdc-select__menu .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 12px;
}
[dir=rtl] div.small-select .mdc-select .mdc-select__menu .mdc-list-item__graphic, div.small-select .mdc-select .mdc-select__menu .mdc-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 12px;
  /* @noflip */
  margin-right: 0;
}
div.small-select .mdc-select__dropdown-icon {
  /* @noflip */
  margin-left: 12px;
  /* @noflip */
  margin-right: 12px;
  display: inline-flex;
  position: relative;
  align-self: center;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  pointer-events: none;
}
div.small-select .mdc-select__dropdown-icon .mdc-select__dropdown-icon-active,
div.small-select .mdc-select__dropdown-icon .mdc-select__dropdown-icon-inactive {
  position: absolute;
  top: 0;
  left: 0;
}
div.small-select .mdc-select__dropdown-icon .mdc-select__dropdown-icon-graphic {
  width: 41.6666666667%;
  height: 20.8333333333%;
}
div.small-select .mdc-select__dropdown-icon .mdc-select__dropdown-icon-inactive {
  opacity: 1;
  transition: opacity 75ms linear 75ms;
}
div.small-select .mdc-select__dropdown-icon .mdc-select__dropdown-icon-active {
  opacity: 0;
  transition: opacity 75ms linear;
}
[dir=rtl] div.small-select .mdc-select__dropdown-icon, div.small-select .mdc-select__dropdown-icon[dir=rtl] {
  /* @noflip */
  margin-left: 12px;
  /* @noflip */
  margin-right: 12px;
}
.mdc-select--activated div.small-select .mdc-select__dropdown-icon .mdc-select__dropdown-icon-inactive {
  opacity: 0;
  transition: opacity 49.5ms linear;
}
.mdc-select--activated div.small-select .mdc-select__dropdown-icon .mdc-select__dropdown-icon-active {
  opacity: 1;
  transition: opacity 100.5ms linear 49.5ms;
}
div.small-select .mdc-select__anchor {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  outline: none;
  cursor: pointer;
}
div.small-select .mdc-select__anchor .mdc-floating-label--float-above {
  transform: translateY(-106%) scale(0.75);
}
div.small-select .mdc-select__anchor.mdc-select--focused.mdc-line-ripple::after {
  transform: scale(1, 2);
  opacity: 1;
}
div.small-select .mdc-select__selected-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  box-sizing: border-box;
  width: auto;
  flex-grow: 1;
  height: 28px;
  border: none;
  outline: none;
  padding: 0;
  white-space: nowrap;
  appearance: none;
  pointer-events: none;
  overflow: hidden;
  background-color: transparent;
  color: inherit;
}
div.small-select .mdc-select__selected-text::-ms-expand {
  display: none;
}
div.small-select .mdc-select__selected-text::-ms-value {
  background-color: transparent;
  color: inherit;
}
div.small-select .mdc-select--outlined {
  border: none;
}
div.small-select .mdc-select--outlined:not(.mdc-select--disabled) .mdc-select__anchor {
  background-color: transparent;
}
div.small-select .mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
div.small-select .mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
div.small-select .mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}
div.small-select .mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__anchor:hover .mdc-notched-outline .mdc-notched-outline__leading,
div.small-select .mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__anchor:hover .mdc-notched-outline .mdc-notched-outline__notch,
div.small-select .mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__anchor:hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
div.small-select .mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
div.small-select .mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
div.small-select .mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing {
  border-width: 2px;
}
div.small-select .mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
div.small-select .mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
div.small-select .mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #0077FF;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #0077FF);
}
div.small-select .mdc-select--outlined.mdc-select--disabled .mdc-notched-outline__leading,
div.small-select .mdc-select--outlined.mdc-select--disabled .mdc-notched-outline__notch,
div.small-select .mdc-select--outlined.mdc-select--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
div.small-select .mdc-select--outlined.mdc-select--disabled .mdc-select__anchor {
  background-color: transparent;
}
div.small-select .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  /* @noflip */
  border-top-left-radius: 4px;
  /* @noflip */
  border-top-right-radius: 0;
  /* @noflip */
  border-bottom-right-radius: 0;
  /* @noflip */
  border-bottom-left-radius: 4px;
}
[dir=rtl] div.small-select .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__leading, div.small-select .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__leading[dir=rtl] {
  /* @noflip */
  border-top-left-radius: 0;
  /* @noflip */
  border-top-right-radius: 4px;
  /* @noflip */
  border-bottom-right-radius: 4px;
  /* @noflip */
  border-bottom-left-radius: 0;
}
div.small-select .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
  /* @noflip */
  border-top-left-radius: 0;
  /* @noflip */
  border-top-right-radius: 4px;
  /* @noflip */
  border-bottom-right-radius: 4px;
  /* @noflip */
  border-bottom-left-radius: 0;
}
[dir=rtl] div.small-select .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__trailing, div.small-select .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__trailing[dir=rtl] {
  /* @noflip */
  border-top-left-radius: 4px;
  /* @noflip */
  border-top-right-radius: 0;
  /* @noflip */
  border-bottom-right-radius: 0;
  /* @noflip */
  border-bottom-left-radius: 4px;
}
div.small-select .mdc-select--outlined .mdc-select__selected-text {
  border-radius: 4px;
}
div.small-select .mdc-select--outlined:not(.mdc-select--disabled) .mdc-select__anchor {
  background-color: transparent;
}
div.small-select .mdc-select--outlined .mdc-select__anchor :not(.mdc-notched-outline--notched) .mdc-notched-outline__notch {
  max-width: calc(100% - 60px);
}
div.small-select .mdc-select--outlined .mdc-select__anchor {
  overflow: visible;
}
div.small-select .mdc-select--outlined .mdc-select__anchor .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-select-outlined 250ms 1;
}
div.small-select .mdc-select--outlined .mdc-select__anchor .mdc-floating-label--float-above {
  transform: translateY(-37.25px) scale(1);
}
div.small-select .mdc-select--outlined .mdc-select__anchor .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
div.small-select .mdc-select--outlined .mdc-select__anchor.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
div.small-select .mdc-select--outlined .mdc-select__anchor .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-34.75px) scale(0.75);
}
div.small-select .mdc-select--outlined .mdc-select__anchor.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
div.small-select .mdc-select--outlined .mdc-select__anchor .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
div.small-select .mdc-select--outlined .mdc-select__anchor .mdc-notched-outline--notched .mdc-notched-outline__notch {
  padding-top: 1px;
}
div.small-select .mdc-select--outlined .mdc-select__anchor .mdc-select__selected-text::before {
  content: "​";
}
div.small-select .mdc-select--outlined .mdc-select__anchor .mdc-select__selected-text {
  height: 100%;
  display: inline-flex;
  align-items: center;
}
div.small-select .mdc-select--outlined .mdc-select__anchor::before {
  display: none;
}
div.small-select .mdc-select--outlined .mdc-select__selected-text {
  display: flex;
  border: none;
  z-index: 1;
  background-color: transparent;
}
div.small-select .mdc-select--outlined .mdc-select__icon {
  z-index: 2;
}
div.small-select .mdc-select--outlined .mdc-floating-label {
  line-height: 1.15rem;
}
div.small-select .mdc-select--outlined.mdc-select--focused .mdc-notched-outline--notched .mdc-notched-outline__notch {
  padding-top: 2px;
}
div.small-select .mdc-select--invalid:not(.mdc-select--disabled) .mdc-floating-label {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020);
}
div.small-select .mdc-select--invalid:not(.mdc-select--disabled) .mdc-line-ripple::before {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020);
}
div.small-select .mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-line-ripple::after {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020);
}
div.small-select .mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-floating-label {
  color: #b00020;
}
div.small-select .mdc-select--invalid:not(.mdc-select--disabled).mdc-select--invalid + .mdc-select-helper-text--validation-msg {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020);
}
div.small-select .mdc-select--invalid:not(.mdc-select--disabled) .mdc-select__dropdown-icon {
  fill: #b00020;
  /* @alternate */
  fill: var(--mdc-theme-error, #b00020);
}
div.small-select .mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-select__dropdown-icon {
  fill: #b00020;
  /* @alternate */
  fill: var(--mdc-theme-error, #b00020);
}
div.small-select .mdc-select--invalid:not(.mdc-select--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020);
}
div.small-select .mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
div.small-select .mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
div.small-select .mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}
div.small-select .mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__anchor:hover .mdc-notched-outline .mdc-notched-outline__leading,
div.small-select .mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__anchor:hover .mdc-notched-outline .mdc-notched-outline__notch,
div.small-select .mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__anchor:hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}
div.small-select .mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
div.small-select .mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
div.small-select .mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing {
  border-width: 2px;
}
div.small-select .mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
div.small-select .mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
div.small-select .mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}
div.small-select .mdc-select--disabled {
  cursor: default;
  pointer-events: none;
}
div.small-select .mdc-select--with-leading-icon .mdc-floating-label {
  /* @noflip */
  left: 48px;
  /* @noflip */
  right: initial;
}
[dir=rtl] div.small-select .mdc-select--with-leading-icon .mdc-floating-label, div.small-select .mdc-select--with-leading-icon .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 48px;
}
div.small-select .mdc-select--with-leading-icon .mdc-select__menu .mdc-list-item {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 12px;
}
[dir=rtl] div.small-select .mdc-select--with-leading-icon .mdc-select__menu .mdc-list-item, div.small-select .mdc-select--with-leading-icon .mdc-select__menu .mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 12px;
}
div.small-select .mdc-select--with-leading-icon.mdc-select--filled .mdc-floating-label {
  max-width: calc(100% - 96px);
}
div.small-select .mdc-select--with-leading-icon.mdc-select--filled .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75 - 96px / 0.75);
}
div.small-select .mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label {
  /* @noflip */
  left: 36px;
  /* @noflip */
  right: initial;
}
[dir=rtl] div.small-select .mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label, div.small-select .mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 36px;
}
div.small-select .mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label--float-above {
  transform: translateY(-37.25px) translateX(-32px) scale(1);
}
[dir=rtl] div.small-select .mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label--float-above, div.small-select .mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-37.25px) translateX(32px) scale(1);
}
div.small-select .mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
div.small-select .mdc-select--with-leading-icon.mdc-select--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
div.small-select .mdc-select--with-leading-icon.mdc-select--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-34.75px) translateX(-32px) scale(0.75);
}
[dir=rtl] div.small-select .mdc-select--with-leading-icon.mdc-select--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above, div.small-select .mdc-select--with-leading-icon.mdc-select--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir=rtl],
[dir=rtl] div.small-select .mdc-select--with-leading-icon.mdc-select--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above,
div.small-select .mdc-select--with-leading-icon.mdc-select--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-34.75px) translateX(32px) scale(0.75);
}
div.small-select .mdc-select--with-leading-icon.mdc-select--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
div.small-select .mdc-select--with-leading-icon.mdc-select--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
div.small-select .mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-select-outlined-leading-icon-56px 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-select-outlined-leading-icon-56px {
  0% {
    transform: translateX(calc(0 - 32px)) translateY(-34.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 32px)) translateY(-34.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 32px)) translateY(-34.75px) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 32px)) translateY(-34.75px) scale(0.75);
  }
}
[dir=rtl] div.small-select .mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label--shake, div.small-select .mdc-select--with-leading-icon.mdc-select--outlined[dir=rtl] .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-select-outlined-leading-icon-56px 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-select-outlined-leading-icon-56px-rtl {
  0% {
    transform: translateX(calc(0 - -32px)) translateY(-34.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - -32px)) translateY(-34.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - -32px)) translateY(-34.75px) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - -32px)) translateY(-34.75px) scale(0.75);
  }
}
div.small-select .mdc-select--with-leading-icon.mdc-select--outlined .mdc-select__anchor :not(.mdc-notched-outline--notched) .mdc-notched-outline__notch {
  max-width: calc(100% - 96px);
}
div.small-select .mdc-select--filled .mdc-menu-surface--is-open-below {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
div.small-select .mdc-select__menu .mdc-select__icon {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
}
[dir=rtl] div.small-select .mdc-select__menu .mdc-select__icon, div.small-select .mdc-select__menu .mdc-select__icon[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
}
div.small-select .mdc-select__menu .mdc-list .mdc-list-item--selected,
div.small-select .mdc-select__menu .mdc-list .mdc-list-item--activated {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}
div.small-select .mdc-select__menu .mdc-list .mdc-list-item--selected .mdc-list-item__graphic,
div.small-select .mdc-select__menu .mdc-list .mdc-list-item--activated .mdc-list-item__graphic {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

div.myselect:not(.mdc-select--disabled) .mdc-notched-outline__leading,
div.myselect:not(.mdc-select--disabled) .mdc-notched-outline__notch,
div.myselect:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: white;
}

.mdc-list-item__text.with-icon {
  display: grid;
  grid-template-columns: 1fr 20px;
  align-items: center;
  width: 100%;
}
.mdc-list-item__text.with-icon svg {
  width: 20px;
  height: 20px;
}

.mdc-list {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  /* @alternate */
  line-height: 1.5rem;
  margin: 0;
  padding: 8px 0;
  list-style-type: none;
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mdc-list:focus {
  outline: none;
}

.mdc-list-item {
  height: 48px;
}

.mdc-list-item__secondary-text {
  color: rgba(0, 0, 0, 0.54);
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__graphic {
  background-color: transparent;
}

.mdc-list-item__graphic {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__meta {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-group__subheader {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-item--disabled .mdc-list-item__text {
  opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__text,
.mdc-list-item--disabled .mdc-list-item__primary-text,
.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--selected,
.mdc-list-item--activated {
  color: #0077FF;
  /* @alternate */
  color: var(--mdc-theme-primary, #0077FF);
}
.mdc-list-item--selected .mdc-list-item__graphic,
.mdc-list-item--activated .mdc-list-item__graphic {
  color: #0077FF;
  /* @alternate */
  color: var(--mdc-theme-primary, #0077FF);
}

.mdc-list--dense {
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 0.812rem;
}

.mdc-list-item {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  padding: 0;
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
  height: 48px;
}
.mdc-list-item:focus {
  outline: none;
}
.mdc-list-item:not(.mdc-list-item--selected):focus::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  content: "";
}
.mdc-list-item.mdc-list-item--selected::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 3px double transparent;
  border-radius: inherit;
  content: "";
}
[dir=rtl] .mdc-list-item, .mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
}
.mdc-list--icon-list .mdc-list-item {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
  height: 56px;
}
[dir=rtl] .mdc-list--icon-list .mdc-list-item, .mdc-list--icon-list .mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
}
.mdc-list--avatar-list .mdc-list-item {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
  height: 56px;
}
[dir=rtl] .mdc-list--avatar-list .mdc-list-item, .mdc-list--avatar-list .mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
}
.mdc-list--thumbnail-list .mdc-list-item {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
  height: 56px;
}
[dir=rtl] .mdc-list--thumbnail-list .mdc-list-item, .mdc-list--thumbnail-list .mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
}
.mdc-list--image-list .mdc-list-item {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
  height: 72px;
}
[dir=rtl] .mdc-list--image-list .mdc-list-item, .mdc-list--image-list .mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
}
.mdc-list--video-list .mdc-list-item {
  /* @noflip */
  padding-left: 0px;
  /* @noflip */
  padding-right: 16px;
  height: 72px;
}
[dir=rtl] .mdc-list--video-list .mdc-list-item, .mdc-list--video-list .mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 0px;
}

.mdc-list--dense .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
  width: 20px;
  height: 20px;
}
[dir=rtl] .mdc-list--dense .mdc-list-item__graphic, .mdc-list--dense .mdc-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
}

.mdc-list-item__graphic {
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  fill: currentColor;
  object-fit: cover;
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 32px;
  width: 24px;
  height: 24px;
}
[dir=rtl] .mdc-list-item__graphic, .mdc-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 32px;
  /* @noflip */
  margin-right: 0;
}
.mdc-list--icon-list .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 32px;
  width: 24px;
  height: 24px;
}
[dir=rtl] .mdc-list--icon-list .mdc-list-item__graphic, .mdc-list--icon-list .mdc-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 32px;
  /* @noflip */
  margin-right: 0;
}
.mdc-list--avatar-list .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
[dir=rtl] .mdc-list--avatar-list .mdc-list-item__graphic, .mdc-list--avatar-list .mdc-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
}
.mdc-list--thumbnail-list .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
  width: 40px;
  height: 40px;
}
[dir=rtl] .mdc-list--thumbnail-list .mdc-list-item__graphic, .mdc-list--thumbnail-list .mdc-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
}
.mdc-list--image-list .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
  width: 56px;
  height: 56px;
}
[dir=rtl] .mdc-list--image-list .mdc-list-item__graphic, .mdc-list--image-list .mdc-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
}
.mdc-list--video-list .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
  width: 100px;
  height: 56px;
}
[dir=rtl] .mdc-list--video-list .mdc-list-item__graphic, .mdc-list--video-list .mdc-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
}

.mdc-list .mdc-list-item__graphic {
  display: inline-flex;
}

.mdc-list-item__meta {
  /* @noflip */
  margin-left: auto;
  /* @noflip */
  margin-right: 0;
}
.mdc-list-item__meta:not(.material-icons) {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
}
.mdc-list-item[dir=rtl] .mdc-list-item__meta, [dir=rtl] .mdc-list-item .mdc-list-item__meta {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: auto;
}

.mdc-list-item__text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mdc-list-item__text[for] {
  pointer-events: none;
}

.mdc-list-item__primary-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list--video-list .mdc-list-item__primary-text, .mdc-list--image-list .mdc-list-item__primary-text, .mdc-list--thumbnail-list .mdc-list-item__primary-text, .mdc-list--avatar-list .mdc-list-item__primary-text, .mdc-list--icon-list .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list--video-list .mdc-list-item__primary-text::before, .mdc-list--image-list .mdc-list-item__primary-text::before, .mdc-list--thumbnail-list .mdc-list-item__primary-text::before, .mdc-list--avatar-list .mdc-list-item__primary-text::before, .mdc-list--icon-list .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list--video-list .mdc-list-item__primary-text::after, .mdc-list--image-list .mdc-list-item__primary-text::after, .mdc-list--thumbnail-list .mdc-list-item__primary-text::after, .mdc-list--avatar-list .mdc-list-item__primary-text::after, .mdc-list--icon-list .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list--dense .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list--dense .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 24px;
  content: "";
  vertical-align: 0;
}
.mdc-list--dense .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item__secondary-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-list-item__secondary-text::before {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: 0;
}
.mdc-list--dense .mdc-list-item__secondary-text {
  font-size: inherit;
}

.mdc-list--dense .mdc-list-item {
  height: 40px;
}

.mdc-list--two-line .mdc-list-item__text {
  align-self: flex-start;
}

.mdc-list--two-line .mdc-list-item {
  height: 64px;
}
.mdc-list--two-line.mdc-list--video-list .mdc-list-item, .mdc-list--two-line.mdc-list--image-list .mdc-list-item, .mdc-list--two-line.mdc-list--thumbnail-list .mdc-list-item, .mdc-list--two-line.mdc-list--avatar-list .mdc-list-item, .mdc-list--two-line.mdc-list--icon-list .mdc-list-item {
  height: 72px;
}
.mdc-list--two-line.mdc-list--icon-list .mdc-list-item__graphic {
  align-self: flex-start;
  margin-top: 16px;
}

.mdc-list--two-line.mdc-list--dense .mdc-list-item,
.mdc-list--avatar-list.mdc-list--dense .mdc-list-item {
  height: 60px;
}

.mdc-list--avatar-list.mdc-list--dense .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
  width: 36px;
  height: 36px;
}
[dir=rtl] .mdc-list--avatar-list.mdc-list--dense .mdc-list-item__graphic, .mdc-list--avatar-list.mdc-list--dense .mdc-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
}

:not(.mdc-list-item--disabled).mdc-list-item {
  cursor: pointer;
}

a.mdc-list-item {
  color: inherit;
  text-decoration: none;
}

.mdc-list-divider {
  height: 0;
  margin: 0;
  border: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.mdc-list-divider {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mdc-list-divider--padded {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-list-divider--padded, .mdc-list-divider--padded[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}

.mdc-list-divider--inset {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 72px);
}
[dir=rtl] .mdc-list-divider--inset, .mdc-list-divider--inset[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}

.mdc-list-divider--inset.mdc-list-divider--padded {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 88px);
}
[dir=rtl] .mdc-list-divider--inset.mdc-list-divider--padded, .mdc-list-divider--inset.mdc-list-divider--padded[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}

.mdc-list .mdc-list-divider--inset-leading {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-list .mdc-list-divider--inset-leading, .mdc-list .mdc-list-divider--inset-leading[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}
.mdc-list .mdc-list-divider--inset-trailing {
  width: calc(100% - 16px);
}
.mdc-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing, .mdc-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}
.mdc-list .mdc-list-divider--inset-leading.mdc-list-divider--padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-list .mdc-list-divider--inset-leading.mdc-list-divider--padding, .mdc-list .mdc-list-divider--inset-leading.mdc-list-divider--padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}
.mdc-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding, .mdc-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}

.mdc-list--icon-list .mdc-list-divider--inset-leading {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 72px);
}
[dir=rtl] .mdc-list--icon-list .mdc-list-divider--inset-leading, .mdc-list--icon-list .mdc-list-divider--inset-leading[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}
.mdc-list--icon-list .mdc-list-divider--inset-trailing {
  width: calc(100% - 16px);
}
.mdc-list--icon-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 88px);
}
[dir=rtl] .mdc-list--icon-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing, .mdc-list--icon-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}
.mdc-list--icon-list .mdc-list-divider--inset-leading.mdc-list-divider--padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-list--icon-list .mdc-list-divider--inset-leading.mdc-list-divider--padding, .mdc-list--icon-list .mdc-list-divider--inset-leading.mdc-list-divider--padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}
.mdc-list--icon-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-list--icon-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding, .mdc-list--icon-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}

.mdc-list--avatar-list .mdc-list-divider--inset-leading {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 72px);
}
[dir=rtl] .mdc-list--avatar-list .mdc-list-divider--inset-leading, .mdc-list--avatar-list .mdc-list-divider--inset-leading[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}
.mdc-list--avatar-list .mdc-list-divider--inset-trailing {
  width: calc(100% - 16px);
}
.mdc-list--avatar-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 88px);
}
[dir=rtl] .mdc-list--avatar-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing, .mdc-list--avatar-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}
.mdc-list--avatar-list .mdc-list-divider--inset-leading.mdc-list-divider--padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-list--avatar-list .mdc-list-divider--inset-leading.mdc-list-divider--padding, .mdc-list--avatar-list .mdc-list-divider--inset-leading.mdc-list-divider--padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}
.mdc-list--avatar-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-list--avatar-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding, .mdc-list--avatar-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}

.mdc-list--thumbnail-list .mdc-list-divider--inset-leading {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 72px);
}
[dir=rtl] .mdc-list--thumbnail-list .mdc-list-divider--inset-leading, .mdc-list--thumbnail-list .mdc-list-divider--inset-leading[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}
.mdc-list--thumbnail-list .mdc-list-divider--inset-trailing {
  width: calc(100% - 16px);
}
.mdc-list--thumbnail-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 88px);
}
[dir=rtl] .mdc-list--thumbnail-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing, .mdc-list--thumbnail-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}
.mdc-list--thumbnail-list .mdc-list-divider--inset-leading.mdc-list-divider--padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-list--thumbnail-list .mdc-list-divider--inset-leading.mdc-list-divider--padding, .mdc-list--thumbnail-list .mdc-list-divider--inset-leading.mdc-list-divider--padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}
.mdc-list--thumbnail-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-list--thumbnail-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding, .mdc-list--thumbnail-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}

.mdc-list--image-list .mdc-list-divider--inset-leading {
  /* @noflip */
  margin-left: 88px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 88px);
}
[dir=rtl] .mdc-list--image-list .mdc-list-divider--inset-leading, .mdc-list--image-list .mdc-list-divider--inset-leading[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 88px;
}
.mdc-list--image-list .mdc-list-divider--inset-trailing {
  width: calc(100% - 16px);
}
.mdc-list--image-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing {
  /* @noflip */
  margin-left: 88px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 104px);
}
[dir=rtl] .mdc-list--image-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing, .mdc-list--image-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 88px;
}
.mdc-list--image-list .mdc-list-divider--inset-leading.mdc-list-divider--padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-list--image-list .mdc-list-divider--inset-leading.mdc-list-divider--padding, .mdc-list--image-list .mdc-list-divider--inset-leading.mdc-list-divider--padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}
.mdc-list--image-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-list--image-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding, .mdc-list--image-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}

.mdc-list--video-list .mdc-list-divider--inset-leading {
  /* @noflip */
  margin-left: 116px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 116px);
}
[dir=rtl] .mdc-list--video-list .mdc-list-divider--inset-leading, .mdc-list--video-list .mdc-list-divider--inset-leading[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 116px;
}
.mdc-list--video-list .mdc-list-divider--inset-trailing {
  width: calc(100% - 16px);
}
.mdc-list--video-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing {
  /* @noflip */
  margin-left: 116px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 132px);
}
[dir=rtl] .mdc-list--video-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing, .mdc-list--video-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 116px;
}
.mdc-list--video-list .mdc-list-divider--inset-leading.mdc-list-divider--padding {
  /* @noflip */
  margin-left: 0px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 0px);
}
[dir=rtl] .mdc-list--video-list .mdc-list-divider--inset-leading.mdc-list-divider--padding, .mdc-list--video-list .mdc-list-divider--inset-leading.mdc-list-divider--padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0px;
}
.mdc-list--video-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding {
  /* @noflip */
  margin-left: 0px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-list--video-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding, .mdc-list--video-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0px;
}

.mdc-list-group .mdc-list {
  padding: 0;
}

.mdc-list-group__subheader {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  margin: calc((3rem - 1.5rem) / 2) 16px;
}

:not(.mdc-list-item--disabled).mdc-list-item {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::before,
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded .mdc-list-item__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--unbounded .mdc-list-item__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--foreground-activation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--foreground-deactivation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::before,
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
:not(.mdc-list-item--disabled).mdc-list-item:hover .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
:not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}
:not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
:not(.mdc-list-item--disabled).mdc-list-item--activated .mdc-list-item__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-activated-opacity, 0.12);
}
:not(.mdc-list-item--disabled).mdc-list-item--activated .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--activated .mdc-list-item__ripple::after {
  background-color: #0077FF;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #0077FF));
}
:not(.mdc-list-item--disabled).mdc-list-item--activated:hover .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.16;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.16);
}
:not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
:not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}
:not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}
:not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.24);
}
:not(.mdc-list-item--disabled).mdc-list-item--selected .mdc-list-item__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-selected-opacity, 0.08);
}
:not(.mdc-list-item--disabled).mdc-list-item--selected .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--selected .mdc-list-item__ripple::after {
  background-color: #0077FF;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #0077FF));
}
:not(.mdc-list-item--disabled).mdc-list-item--selected:hover .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.12);
}
:not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.2);
}
:not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}
:not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.2);
}
:not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.2);
}
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-list-item--disabled {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-list-item--disabled .mdc-list-item__ripple::before,
.mdc-list-item--disabled .mdc-list-item__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-list-item--disabled .mdc-list-item__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-list-item--disabled .mdc-list-item__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-list-item--disabled.mdc-ripple-upgraded .mdc-list-item__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-list-item--disabled.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-list-item--disabled.mdc-ripple-upgraded--unbounded .mdc-list-item__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-list-item--disabled.mdc-ripple-upgraded--foreground-activation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-list-item--disabled.mdc-ripple-upgraded--foreground-deactivation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-list-item--disabled .mdc-list-item__ripple::before,
.mdc-list-item--disabled .mdc-list-item__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-list-item--disabled.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-list-item--disabled .mdc-list-item__ripple::before,
.mdc-list-item--disabled .mdc-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
.mdc-list-item--disabled.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, .mdc-list-item--disabled:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-list-item--disabled .mdc-list-item__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.top-chart {
  padding: 0.5rem;
  background-color: #F7F7F7;
  border-radius: 4px;
  margin: 1rem 0rem;
}
.top-chart p.title {
  font-weight: 400;
}
.top-chart ul.container-business {
  list-style-type: none;
  /* Remove bullets */
  padding: 0;
  /* Remove padding */
  margin: 0;
  /* Remove margins */
}
.top-chart li.business-list {
  display: grid;
  grid-template-areas: "name amount percentage";
  grid-template-columns: 1fr 0.75fr 0.3fr;
  padding: 0.125rem 0rem;
  font-size: 14px;
}
.top-chart li.business-list span.name {
  grid-area: name;
}
.top-chart li.business-list span.amount {
  grid-area: amount;
  text-align: right;
}
.top-chart li.business-list span.percentage {
  grid-area: percentage;
  text-align: center;
}

div.removal-list {
  background-color: #F7F7F7;
  width: 100%;
  display: grid;
  grid-template-columns: 0.5fr 3fr 1fr;
  grid-template-areas: "icon name delete";
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  align-items: center;
  height: 46px;
  padding: 0.5rem;
  box-sizing: border-box;
  margin-bottom: 0.5rem;
}
div.removal-list .icon-element {
  width: 100%;
  grid-area: icon;
}
div.removal-list .icon-element svg {
  width: 25px;
  height: 25px;
}
div.removal-list .text {
  width: 100%;
  grid-area: name;
  padding-left: 0.5rem;
  font-size: 1.125rem;
  box-sizing: border-box;
}
div.removal-list button.delete {
  background-color: transparent;
  border: none;
  grid-area: delete;
}
div.removal-list button.delete svg {
  width: 25px;
  height: 25px;
  fill: #7c7b7b;
}

div.viewscontainer {
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  position: relative;
}
div.viewscontainer div.sectionview {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  will-change: transform;
  transition: -webkit-transform 0.3s cubic-bezier(0.465, 0.183, 0.153, 0.946);
  transition: transform 0.3s cubic-bezier(0.465, 0.183, 0.153, 0.946);
}
div.viewscontainer div.no-transition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
div.viewscontainer div.rightcommingsection {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
div.viewscontainer div.leftcommingsection {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

div.rightchangeview div.currentsection {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
div.rightchangeview div.rightcommingsection {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

div.leftchangeview div.currentsection {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
div.leftchangeview div.leftcommingsection {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.mdc-text-field--filled {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-text-field--filled .mdc-text-field__ripple::before,
.mdc-text-field--filled .mdc-text-field__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-text-field--filled .mdc-text-field__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-text-field--filled .mdc-text-field__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-text-field--filled.mdc-ripple-upgraded .mdc-text-field__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-text-field--filled.mdc-ripple-upgraded .mdc-text-field__ripple::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-text-field--filled.mdc-ripple-upgraded--unbounded .mdc-text-field__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-text-field--filled.mdc-ripple-upgraded--foreground-activation .mdc-text-field__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-text-field--filled.mdc-ripple-upgraded--foreground-deactivation .mdc-text-field__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-text-field--filled .mdc-text-field__ripple::before,
.mdc-text-field--filled .mdc-text-field__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-text-field--filled.mdc-ripple-upgraded .mdc-text-field__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-text-field__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-text-field {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  padding: 0 16px;
  display: inline-flex;
  align-items: baseline;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  /* @alternate */
  will-change: opacity, transform, color;
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}
@media all {
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.54);
  }
}
@media all {
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.54);
  }
}
.mdc-text-field .mdc-text-field__input {
  caret-color: #0077FF;
  /* @alternate */
  caret-color: var(--mdc-theme-primary, #0077FF);
}
.mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field .mdc-floating-label {
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
.mdc-text-field.mdc-text-field--with-leading-icon {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 16px;
}
[dir=rtl] .mdc-text-field.mdc-text-field--with-leading-icon, .mdc-text-field.mdc-text-field--with-leading-icon[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 0;
}
.mdc-text-field.mdc-text-field--with-trailing-icon {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 0;
}
[dir=rtl] .mdc-text-field.mdc-text-field--with-trailing-icon, .mdc-text-field.mdc-text-field--with-trailing-icon[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 16px;
}
.mdc-text-field.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 0;
}
[dir=rtl] .mdc-text-field.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon, .mdc-text-field.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 0;
}

.mdc-text-field__input {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  height: 28px;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
  min-width: 0;
  border: none;
  border-radius: 0;
  background: none;
  appearance: none;
  padding: 0;
}
.mdc-text-field__input::-ms-clear {
  display: none;
}
.mdc-text-field__input:focus {
  outline: none;
}
.mdc-text-field__input:invalid {
  box-shadow: none;
}
.mdc-text-field__input:-webkit-autofill {
  z-index: auto !important;
}
@media all {
  .mdc-text-field__input::placeholder {
    transition: opacity 67ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
  }
}
@media all {
  .mdc-text-field__input:-ms-input-placeholder {
    transition: opacity 67ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
  }
}
@media all {
  .mdc-text-field--fullwidth .mdc-text-field__input::placeholder, .mdc-text-field--no-label .mdc-text-field__input::placeholder, .mdc-text-field--focused .mdc-text-field__input::placeholder {
    transition-delay: 40ms;
    transition-duration: 110ms;
    opacity: 1;
  }
}
@media all {
  .mdc-text-field--fullwidth .mdc-text-field__input:-ms-input-placeholder, .mdc-text-field--no-label .mdc-text-field__input:-ms-input-placeholder, .mdc-text-field--focused .mdc-text-field__input:-ms-input-placeholder {
    transition-delay: 40ms;
    transition-duration: 110ms;
    opacity: 1;
  }
}

.mdc-text-field__affix {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  height: 28px;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  white-space: nowrap;
}
.mdc-text-field--label-floating .mdc-text-field__affix, .mdc-text-field--no-label .mdc-text-field__affix {
  opacity: 1;
}

.mdc-text-field__affix--prefix {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 2px;
}
[dir=rtl] .mdc-text-field__affix--prefix, .mdc-text-field__affix--prefix[dir=rtl] {
  /* @noflip */
  padding-left: 2px;
  /* @noflip */
  padding-right: 0;
}
.mdc-text-field--end-aligned .mdc-text-field__affix--prefix {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 12px;
}
[dir=rtl] .mdc-text-field--end-aligned .mdc-text-field__affix--prefix, .mdc-text-field--end-aligned .mdc-text-field__affix--prefix[dir=rtl] {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 0;
}

.mdc-text-field__affix--suffix {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 0;
}
[dir=rtl] .mdc-text-field__affix--suffix, .mdc-text-field__affix--suffix[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 12px;
}
.mdc-text-field--end-aligned .mdc-text-field__affix--suffix {
  /* @noflip */
  padding-left: 2px;
  /* @noflip */
  padding-right: 0;
}
[dir=rtl] .mdc-text-field--end-aligned .mdc-text-field__affix--suffix, .mdc-text-field--end-aligned .mdc-text-field__affix--suffix[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 2px;
}

.mdc-text-field__input:-webkit-autofill + .mdc-floating-label {
  transform: translateY(-50%) scale(0.75);
  cursor: auto;
}

.mdc-text-field--filled {
  height: 56px;
}
.mdc-text-field--filled .mdc-text-field__ripple::before,
.mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.mdc-text-field--filled:hover .mdc-text-field__ripple::before, .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-text-field--filled::before {
  display: inline-block;
  width: 0;
  height: 40px;
  content: "";
  vertical-align: 0;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: whitesmoke;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: #0077FF;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-primary, #0077FF);
}
.mdc-text-field--filled .mdc-floating-label {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .mdc-text-field--filled .mdc-floating-label, .mdc-text-field--filled .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 16px;
}
.mdc-text-field--filled .mdc-floating-label--float-above {
  transform: translateY(-106%) scale(0.75);
}
.mdc-text-field--filled.mdc-text-field--no-label .mdc-text-field__input {
  height: 100%;
}
.mdc-text-field--filled.mdc-text-field--no-label .mdc-floating-label {
  display: none;
}
.mdc-text-field--filled.mdc-text-field--no-label::before {
  display: none;
}

.mdc-text-field--outlined {
  height: 56px;
  overflow: visible;
}
.mdc-text-field--outlined .mdc-floating-label--float-above {
  transform: translateY(-37.25px) scale(1);
}
.mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-34.75px) scale(0.75);
}
.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.mdc-text-field--outlined .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-34.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-34.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-34.75px) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-34.75px) scale(0.75);
  }
}
.mdc-text-field--outlined .mdc-text-field__input {
  height: 100%;
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: #0077FF;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #0077FF);
}
.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  /* @noflip */
  border-top-left-radius: 4px;
  /* @noflip */
  border-top-right-radius: 0;
  /* @noflip */
  border-bottom-right-radius: 0;
  /* @noflip */
  border-bottom-left-radius: 4px;
}
[dir=rtl] .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading, .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading[dir=rtl] {
  /* @noflip */
  border-top-left-radius: 0;
  /* @noflip */
  border-top-right-radius: 4px;
  /* @noflip */
  border-bottom-right-radius: 4px;
  /* @noflip */
  border-bottom-left-radius: 0;
}
.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
  /* @noflip */
  border-top-left-radius: 0;
  /* @noflip */
  border-top-right-radius: 4px;
  /* @noflip */
  border-bottom-right-radius: 4px;
  /* @noflip */
  border-bottom-left-radius: 0;
}
[dir=rtl] .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing, .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing[dir=rtl] {
  /* @noflip */
  border-top-left-radius: 4px;
  /* @noflip */
  border-top-right-radius: 0;
  /* @noflip */
  border-bottom-right-radius: 0;
  /* @noflip */
  border-bottom-left-radius: 4px;
}
.mdc-text-field--outlined .mdc-notched-outline--notched .mdc-notched-outline__notch {
  padding-top: 1px;
}
.mdc-text-field--outlined .mdc-text-field__ripple::before,
.mdc-text-field--outlined .mdc-text-field__ripple::after {
  content: none;
}
.mdc-text-field--outlined .mdc-floating-label {
  /* @noflip */
  left: 4px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .mdc-text-field--outlined .mdc-floating-label, .mdc-text-field--outlined .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 4px;
}
.mdc-text-field--outlined .mdc-text-field__input {
  display: flex;
  border: none !important;
  background-color: transparent;
}

.mdc-text-field--textarea {
  flex-direction: column;
  align-items: center;
  width: auto;
  height: auto;
  padding: 0;
  transition: none;
}
.mdc-text-field--textarea .mdc-floating-label {
  top: 19px;
}
.mdc-text-field--textarea .mdc-floating-label:not(.mdc-floating-label--float-above) {
  transform: none;
}
.mdc-text-field--textarea .mdc-text-field__input {
  flex-grow: 1;
  height: auto;
  min-height: 1.5rem;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  resize: none;
  padding: 0 16px;
  line-height: 1.5rem;
}
.mdc-text-field--textarea.mdc-text-field--filled::before {
  display: none;
}
.mdc-text-field--textarea.mdc-text-field--filled .mdc-floating-label--float-above {
  transform: translateY(-10.25px) scale(0.75);
}
.mdc-text-field--textarea.mdc-text-field--filled .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-textarea-filled 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-textarea-filled {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-10.25px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-10.25px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-10.25px) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-10.25px) scale(0.75);
  }
}
.mdc-text-field--textarea.mdc-text-field--filled .mdc-text-field__input {
  margin-top: 23px;
  margin-bottom: 9px;
}
.mdc-text-field--textarea.mdc-text-field--filled.mdc-text-field--no-label .mdc-text-field__input {
  margin-top: 16px;
  margin-bottom: 16px;
}
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-notched-outline--notched .mdc-notched-outline__notch {
  padding-top: 0;
}
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-floating-label--float-above {
  transform: translateY(-27.25px) scale(1);
}
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.mdc-text-field--textarea.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-24.75px) scale(0.75);
}
.mdc-text-field--textarea.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-textarea-outlined 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-textarea-outlined {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-24.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-24.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-24.75px) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-24.75px) scale(0.75);
  }
}
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-text-field__input {
  margin-top: 16px;
  margin-bottom: 16px;
}
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-floating-label {
  top: 18px;
}
.mdc-text-field--textarea.mdc-text-field--with-internal-counter .mdc-text-field__input {
  margin-bottom: 2px;
}
.mdc-text-field--textarea.mdc-text-field--with-internal-counter .mdc-text-field-character-counter {
  align-self: flex-end;
  padding: 0 16px;
}
.mdc-text-field--textarea.mdc-text-field--with-internal-counter .mdc-text-field-character-counter::after {
  display: inline-block;
  width: 0;
  height: 16px;
  content: "";
  vertical-align: -16px;
}
.mdc-text-field--textarea.mdc-text-field--with-internal-counter .mdc-text-field-character-counter::before {
  display: none;
}

.mdc-text-field__resizer {
  align-self: stretch;
  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: 100%;
  max-width: 100%;
  min-height: 56px;
  min-width: fit-content;
  /* @alternate */
  min-width: -moz-available;
  /* @alternate */
  min-width: -webkit-fill-available;
  overflow: hidden;
  resize: both;
}
.mdc-text-field--filled .mdc-text-field__resizer {
  transform: translateY(-1px);
}
.mdc-text-field--filled .mdc-text-field__resizer .mdc-text-field__input,
.mdc-text-field--filled .mdc-text-field__resizer .mdc-text-field-character-counter {
  transform: translateY(1px);
}
.mdc-text-field--outlined .mdc-text-field__resizer {
  transform: translateX(-1px) translateY(-1px);
}
[dir=rtl] .mdc-text-field--outlined .mdc-text-field__resizer, .mdc-text-field--outlined .mdc-text-field__resizer[dir=rtl] {
  transform: translateX(1px) translateY(-1px);
}
.mdc-text-field--outlined .mdc-text-field__resizer .mdc-text-field__input,
.mdc-text-field--outlined .mdc-text-field__resizer .mdc-text-field-character-counter {
  transform: translateX(1px) translateY(1px);
}
[dir=rtl] .mdc-text-field--outlined .mdc-text-field__resizer .mdc-text-field__input, .mdc-text-field--outlined .mdc-text-field__resizer .mdc-text-field__input[dir=rtl],
[dir=rtl] .mdc-text-field--outlined .mdc-text-field__resizer .mdc-text-field-character-counter,
.mdc-text-field--outlined .mdc-text-field__resizer .mdc-text-field-character-counter[dir=rtl] {
  transform: translateX(-1px) translateY(1px);
}

.mdc-text-field--fullwidth {
  padding: 0;
  width: 100%;
}
.mdc-text-field--fullwidth:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.mdc-text-field--fullwidth.mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.mdc-text-field--fullwidth:not(.mdc-text-field--textarea) {
  display: flex;
}
.mdc-text-field--fullwidth:not(.mdc-text-field--textarea) .mdc-text-field__input {
  height: 100%;
}
.mdc-text-field--fullwidth:not(.mdc-text-field--textarea) .mdc-floating-label {
  display: none;
}
.mdc-text-field--fullwidth:not(.mdc-text-field--textarea)::before {
  display: none;
}
.mdc-text-field--fullwidth:not(.mdc-text-field--textarea) .mdc-text-field__ripple::before,
.mdc-text-field--fullwidth:not(.mdc-text-field--textarea) .mdc-text-field__ripple::after {
  content: none;
}
.mdc-text-field--fullwidth:not(.mdc-text-field--textarea):not(.mdc-text-field--disabled) {
  background-color: transparent;
}
.mdc-text-field--fullwidth.mdc-text-field--textarea .mdc-text-field__resizer {
  resize: vertical;
}

.mdc-text-field--with-leading-icon.mdc-text-field--filled .mdc-floating-label {
  max-width: calc(100% - 48px);
  /* @noflip */
  left: 48px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--filled .mdc-floating-label, .mdc-text-field--with-leading-icon.mdc-text-field--filled .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 48px;
}
.mdc-text-field--with-leading-icon.mdc-text-field--filled .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75 - 64px / 0.75);
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label {
  /* @noflip */
  left: 36px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label, .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 36px;
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined :not(.mdc-notched-outline--notched) .mdc-notched-outline__notch {
  max-width: calc(100% - 60px);
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above {
  transform: translateY(-37.25px) translateX(-32px) scale(1);
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above, .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-37.25px) translateX(32px) scale(1);
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-34.75px) translateX(-32px) scale(0.75);
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above, .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir=rtl],
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-34.75px) translateX(32px) scale(0.75);
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon {
  0% {
    transform: translateX(calc(0 - 32px)) translateY(-34.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 32px)) translateY(-34.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 32px)) translateY(-34.75px) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 32px)) translateY(-34.75px) scale(0.75);
  }
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--shake, .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-rtl {
  0% {
    transform: translateX(calc(0 - -32px)) translateY(-34.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - -32px)) translateY(-34.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - -32px)) translateY(-34.75px) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - -32px)) translateY(-34.75px) scale(0.75);
  }
}

.mdc-text-field--with-trailing-icon.mdc-text-field--filled .mdc-floating-label {
  max-width: calc(100% - 64px);
}
.mdc-text-field--with-trailing-icon.mdc-text-field--filled .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75 - 64px / 0.75);
}
.mdc-text-field--with-trailing-icon.mdc-text-field--outlined :not(.mdc-notched-outline--notched) .mdc-notched-outline__notch {
  max-width: calc(100% - 60px);
}

.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--filled .mdc-floating-label {
  max-width: calc(100% - 96px);
}
.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--filled .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75 - 96px / 0.75);
}

.mdc-text-field-helper-line {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}
.mdc-text-field + .mdc-text-field-helper-line {
  padding-right: 16px;
  padding-left: 16px;
}

.mdc-form-field > .mdc-text-field + label {
  align-self: flex-start;
}

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 119, 255, 0.87);
}
.mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--focused .mdc-notched-outline__trailing {
  border-width: 2px;
}
.mdc-text-field--focused + .mdc-text-field-helper-line .mdc-text-field-helper-text:not(.mdc-text-field-helper-text--validation-msg) {
  opacity: 1;
}
.mdc-text-field--focused.mdc-text-field--outlined .mdc-notched-outline--notched .mdc-notched-outline__notch {
  padding-top: 2px;
}
.mdc-text-field--focused.mdc-text-field--outlined.mdc-text-field--textarea .mdc-notched-outline--notched .mdc-notched-outline__notch {
  padding-top: 0;
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid .mdc-text-field__input {
  caret-color: #b00020;
  /* @alternate */
  caret-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  opacity: 1;
}

.mdc-text-field--disabled {
  pointer-events: none;
}
.mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.38);
}
@media all {
  .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
@media all {
  .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
.mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field-character-counter,
.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.mdc-text-field--disabled .mdc-notched-outline__leading,
.mdc-text-field--disabled .mdc-notched-outline__notch,
.mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
@media screen and (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
}
@media screen and (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
}
@media screen and (-ms-high-contrast: active) {
  .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
}
@media screen and (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field-character-counter,
.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
}
@media screen and (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
}
@media screen and (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
}
@media screen and (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
}
@media screen and (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
}
@media screen and (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
}
@media screen and (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-notched-outline__leading,
.mdc-text-field--disabled .mdc-notched-outline__notch,
.mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.mdc-text-field--disabled .mdc-floating-label {
  cursor: default;
}
.mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #fafafa;
}
.mdc-text-field--disabled.mdc-text-field--filled .mdc-text-field__ripple {
  display: none;
}
.mdc-text-field--disabled .mdc-text-field__input {
  pointer-events: auto;
}

.mdc-text-field--end-aligned .mdc-text-field__input {
  /* @noflip */
  text-align: right;
}
[dir=rtl] .mdc-text-field--end-aligned .mdc-text-field__input, .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /* @noflip */
  text-align: left;
}

[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__input,
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__affix, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__input,
.mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__affix {
  /* @noflip */
  direction: ltr;
}
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__affix--prefix, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__affix--prefix {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 2px;
}
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__affix--suffix, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__affix--suffix {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 0;
}
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__icon--leading, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__icon--leading {
  order: 1;
}
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__affix--suffix, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__affix--suffix {
  order: 2;
}
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__input, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__input {
  order: 3;
}
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__affix--prefix, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__affix--prefix {
  order: 4;
}
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__icon--trailing, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__icon--trailing {
  order: 5;
}
[dir=rtl] .mdc-text-field--ltr-text.mdc-text-field--end-aligned .mdc-text-field__input, .mdc-text-field--ltr-text.mdc-text-field--end-aligned[dir=rtl] .mdc-text-field__input {
  /* @noflip */
  text-align: right;
}
[dir=rtl] .mdc-text-field--ltr-text.mdc-text-field--end-aligned .mdc-text-field__affix--prefix, .mdc-text-field--ltr-text.mdc-text-field--end-aligned[dir=rtl] .mdc-text-field__affix--prefix {
  /* @noflip */
  padding-right: 12px;
}
[dir=rtl] .mdc-text-field--ltr-text.mdc-text-field--end-aligned .mdc-text-field__affix--suffix, .mdc-text-field--ltr-text.mdc-text-field--end-aligned[dir=rtl] .mdc-text-field__affix--suffix {
  /* @noflip */
  padding-left: 2px;
}

.mdc-text-field-helper-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin: 0;
  opacity: 0;
  will-change: opacity;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
.mdc-text-field-helper-text::before {
  display: inline-block;
  width: 0;
  height: 16px;
  content: "";
  vertical-align: 0;
}

.mdc-text-field-helper-text--persistent {
  transition: none;
  opacity: 1;
  will-change: initial;
}

.mdc-text-field-character-counter {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  /* @noflip */
  margin-left: auto;
  /* @noflip */
  margin-right: 0;
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 0;
  white-space: nowrap;
}
.mdc-text-field-character-counter::before {
  display: inline-block;
  width: 0;
  height: 16px;
  content: "";
  vertical-align: 0;
}
[dir=rtl] .mdc-text-field-character-counter, .mdc-text-field-character-counter[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: auto;
}
[dir=rtl] .mdc-text-field-character-counter, .mdc-text-field-character-counter[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 16px;
}

.mdc-text-field__icon {
  align-self: center;
  cursor: pointer;
}
.mdc-text-field__icon:not([tabindex]), .mdc-text-field__icon[tabindex="-1"] {
  cursor: default;
  pointer-events: none;
}
.mdc-text-field__icon svg {
  display: block;
}

.mdc-text-field__icon--leading {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 8px;
}
[dir=rtl] .mdc-text-field__icon--leading, .mdc-text-field__icon--leading[dir=rtl] {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 16px;
}

.mdc-text-field__icon--trailing {
  /* @noflip */
  margin-left: 12px;
  /* @noflip */
  margin-right: 12px;
}
[dir=rtl] .mdc-text-field__icon--trailing, .mdc-text-field__icon--trailing[dir=rtl] {
  /* @noflip */
  margin-left: 12px;
  /* @noflip */
  margin-right: 12px;
}

.mdc-text-field.smaller {
  height: 30px;
}
.mdc-text-field.smaller .mdc-text-field__input {
  height: 100%;
}
.mdc-text-field.smaller .mdc-floating-label {
  display: none;
}
.mdc-text-field.smaller::before {
  display: none;
}

.mdc-text-field.text-field-total {
  height: 70px;
}
.mdc-text-field.text-field-total input {
  text-align: right;
  font-size: 2rem;
  letter-spacing: 1px;
}

label.mdc-text-field.text-field-big {
  height: 70px;
}
label.mdc-text-field.text-field-big input {
  font-size: 2rem;
  letter-spacing: 1px;
  height: 70px !important;
}
label.mdc-text-field.text-field-big input.inner-big-textfield {
  height: 70px !important;
}
label.mdc-text-field.text-field-big .mdc-text-field__affix {
  font-size: 1.75rem;
}
label.mdc-text-field.text-field-big .mdc-text-field--filled .mdc-floating-label--float-above {
  transform: translateY(-180%) scale(0.75);
}
label.mdc-text-field.text-field-big .mdc-text-field__input {
  padding-top: 1.75rem;
  padding-left: 1rem;
  height: 70px !important;
}

.mdc-text-field.full-width {
  width: 100%;
}

.text-field-with-action {
  position: relative;
}
.text-field-with-action button {
  position: absolute;
  right: 5px;
  top: 16px;
}
.text-field-with-action label input {
  padding-right: 64px;
}

.data-hint {
  border-radius: 4px;
  padding: 0.25rem;
  display: block;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background-color: #0077FF;
}
.data-hint .name {
  display: block;
  font-size: 10px;
  font-weight: 700;
}
.data-hint .value {
  display: block;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
}

.data-hint.orange {
  background-color: #FE7235;
}

.data-hint.green {
  background-color: #2E9599;
}

.data-hint.red {
  background-color: #D1463F;
}

div.logo-loading-container {
  width: 50px;
}

div.logo-margin {
  margin: 1rem 0rem;
}
div.logo-margin svg {
  fill: #0077FF;
}

div.header-logo {
  height: var(--top-bar-height, 50px);
  display: grid;
  grid-template-columns: 90px 50px auto;
  align-items: center;
}
div.header-logo span {
  font-size: 14px;
  font-weight: 300;
}
div.header-logo svg {
  width: 30px;
  height: 30px;
}
div.header-logo .header-actions {
  display: grid;
  align-items: center;
  justify-items: end;
}
div.header-logo .header-actions button {
  background: none;
  border: none;
}
div.header-logo .header-actions button svg {
  width: 25px;
  height: 25px;
  fill: #F7F7F7;
}
div.header-logo .header-actions button:focus {
  outline: none;
}

div.header-logo.blue-background {
  background-color: #0077FF;
  color: #F7F7F7;
}

.tip {
  display: grid;
  grid-template-columns: 1fr 1.61fr;
  grid-gap: 5px;
  align-items: center;
  max-width: 600px;
}

.calendar-widget {
  margin: 0rem auto;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  background-color: #0077FF;
  color: #F7F7F7;
  padding: 0.5rem 1rem;
  box-shadow: 0 4px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.calendar-widget span {
  display: block;
  text-align: center;
  padding: 0.25rem 0rem;
}
.calendar-widget span.date-text {
  font-size: 14px;
}
.calendar-widget span.month-total-text {
  font-weight: 600;
  font-size: 1.125rem;
}
.calendar-widget button {
  background-color: #0077FF;
  color: #F7F7F7;
  border: none;
}
.calendar-widget button div.square {
  width: 20px;
}
.calendar-widget button div.square svg {
  fill: #F7F7F7;
}

a {
  color: #0077FF;
}

.header-list {
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-template-areas: "text action";
  align-items: center;
}
.header-list p {
  grid-area: text;
  margin: 0rem;
  padding: 0rem;
  font-weight: 500;
  font-size: 1.25rem;
}
.header-list button {
  grid-area: action;
}

.header-list.small p {
  font-size: 1.125rem;
}

.header-section {
  display: grid;
  grid-template-columns: 25px 3fr;
  grid-template-areas: "icon title";
  align-items: center;
}
.header-section .icon-section {
  grid-area: icon;
}
.header-section .icon-section svg {
  width: 25px;
  height: 25px;
}
.header-section p {
  text-transform: uppercase;
  grid-area: title;
  font-weight: 600;
  padding-left: 0.5rem;
  margin: 0rem;
}

.header-hero-section {
  background-color: #0077FF;
  color: #F7F7F7;
  padding: 1rem 16px;
}
.header-hero-section h4 {
  font-size: 20px;
}

.grid-button {
  --auto-grid-min-size: 4rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
  gap: 4px;
}
.grid-button .button {
  position: relative;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #F7F7F7;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.grid-button .button svg {
  width: 100%;
  height: 100%;
}
.grid-button .button::before {
  content: "";
  display: block;
  padding-top: 100%;
}
.grid-button .button .content {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 0.75rem;
}
.grid-button .button.selected {
  border: 3px solid #0077FF;
  -webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
  -moz-box-shadow: inset 0px 0px 5px #c1c1c1;
  box-shadow: inset 0px 0px 5px #c1c1c1;
}

.tab-bar {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  bottom: 0rem;
  margin: 0rem;
  list-style-type: none;
  -webkit-padding-start: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: bottom 0.3s;
  -webkit-transition: bottom 0.5s;
  height: 44px;
}
.tab-bar svg {
  width: 20px;
  height: 20px;
}

.tab {
  width: 33%;
  padding: 10px 0;
  background: #F7F7F7;
  color: #607D8B;
  overflow: hidden;
  text-align: center;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  cursor: pointer;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.tab svg {
  fill: #3e3e3e;
  -webkit-transition: fill 0.5s ease;
  transition: fill 0.5s ease;
}

.tab.selected svg {
  fill: #0077FF;
}

.indicator {
  width: 33%;
  height: 3px;
  background: #0077FF;
  position: absolute;
  margin-top: 44px;
  margin-left: 0;
  bottom: 0rem;
  -webkit-transition: margin 0.5s ease;
  transition: margin 0.5s ease;
}

@media only screen and (min-width: 768px) {
  .tab-bar {
    display: none;
  }
}
.input-calendar-style {
  position: relative;
}
.input-calendar-style input {
  height: 56px;
  font-size: 1rem;
  padding-left: 2.5rem;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #3e3e3e;
  background-color: whitesmoke;
  width: 100%;
  display: block;
  box-sizing: border-box;
}
.input-calendar-style svg {
  position: absolute;
  width: 25px;
  height: 25px;
  top: 13px;
  left: 10px;
  fill: #3e3e3e;
}

.mdc-checkbox {
  padding: calc((40px - 18px) / 2);
  /* @alternate */
  padding: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
  margin: calc((40px - 40px) / 2);
  /* @alternate */
  margin: calc((var(--mdc-checkbox-ripple-size, 40px) - var(--mdc-checkbox-ripple-size, 40px)) / 2);
}
.mdc-checkbox .mdc-checkbox__ripple::before, .mdc-checkbox .mdc-checkbox__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
.mdc-checkbox:hover .mdc-checkbox__ripple::before, .mdc-checkbox.mdc-ripple-surface--hover .mdc-checkbox__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-checkbox.mdc-ripple-upgraded--background-focused .mdc-checkbox__ripple::before, .mdc-checkbox:not(.mdc-ripple-upgraded):focus .mdc-checkbox__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-checkbox:not(.mdc-ripple-upgraded) .mdc-checkbox__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-checkbox:not(.mdc-ripple-upgraded):active .mdc-checkbox__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-checkbox.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-checkbox.mdc-checkbox--selected .mdc-checkbox__ripple::before, .mdc-checkbox.mdc-checkbox--selected .mdc-checkbox__ripple::after {
  background-color: #feeae6;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-secondary, #feeae6));
}
.mdc-checkbox.mdc-checkbox--selected:hover .mdc-checkbox__ripple::before, .mdc-checkbox.mdc-checkbox--selected.mdc-ripple-surface--hover .mdc-checkbox__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-checkbox.mdc-checkbox--selected.mdc-ripple-upgraded--background-focused .mdc-checkbox__ripple::before, .mdc-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded):focus .mdc-checkbox__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded) .mdc-checkbox__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded):active .mdc-checkbox__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-checkbox.mdc-checkbox--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-checkbox.mdc-ripple-upgraded--background-focused.mdc-checkbox--selected .mdc-checkbox__ripple::before,
.mdc-checkbox.mdc-ripple-upgraded--background-focused.mdc-checkbox--selected .mdc-checkbox__ripple::after {
  background-color: #feeae6;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-secondary, #feeae6));
}
.mdc-checkbox .mdc-checkbox__background {
  top: calc((40px - 18px) / 2);
  /* @alternate */
  top: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
  left: calc((40px - 18px) / 2);
  /* @alternate */
  left: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
}
.mdc-checkbox .mdc-checkbox__native-control {
  top: calc((40px - 40px) / 2);
  /* @alternate */
  top: calc((var(--mdc-checkbox-ripple-size, 40px) - var(--mdc-checkbox-ripple-size, 40px)) / 2);
  right: calc((40px - 40px) / 2);
  /* @alternate */
  right: calc((var(--mdc-checkbox-ripple-size, 40px) - var(--mdc-checkbox-ripple-size, 40px)) / 2);
  left: calc((40px - 40px) / 2);
  /* @alternate */
  left: calc((var(--mdc-checkbox-ripple-size, 40px) - var(--mdc-checkbox-ripple-size, 40px)) / 2);
  width: 40px;
  /* @alternate */
  width: var(--mdc-checkbox-ripple-size, 40px);
  height: 40px;
  /* @alternate */
  height: var(--mdc-checkbox-ripple-size, 40px);
}
.mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
  border-color: rgba(0, 0, 0, 0.54);
  /* @alternate */
  border-color: var(--mdc-checkbox-unchecked-color, rgba(0, 0, 0, 0.54));
  background-color: transparent;
}
.mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled ~ .mdc-checkbox__background {
  border-color: #feeae6;
  /* @alternate */
  border-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #feeae6));
  background-color: #feeae6;
  /* @alternate */
  background-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #feeae6));
}
@keyframes mdc-checkbox-fade-in-background-8A000000FFFEEAE600000000FFFEEAE6 {
  0% {
    border-color: rgba(0, 0, 0, 0.54);
    /* @alternate */
    border-color: var(--mdc-checkbox-unchecked-color, rgba(0, 0, 0, 0.54));
    background-color: transparent;
  }
  50% {
    border-color: #feeae6;
    /* @alternate */
    border-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #feeae6));
    background-color: #feeae6;
    /* @alternate */
    background-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #feeae6));
  }
}
@keyframes mdc-checkbox-fade-out-background-8A000000FFFEEAE600000000FFFEEAE6 {
  0%, 80% {
    border-color: #feeae6;
    /* @alternate */
    border-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #feeae6));
    background-color: #feeae6;
    /* @alternate */
    background-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #feeae6));
  }
  100% {
    border-color: rgba(0, 0, 0, 0.54);
    /* @alternate */
    border-color: var(--mdc-checkbox-unchecked-color, rgba(0, 0, 0, 0.54));
    background-color: transparent;
  }
}
.mdc-checkbox.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background, .mdc-checkbox.mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
  animation-name: mdc-checkbox-fade-in-background-8A000000FFFEEAE600000000FFFEEAE6;
}
.mdc-checkbox.mdc-checkbox--anim-checked-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background, .mdc-checkbox.mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
  animation-name: mdc-checkbox-fade-out-background-8A000000FFFEEAE600000000FFFEEAE6;
}
.mdc-checkbox .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
  border-color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  border-color: var(--mdc-checkbox-disabled-color, rgba(0, 0, 0, 0.38));
  background-color: transparent;
}
.mdc-checkbox .mdc-checkbox__native-control[disabled]:checked ~ .mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control[disabled]:indeterminate ~ .mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true][disabled] ~ .mdc-checkbox__background {
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  background-color: var(--mdc-checkbox-disabled-color, rgba(0, 0, 0, 0.38));
}
.mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  color: #442b2d;
  /* @alternate */
  color: var(--mdc-checkbox-ink-color, #442b2d);
}
.mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  border-color: #442b2d;
  /* @alternate */
  border-color: var(--mdc-checkbox-ink-color, #442b2d);
}
.mdc-checkbox .mdc-checkbox__native-control:disabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  color: #442b2d;
  /* @alternate */
  color: var(--mdc-checkbox-ink-color, #442b2d);
}
.mdc-checkbox .mdc-checkbox__native-control:disabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  border-color: #442b2d;
  /* @alternate */
  border-color: var(--mdc-checkbox-ink-color, #442b2d);
}

@keyframes mdc-checkbox-unchecked-checked-checkmark-path {
  0%, 50% {
    stroke-dashoffset: 29.7833385;
  }
  50% {
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes mdc-checkbox-unchecked-indeterminate-mixedmark {
  0%, 68.2% {
    transform: scaleX(0);
  }
  68.2% {
    animation-timing-function: cubic-bezier(0, 0, 0, 1);
  }
  100% {
    transform: scaleX(1);
  }
}
@keyframes mdc-checkbox-checked-unchecked-checkmark-path {
  from {
    animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
    opacity: 1;
    stroke-dashoffset: 0;
  }
  to {
    opacity: 0;
    stroke-dashoffset: -29.7833385;
  }
}
@keyframes mdc-checkbox-checked-indeterminate-checkmark {
  from {
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    transform: rotate(0deg);
    opacity: 1;
  }
  to {
    transform: rotate(45deg);
    opacity: 0;
  }
}
@keyframes mdc-checkbox-indeterminate-checked-checkmark {
  from {
    animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
    transform: rotate(45deg);
    opacity: 0;
  }
  to {
    transform: rotate(360deg);
    opacity: 1;
  }
}
@keyframes mdc-checkbox-checked-indeterminate-mixedmark {
  from {
    animation-timing-function: mdc-animation-deceleration-curve-timing-function;
    transform: rotate(-45deg);
    opacity: 0;
  }
  to {
    transform: rotate(0deg);
    opacity: 1;
  }
}
@keyframes mdc-checkbox-indeterminate-checked-mixedmark {
  from {
    animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
    transform: rotate(0deg);
    opacity: 1;
  }
  to {
    transform: rotate(315deg);
    opacity: 0;
  }
}
@keyframes mdc-checkbox-indeterminate-unchecked-mixedmark {
  0% {
    animation-timing-function: linear;
    transform: scaleX(1);
    opacity: 1;
  }
  32.8%, 100% {
    transform: scaleX(0);
    opacity: 0;
  }
}
.mdc-checkbox {
  display: inline-block;
  position: relative;
  flex: 0 0 18px;
  box-sizing: content-box;
  width: 18px;
  height: 18px;
  line-height: 0;
  white-space: nowrap;
  cursor: pointer;
  vertical-align: bottom;
}

@media screen and (-ms-high-contrast: active) {
  .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
    border-color: GrayText;
    /* @alternate */
    border-color: var(--mdc-checkbox-disabled-color, GrayText);
    background-color: transparent;
  }

  .mdc-checkbox__native-control[disabled]:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control[disabled]:indeterminate ~ .mdc-checkbox__background,
.mdc-checkbox__native-control[data-indeterminate=true][disabled] ~ .mdc-checkbox__background {
    border-color: GrayText;
    background-color: transparent;
    /* @alternate */
    background-color: var(--mdc-checkbox-disabled-color, transparent);
  }

  .mdc-checkbox__native-control:disabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
    color: GrayText;
    /* @alternate */
    color: var(--mdc-checkbox-ink-color, GrayText);
  }
  .mdc-checkbox__native-control:disabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
    border-color: GrayText;
    /* @alternate */
    border-color: var(--mdc-checkbox-ink-color, GrayText);
  }

  .mdc-checkbox__mixedmark {
    margin: 0 1px;
  }
}
.mdc-checkbox--disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-checkbox__background {
  display: inline-flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  border: 2px solid currentColor;
  border-radius: 2px;
  background-color: transparent;
  pointer-events: none;
  will-change: background-color, border-color;
  transition: background-color 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), border-color 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

.mdc-checkbox__checkmark {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  transition: opacity 180ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}
.mdc-checkbox--upgraded .mdc-checkbox__checkmark {
  opacity: 1;
}

.mdc-checkbox__checkmark-path {
  transition: stroke-dashoffset 180ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  stroke: currentColor;
  stroke-width: 3.12px;
  stroke-dashoffset: 29.7833385;
  stroke-dasharray: 29.7833385;
}

.mdc-checkbox__mixedmark {
  width: 100%;
  height: 0;
  transform: scaleX(0) rotate(0deg);
  border-width: 1px;
  border-style: solid;
  opacity: 0;
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__background, .mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__background, .mdc-checkbox--anim-checked-unchecked .mdc-checkbox__background, .mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__background {
  animation-duration: 180ms;
  animation-timing-function: linear;
}
.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__checkmark-path {
  animation: mdc-checkbox-unchecked-checked-checkmark-path 180ms linear 0s;
  transition: none;
}
.mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__mixedmark {
  animation: mdc-checkbox-unchecked-indeterminate-mixedmark 90ms linear 0s;
  transition: none;
}
.mdc-checkbox--anim-checked-unchecked .mdc-checkbox__checkmark-path {
  animation: mdc-checkbox-checked-unchecked-checkmark-path 90ms linear 0s;
  transition: none;
}
.mdc-checkbox--anim-checked-indeterminate .mdc-checkbox__checkmark {
  animation: mdc-checkbox-checked-indeterminate-checkmark 90ms linear 0s;
  transition: none;
}
.mdc-checkbox--anim-checked-indeterminate .mdc-checkbox__mixedmark {
  animation: mdc-checkbox-checked-indeterminate-mixedmark 90ms linear 0s;
  transition: none;
}
.mdc-checkbox--anim-indeterminate-checked .mdc-checkbox__checkmark {
  animation: mdc-checkbox-indeterminate-checked-checkmark 500ms linear 0s;
  transition: none;
}
.mdc-checkbox--anim-indeterminate-checked .mdc-checkbox__mixedmark {
  animation: mdc-checkbox-indeterminate-checked-mixedmark 500ms linear 0s;
  transition: none;
}
.mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__mixedmark {
  animation: mdc-checkbox-indeterminate-unchecked-mixedmark 300ms linear 0s;
  transition: none;
}

.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background,
.mdc-checkbox__native-control[data-indeterminate=true] ~ .mdc-checkbox__background {
  transition: border-color 90ms 0ms cubic-bezier(0, 0, 0.2, 1), background-color 90ms 0ms cubic-bezier(0, 0, 0.2, 1);
}
.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__checkmark-path,
.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__checkmark-path,
.mdc-checkbox__native-control[data-indeterminate=true] ~ .mdc-checkbox__background .mdc-checkbox__checkmark-path {
  stroke-dashoffset: 0;
}

.mdc-checkbox__native-control {
  position: absolute;
  margin: 0;
  padding: 0;
  opacity: 0;
  cursor: inherit;
}
.mdc-checkbox__native-control:disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-checkbox--touch {
  margin: calc((48px - 40px) / 2);
  /* @alternate */
  margin: calc((var(--mdc-checkbox-touch-target-size, 48px) - var(--mdc-checkbox-ripple-size, 40px)) / 2);
}
.mdc-checkbox--touch .mdc-checkbox__native-control {
  top: calc((40px - 48px) / 2);
  /* @alternate */
  top: calc((var(--mdc-checkbox-ripple-size, 40px) - var(--mdc-checkbox-touch-target-size, 48px)) / 2);
  right: calc((40px - 48px) / 2);
  /* @alternate */
  right: calc((var(--mdc-checkbox-ripple-size, 40px) - var(--mdc-checkbox-touch-target-size, 48px)) / 2);
  left: calc((40px - 48px) / 2);
  /* @alternate */
  left: calc((var(--mdc-checkbox-ripple-size, 40px) - var(--mdc-checkbox-touch-target-size, 48px)) / 2);
  width: 48px;
  /* @alternate */
  width: var(--mdc-checkbox-touch-target-size, 48px);
  height: 48px;
  /* @alternate */
  height: var(--mdc-checkbox-touch-target-size, 48px);
}

.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  transition: opacity 180ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 180ms 0ms cubic-bezier(0, 0, 0.2, 1);
  opacity: 1;
}
.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  transform: scaleX(1) rotate(-45deg);
}

.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__checkmark,
.mdc-checkbox__native-control[data-indeterminate=true] ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}
.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__mixedmark,
.mdc-checkbox__native-control[data-indeterminate=true] ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  transform: scaleX(1) rotate(0deg);
  opacity: 1;
}

.mdc-checkbox.mdc-checkbox--upgraded .mdc-checkbox__background,
.mdc-checkbox.mdc-checkbox--upgraded .mdc-checkbox__checkmark,
.mdc-checkbox.mdc-checkbox--upgraded .mdc-checkbox__checkmark-path,
.mdc-checkbox.mdc-checkbox--upgraded .mdc-checkbox__mixedmark {
  transition: none;
}

.mdc-checkbox {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-checkbox .mdc-checkbox__ripple::before,
.mdc-checkbox .mdc-checkbox__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-checkbox .mdc-checkbox__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-checkbox .mdc-checkbox__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-checkbox.mdc-ripple-upgraded .mdc-checkbox__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-checkbox.mdc-ripple-upgraded .mdc-checkbox__ripple::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-checkbox.mdc-ripple-upgraded--unbounded .mdc-checkbox__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-checkbox.mdc-ripple-upgraded--foreground-activation .mdc-checkbox__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-checkbox.mdc-ripple-upgraded--foreground-deactivation .mdc-checkbox__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-checkbox .mdc-checkbox__ripple::before,
.mdc-checkbox .mdc-checkbox__ripple::after {
  top: calc(50% - 50%);
  /* @noflip */
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}
.mdc-checkbox.mdc-ripple-upgraded .mdc-checkbox__ripple::before,
.mdc-checkbox.mdc-ripple-upgraded .mdc-checkbox__ripple::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-checkbox.mdc-ripple-upgraded .mdc-checkbox__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-checkbox {
  z-index: 0;
}
.mdc-checkbox .mdc-checkbox__ripple::before,
.mdc-checkbox .mdc-checkbox__ripple::after {
  z-index: -1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, -1);
}

.mdc-checkbox__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
}
.mdc-form-field > label {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: auto;
  /* @noflip */
  padding-left: 4px;
  /* @noflip */
  padding-right: 0;
  order: 0;
}
[dir=rtl] .mdc-form-field > label, .mdc-form-field > label[dir=rtl] {
  /* @noflip */
  margin-left: auto;
  /* @noflip */
  margin-right: 0;
}
[dir=rtl] .mdc-form-field > label, .mdc-form-field > label[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 4px;
}

.mdc-form-field--nowrap > label {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mdc-form-field--align-end > label {
  /* @noflip */
  margin-left: auto;
  /* @noflip */
  margin-right: 0;
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 4px;
  order: -1;
}
[dir=rtl] .mdc-form-field--align-end > label, .mdc-form-field--align-end > label[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: auto;
}
[dir=rtl] .mdc-form-field--align-end > label, .mdc-form-field--align-end > label[dir=rtl] {
  /* @noflip */
  padding-left: 4px;
  /* @noflip */
  padding-right: 0;
}

.mdc-form-field--space-between {
  justify-content: space-between;
}
.mdc-form-field--space-between > label {
  margin: 0;
}
[dir=rtl] .mdc-form-field--space-between > label, .mdc-form-field--space-between > label[dir=rtl] {
  margin: 0;
}

.kebab-menu button {
  background: none;
  border: none;
}
.kebab-menu button svg {
  width: 25px;
  height: 25px;
}

.chart-container {
  background-color: #F7F7F7;
  margin: 0rem auto;
  margin-top: 1rem;
  border-radius: 4px;
  padding: 0.5rem 0rem;
  box-sizing: border-box;
}

.mdc-switch__thumb-underlay {
  /* @noflip */
  left: -14px;
  /* @noflip */
  right: initial;
  top: -17px;
  width: 48px;
  height: 48px;
}
[dir=rtl] .mdc-switch__thumb-underlay, .mdc-switch__thumb-underlay[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: -14px;
}

.mdc-switch__native-control {
  width: 64px;
  height: 48px;
}

.mdc-switch {
  display: inline-block;
  position: relative;
  outline: none;
  user-select: none;
}
.mdc-switch.mdc-switch--checked .mdc-switch__track {
  background-color: #feeae6;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #feeae6);
}
.mdc-switch.mdc-switch--checked .mdc-switch__thumb {
  background-color: #feeae6;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #feeae6);
  border-color: #feeae6;
  /* @alternate */
  border-color: var(--mdc-theme-secondary, #feeae6);
}
.mdc-switch:not(.mdc-switch--checked) .mdc-switch__track {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-theme-on-surface, #000);
}
.mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  border-color: #fff;
  /* @alternate */
  border-color: var(--mdc-theme-surface, #fff);
}

.mdc-switch__native-control {
  /* @noflip */
  left: 0;
  /* @noflip */
  right: initial;
  position: absolute;
  top: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  pointer-events: auto;
  transition: transform 90ms cubic-bezier(0.4, 0, 0.2, 1);
}
[dir=rtl] .mdc-switch__native-control, .mdc-switch__native-control[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 0;
}

.mdc-switch__track {
  box-sizing: border-box;
  width: 36px;
  height: 14px;
  border: 1px solid transparent;
  border-radius: 7px;
  opacity: 0.38;
  transition: opacity 90ms cubic-bezier(0.4, 0, 0.2, 1), background-color 90ms cubic-bezier(0.4, 0, 0.2, 1), border-color 90ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-switch__thumb-underlay {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  transform: translateX(0);
  transition: transform 90ms cubic-bezier(0.4, 0, 0.2, 1), background-color 90ms cubic-bezier(0.4, 0, 0.2, 1), border-color 90ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-switch__thumb {
  /* @alternate */
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  border: 10px solid;
  border-radius: 50%;
  pointer-events: none;
  z-index: 1;
}

.mdc-switch--checked .mdc-switch__track {
  opacity: 0.54;
}
.mdc-switch--checked .mdc-switch__thumb-underlay {
  transform: translateX(16px);
}
[dir=rtl] .mdc-switch--checked .mdc-switch__thumb-underlay, .mdc-switch--checked .mdc-switch__thumb-underlay[dir=rtl] {
  transform: translateX(-16px);
}
.mdc-switch--checked .mdc-switch__native-control {
  transform: translateX(-16px);
}
[dir=rtl] .mdc-switch--checked .mdc-switch__native-control, .mdc-switch--checked .mdc-switch__native-control[dir=rtl] {
  transform: translateX(16px);
}

.mdc-switch--disabled {
  opacity: 0.38;
  pointer-events: none;
}
.mdc-switch--disabled .mdc-switch__thumb {
  border-width: 1px;
}
.mdc-switch--disabled .mdc-switch__native-control {
  cursor: default;
  pointer-events: none;
}

.mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb-underlay::before, .mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb-underlay::after {
  background-color: #9e9e9e;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #9e9e9e);
}
.mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb-underlay:hover::before, .mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb-underlay.mdc-ripple-surface--hover::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}
.mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb-underlay.mdc-ripple-upgraded--background-focused::before, .mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb-underlay:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
.mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb-underlay:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb-underlay:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}
.mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb-underlay.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.24);
}

.mdc-switch__thumb-underlay {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-switch__thumb-underlay::before, .mdc-switch__thumb-underlay::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-switch__thumb-underlay::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-switch__thumb-underlay::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-switch__thumb-underlay::before, .mdc-switch__thumb-underlay::after {
  top: calc(50% - 50%);
  /* @noflip */
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded::before, .mdc-switch__thumb-underlay.mdc-ripple-upgraded::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-switch__thumb-underlay::before, .mdc-switch__thumb-underlay::after {
  background-color: #feeae6;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-secondary, #feeae6));
}
.mdc-switch__thumb-underlay:hover::before, .mdc-switch__thumb-underlay.mdc-ripple-surface--hover::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded--background-focused::before, .mdc-switch__thumb-underlay:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
.mdc-switch__thumb-underlay:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-switch__thumb-underlay:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.24);
}

.blue-switch.mdc-switch--checked .mdc-switch__track {
  background-color: #0077FF;
}
.blue-switch.mdc-switch--checked .mdc-switch__thumb {
  background-color: #0077FF;
  border-color: #0077FF;
}
.blue-switch.mdc-switch--checked .mdc-switch__thumb-underlay::before, .blue-switch.mdc-switch--checked .mdc-switch__thumb-underlay::after {
  background-color: #0077FF;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #0077FF);
}
.blue-switch.mdc-switch--checked .mdc-switch__thumb-underlay:hover::before, .blue-switch.mdc-switch--checked .mdc-switch__thumb-underlay.mdc-ripple-surface--hover::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.blue-switch.mdc-switch--checked .mdc-switch__thumb-underlay.mdc-ripple-upgraded--background-focused::before, .blue-switch.mdc-switch--checked .mdc-switch__thumb-underlay:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.blue-switch.mdc-switch--checked .mdc-switch__thumb-underlay:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.blue-switch.mdc-switch--checked .mdc-switch__thumb-underlay:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.blue-switch.mdc-switch--checked .mdc-switch__thumb-underlay.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}

div.with-explanation p {
  font-size: 14px;
  padding-top: 0.5rem;
  color: #7c7b7b;
}

div.sectionview {
  min-height: 100vh;
}

.box-section {
  background-color: #F7F7F7;
  color: #3e3e3e;
  padding: 0.5rem;
  border-radius: 4px;
  margin: 2rem 0rem;
}
.box-section .header-section svg {
  fill: #0077FF;
}
.box-section .header-section p {
  color: #0077FF;
}
.box-section .action-highlight {
  padding: 1rem 0rem;
  font-weight: bold;
}

/*! nouislider - 14.6.3 - 11/19/2020 */
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
}

.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

/* Wrapper for all connect elements.
 */
.noUi-connects {
  overflow: hidden;
  z-index: 0;
}

.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-origin: 0 0;
  transform-style: flat;
}

.noUi-connect {
  height: 100%;
  width: 100%;
}

.noUi-origin {
  height: 10%;
  width: 10%;
}

/* Offset direction
 */
.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
  left: 0;
  right: auto;
}

/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */
.noUi-vertical .noUi-origin {
  width: 0;
}

.noUi-horizontal .noUi-origin {
  height: 0;
}

.noUi-handle {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
}

.noUi-touch-area {
  height: 100%;
  width: 100%;
}

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 18px;
}

.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  right: -17px;
  top: -6px;
}

.noUi-vertical {
  width: 18px;
}

.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  right: -6px;
  top: -17px;
}

.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
  left: -17px;
  right: auto;
}

/* Styling;
 * Giving the connect element a border radius causes issues with using transform: scale
 */
.noUi-target {
  background: #FAFAFA;
  border-radius: 4px;
  border: 1px solid #D3D3D3;
  box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB;
}

.noUi-connects {
  border-radius: 3px;
}

.noUi-connect {
  background: #3FB8AF;
}

/* Handles and cursors;
 */
.noUi-draggable {
  cursor: ew-resize;
}

.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}

.noUi-handle {
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #FFF;
  cursor: default;
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB;
}

.noUi-active {
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB;
}

/* Handle stripes;
 */
.noUi-handle:before,
.noUi-handle:after {
  content: "";
  display: block;
  position: absolute;
  height: 14px;
  width: 1px;
  background: #E8E7E6;
  left: 14px;
  top: 6px;
}

.noUi-handle:after {
  left: 17px;
}

.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px;
}

.noUi-vertical .noUi-handle:after {
  top: 17px;
}

/* Disabled state;
 */
[disabled] .noUi-connect {
  background: #B8B8B8;
}

[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed;
}

/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-pips {
  position: absolute;
  color: #999;
}

/* Values;
 *
 */
.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
}

.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}

/* Markings;
 *
 */
.noUi-marker {
  position: absolute;
  background: #CCC;
}

.noUi-marker-sub {
  background: #AAA;
}

.noUi-marker-large {
  background: #AAA;
}

/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%;
}

.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}

.noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%);
}

.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}

/* Vertical layout;
 *
 */
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}

.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding-left: 25px;
}

.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%);
}

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}

.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}

.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
}

.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%;
}

.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%;
}

.noUi-horizontal .noUi-origin > .noUi-tooltip {
  -webkit-transform: translate(50%, 0);
  transform: translate(50%, 0);
  left: auto;
  bottom: 10px;
}

.noUi-vertical .noUi-origin > .noUi-tooltip {
  -webkit-transform: translate(0, -18px);
  transform: translate(0, -18px);
  top: auto;
  right: 28px;
}

.noUi-connect {
  background: #00C3FF;
}

.noUi-target {
  width: 90%;
  margin-top: 3rem;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.add-alarm-to-budget {
  padding: 0.5rem;
  background-color: #F7F7F7;
  border-radius: 4px;
  border: 1px solid #0077FF;
}
.add-alarm-to-budget .slider {
  width: 80%;
}

div.inner-notification {
  display: grid;
  grid-template-columns: 4fr 1fr;
  max-width: 200px;
  margin: 0.5rem auto;
  background-color: #FEA735;
  border-radius: 30px;
  padding: 0.5rem 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  align-items: center;
  font-size: 14px;
}
div.inner-notification .actions-notification {
  text-align: right;
}
div.inner-notification button {
  background: none;
  border: none;
  width: 25px;
  height: 25px;
}
div.inner-notification button svg {
  width: 20px;
  height: 20px;
  fill: #3e3e3e;
}

.voucheer-card {
  display: grid;
  grid-template-columns: 1.61fr 1fr;
  align-items: center;
  background-color: #F7F7F7;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  color: #3e3e3e;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}
.voucheer-card .main-content {
  display: grid;
  grid-template-columns: 1fr 7fr;
}
.voucheer-card div.logo {
  display: grid;
  align-items: center;
}
.voucheer-card div.logo svg {
  width: 25px;
  height: 25px;
}
.voucheer-card .info-metadata {
  font-size: 12px;
  display: grid;
  grid-template-columns: 0.5fr 1.61fr;
}
.voucheer-card div.amount {
  text-align: right;
}

.card-element {
  display: grid;
  grid-template-columns: 25px 1fr 20px;
  align-items: center;
  color: #3e3e3e;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin-bottom: 1rem;
  padding: 1rem 16px;
  border-radius: 4px;
}
.card-element span {
  padding-left: 10px;
}
.card-element svg.icon-card {
  width: 25px;
  height: 25px;
  fill: #3e3e3e;
}
.card-element svg.navigation {
  width: 20px;
  height: 20px;
  fill: #0077FF;
}

.element-card-slim {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 1rem;
}
.element-card-slim .expense-value {
  display: grid;
  grid-template-columns: 2fr 1fr;
  font-size: 16px;
  padding: 4px 0px;
}
.element-card-slim .category-value {
  color: #787FF6;
  font-size: 14px;
}

.blogpost h1 {
  font-size: 36px;
  line-height: 44px;
  margin: 0 auto 11px;
}
.blogpost time {
  font-size: 16px;
  font-weight: lighter;
}
.blogpost h2 {
  margin-top: 64px;
  margin-bottom: 32px;
  font-size: 24px;
}
.blogpost h3 {
  margin-top: 48px;
  margin-bottom: 16px;
  font-size: 20px;
}
.blogpost p.subtitle {
  font-size: 18px;
  margin: 16px auto;
  line-height: 32px;
}
.blogpost p {
  margin-top: 36px;
  margin-bottom: 36px;
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
}
.blogpost ul {
  list-style: none;
  margin: 32px 0;
  position: relative;
  padding-left: 24px;
}
.blogpost ul li {
  font-size: 1.125rem;
  line-height: 1.778rem;
  margin-bottom: 8px;
}

.foo-button:not(:disabled) {
  background-color: darksalmon;
}