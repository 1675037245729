@use './../base/color';
@use "@material/icon-button";
@use "@material/top-app-bar/mdc-top-app-bar";

@include icon-button.core-styles;

.app-bar {
    // position: absolute;
}

.mdc-top-app-bar {
    z-index: 7;
    .header-text {
        color: color.getColor('white', base);
        font-weight: 300;
    }
}