.blogpost {

    h1 {
        font-size: 36px;
        line-height: 44px;
        margin: 0 auto 11px;
    }

    time {
        font-size: 16px;
        font-weight: lighter;
    }

    h2 {
        margin-top: 64px;
        margin-bottom: 32px;
        font-size: 24px;
    }

    h3 {
        margin-top: 48px;
        margin-bottom: 16px;
        font-size: 20px;
    }

    p.subtitle {
        font-size: 18px;
        margin: 16px auto;
        line-height: 32px;
    }

    p {
        margin-top: 36px;
        margin-bottom: 36px;
        font-size: 18px;
        font-weight: 400;
        line-height: 32px;
    }

    ul {
        list-style: none;
        margin: 32px 0;
        position: relative;
        padding-left: 24px;
        li {
            font-size: 1.125rem;
            line-height: 1.778rem;
            margin-bottom: 8px;
        }
    }

}