@use "@material/floating-label/mdc-floating-label";
@use "@material/line-ripple/mdc-line-ripple";
@use "@material/notched-outline/mdc-notched-outline";
@use "@material/textfield";

@include textfield.core-styles;

.mdc-text-field.smaller {
    @include textfield.height(30px);
}

.mdc-text-field.text-field-total {
    @include textfield.height(70px);
    input {
        text-align: right;
        font-size: 2rem;
        letter-spacing: 1px;
    }
}
label.mdc-text-field.text-field-big {
    @include textfield.height(70px);
    input {
        font-size: 2rem;
        letter-spacing: 1px;
        height: 70px !important;
    }
    input.inner-big-textfield {
        height: 70px !important;
    }
    .mdc-text-field__affix{
        font-size: 1.75rem;
    }
    .mdc-text-field--filled .mdc-floating-label--float-above {
        transform: translateY(-180%) scale(0.75);
    }
    .mdc-text-field__input {
        padding-top: 1.75rem;
        padding-left: 1rem;
        height: 70px !important;
    }
}

.mdc-text-field.full-width {
    width: 100%;
}

.text-field-with-action {
    position: relative;
    button {
        position: absolute;
        right: 5px;
        top: 16px
    }
    label {
        input {
            padding-right: 64px;
        }
    }
}