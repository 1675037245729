@use './../base/color';

.voucheer-card{
    display: grid;
    grid-template-columns: 1.61fr 1fr;
    align-items: center;
    background-color: color.getColor('white', base);;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    color: color.getColor('black', base);;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    .main-content {
        display: grid;
        grid-template-columns: 1fr 7fr;
    }
    div.logo {
        display: grid;
        align-items: center;
        svg {
            width: 25px;
            height: 25px;
        }
    }
    .info-metadata {
        font-size: 12px;
        display: grid;
        grid-template-columns: 0.5fr 1.61fr;
    }

    div.amount {
        text-align: right;
    }
}